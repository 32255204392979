export class AppConstants {
  public static DATE_FORMAT_ISO: string = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';
  public static DATE_FORMAT_BASE_LINE: string = 'YYYY-MM-DD';
  public static DATE_FORMAT_BASE_HYPHEN: string = 'DD-MM-YYYY';
  public static DATE_FORMAT_BASE_SLASH: string = 'DD/MM/YYYY';
  public static DATE_FORMAT_BASE_SLASH_SHORT: string = 'DD/MM/YY';
  public static DATE_FORMAT_BASE_SLASH_SIMPLE: string = 'DD/MM';
  public static DATE_FORMAT_BASE_SLASH_TIME: string = 'DD/MM/YYYY HH:mm';
  public static DATE_FORMAT_BASE_SLASH_COMMA_TIME: string = 'DD/MM/YYYY, HH:mm';
  public static DATE_FORMAT_BASE_DOT: string = 'DD.MM.YYYY';
  public static DATE_FORMAT_BASE_DOT_SHORT: string = 'DD.MM.YY';
  public static DATE_FORMAT_BASE_TIME_LINE: string = 'YYYY-MM-DD[T]HH:mm';
  public static TIME_FORMAT: string = 'HH:mm';
  public static TIME_FORMAT_FULL: string = 'HH:mm:ss';
  public static NUMBER_FORMAT: string = '1.0-2';

  public static NUMBER_LOCALE: string = 'en-IL';

  public static customerType = {
    ShuttleCompany: 27,
    Supplier: 40,
    School: 32,
    Municipality: 28,
    System: 163,
    Business: 219,
    Army: 262
  };

  public static contactTypes = {
    Mobile: 14,
    Fax: 15,
    Phone: 13,
    Mail: 37
  };

  public static roles = {
    Accompany: 10,
    Passenger: 16,
    Driver: 27,
    Parent: 17,
    Bouncer: 1,
    Secretary: 19,
    DirectorGeneral: 20,
    Other: 21,
    TravelDirector2: 50,
    BusinessTransportationManager: 46,
    Unknown: -1,
    TravelDirector: 30
  };
}
