import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgProgress } from 'ngx-progressbar';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { LoaderService, LocalizationService } from '@app/shared';
import { SideMenuService } from '../../services';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: [ './layout.component.scss', './layout.component.rtl.scss' ]
})

export class LayoutComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();

  isRtl: boolean = this.localizationService.isRtl();

  constructor(
    private progress: NgProgress,
    private cdRef: ChangeDetectorRef,
    private loaderService: LoaderService,
    private localizationService: LocalizationService,
    public appSideMenuService: SideMenuService
  ) {}

  ngOnInit(): void {
    this.toggleLoading();

    this.appSideMenuService.isMenuCollapsedObs.subscribe(() => this.cdRef.detectChanges());
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private toggleLoading(): void {
    this.loaderService.isLoading
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(isLoading => {
        if (isLoading) {
          this.progress.start();
        } else {
          this.progress.done();
        }
      });
  }
}
