import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { TranslateModule } from '@ngx-translate/core';
import { UCommonModule, UPopoverModule, UTooltipModule } from '@shift/ulib';

import {
  IdInputComponent,
  AlertInputComponent,
  FileInputComponent,
  PercentInputComponent,
  AddressInputComponent,
  ContactsInputComponent,
  InspectionInputComponent,
  FilesImagesInputComponent,
  PaymentTermsInputComponent,
  ContactGroupsInputComponent,
  NextInspectionInputComponent,
  FileExpirationInputComponent
} from './components';

@NgModule({
  imports: [
    CommonModule,
    UCommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    UTooltipModule,
    UPopoverModule,
    ModalModule,
    NgHttpLoaderModule
  ],
  declarations: [
    ContactsInputComponent,
    ContactGroupsInputComponent,
    AddressInputComponent,
    FileInputComponent,
    AlertInputComponent,
    FileExpirationInputComponent,
    IdInputComponent,
    PaymentTermsInputComponent,
    NextInspectionInputComponent,
    InspectionInputComponent,
    PercentInputComponent,
    FilesImagesInputComponent
  ],
  exports: [
    AddressInputComponent,
    ContactGroupsInputComponent,
    ContactsInputComponent,
    FileInputComponent,
    AlertInputComponent,
    FileExpirationInputComponent,
    IdInputComponent,
    PaymentTermsInputComponent,
    NextInspectionInputComponent,
    InspectionInputComponent,
    PercentInputComponent
  ]
})
export class InputsModule {
}
