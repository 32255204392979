import { Observable } from 'rxjs';

export const fromEventCustom = (target, eventName, type = false) => {
  return new Observable((subscriber) => {
    const handler = event => subscriber.next(event);

    // Add the event handler to the target
    target.addEventListener(eventName, handler, type);

    return () => {
      // Detach the event handler from the target
      target.removeEventListener(eventName, handler);
    };
  });
};
