<u-group *ngIf="!field['bodyComponent']"
         [name]="field['title'] | translate"
         class="general-field-group {{field['inputType'] === 'textarea' || field['inputType'] === 'file' || field['inputType'] === 'contacts' ? 'large-field-group' : ''}}
         {{field['readonly'] || (field['inputType'] === 'select-if-null' && formControl.value) ? 'readonly-field': ''}} {{!field['title']? 'no-title-group': ''}}">
<label *ngIf="field['inputType'] === 'label' && !field['dateFormat']" class="ctrl-dynamic-label">{{field['translate'] ? (formControl.value | translate) : formControl.value}}</label>
  <label *ngIf="field['inputType'] === 'label' && field['dateFormat']" class="ctrl-dynamic-label">{{formControl.value | date:field['dateFormat']}}</label>
  <input uInput *ngIf="field['inputType'] === 'text'" class="{{field['fullWidth']? 'full-width-field': ''}}"
         type="{{field['inputType']}}"
         [formControl]="formControl"
         [valid]="formControl.valid">
  <u-input-number *ngIf="field['inputType'] === 'number'"
                  [formControl]="formControl"
                  [valid]="formControl.valid"
                  [min]="field['min']" 
                  [max]="field['max']"
                  minlength="{{field['minLength']}}"
                  maxlength="{{field['maxLength']}}"
                  [placeholder]="field['placeholder']"
                  [decimal]="field['decimal']"
                  [maxDecimalDigits]="field['maxFractionDigits'] || 2"
                  [valueType]="field['valueType']"></u-input-number>
  <app-id-input *ngIf="field['inputType'] === 'id'" ngDefaultControl
            [formControl]="formControl"
            [formObject]="formObject"
            [field]="field"></app-id-input>
  <app-address-input *ngIf="field['inputType'] === 'address'"
                 [formObject]="formObject"
                 [fieldName]="field['name']"></app-address-input>
  <u-select-s *ngIf="field['inputType'] === 'select' || field['inputType'] === 'select-if-null'"
              [container]="'body'"
              [messages]="'uSelectS' | translate"
              [items]="field['inputOptionsArr'] || metadata && metadata[field['inputOptionsVar']]"
              [formControl]="formControl"
              [valid]="formControl.valid"
              [emptyName]="'general.select' | translate"
              [emptyValue]="null"
              [emptyHidden]="field['required']"
              [emptyRemove]="field['required'] ? null : 'general.remove'"
              [placeholder]="field['placeholder'] || 'general.select' | translate"
              [placement]="isRtl ? ['bottom-right', 'bottom-left', 'top-right', 'top-left'] : ['bottom-left', 'bottom-right', 'top-left', 'top-right']">
  </u-select-s>
  <u-multiselect *ngIf="field['inputType'] === 'multiSelect'"
              [messages]="'uMultiselect' | translate"
              [container]="'body'"
              [placeholder]="field['placeholder'] || 'general.select' | translate"
              [items]="field['inputOptionsArr'] || metadata && metadata[field['inputOptionsVar']]"
              [formControl]="formControl"
              [placement]="isRtl ? ['bottom-right', 'bottom-left', 'top-right', 'top-left'] : ['bottom-left', 'bottom-right', 'top-left', 'top-right']">
  </u-multiselect>
  <textarea uTextarea *ngIf="field['inputType'] === 'textarea'"
            [formControl]="formControl"></textarea>
  <u-input-date *ngIf="field['inputType'] === 'datepicker'"
            [classUInput]="'u-input_text-center'"
            [formControl]="formControl"
            [valid]="formControl.valid"
            [container]="'body'"
            [placement]="isRtl ? ['bottom-right', 'bottom-left', 'top-right', 'top-left'] : ['bottom-left', 'bottom-right', 'top-left', 'top-right']"
            [messages]="'uInputDate' | translate">
  </u-input-date>
  <u-input-time *ngIf="field['inputType'] === 'time'" [formControl]="formControl"></u-input-time>
<!--  TODO: need to set active color of u-toggle to #009245BF using css-->
  <u-toggle *ngIf="field['inputType'] === 'toggle'" [formControl]="formControl" [messages]="{active: 'general.yes', notActive: 'general.no'}"></u-toggle>
  <app-file-input *ngIf="field['inputType'] === 'file'"
              [formObject]="formControl"
              [fileType]="field['fileType']"></app-file-input>
  <app-file-expiration-input *ngIf="field['inputType'] === 'fileExpiration'"
                         [formControlObject]="formControl"
                         [innerDelete]="field['innerDelete']"
                         [outsideDelete]="field['outsideDelete']"
                         [fileType]="field['fileType']"></app-file-expiration-input>
  <app-alert-input *ngIf="field['inputType'] === 'alert'"
              [control]="formControl"></app-alert-input>
  <app-payment-terms-input *ngIf="field['inputType'] === 'paymentTerms'"
              [formObject]="formObject"></app-payment-terms-input>
  <app-next-inspection-input *ngIf="field['inputType'] === 'nextInspection'"
               [formObject]="formObject" [field]="field"></app-next-inspection-input>
  <app-percent-input *ngIf="field['inputType'] === 'percent'" [field]="field" [formObject]="formControl"></app-percent-input>
  <app-contacts-input *ngIf="field['inputType'] === 'contacts'"
                      [field]="field"
                      [config]="fieldConfig"
                      [contactsForm]="formControl"
                      [editData]="editData"
                      [entityForm]="formObject" [usersForm]="metadata?.usersForm">
  </app-contacts-input>
  <app-contact-groups-input *ngIf="field['inputType'] === 'contactGroups'" [formObject]="formControl" [config]="fieldConfig"></app-contact-groups-input>
</u-group>

<app-dynamic-component *ngIf="field['bodyComponent'] !== undefined" [component]="field['bodyComponent']"
                   [formObject]="formControl" [editData]="editData" [addNewFieldObs]="addNewFieldObs" [config]="fieldConfig"></app-dynamic-component>
