import { Component, OnInit } from '@angular/core';
import 'rxjs-compat/add/operator/map';

import { AppTitleService, AuthService, IntercomChatService, LocalizationService } from '@app/shared/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private intercomChat: IntercomChatService,
    private appTitleService: AppTitleService,
    private localizationService: LocalizationService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.localizationService.init();
    this.authService.init();
    this.appTitleService.init();
    this.initIntercom();
  }

  private initIntercom() {
    // this.intercomChat.configureChatUser(userData.oUser, userData.oCustomer);

    // const INTERCOM_ID = environment.intercomKey;

    // (function () {
    //   const w = window;
    //   const ic = w.Intercom;
    //   if (typeof ic === 'function') {
    //     ic('reattach_activator');
    //     ic('update', w.intercomSettings);
    //   } else {
    //     const d = document;
    //     const i: any = function () {
    //       i.c(arguments);
    //     };
    //     i.q = [];
    //     i.c = function (args) {
    //       i.q.push(args);
    //     };
    //     w.Intercom = i;
    //     const l = function () {
    //       const s = d.createElement('script');
    //       s.type = 'text/javascript';
    //       s.async = true;
    //       s.src = 'https://widget.intercom.io/widget/' + INTERCOM_ID;
    //       const x = d.getElementsByTagName('script')[0];
    //       x.parentNode.insertBefore(s, x);
    //     };
    //     if (document.readyState === 'complete') {
    //       l();
    //     } else if (w.attachEvent) {
    //       w.attachEvent('onload', l);
    //     } else {
    //       w.addEventListener('load', l, false);
    //     }
    //   }
    // })();
    //
    // window.intercomSettings = {
    //   app_id: INTERCOM_ID,
    //   custom_launcher_selector: '#chat-button',
    // };
  }
}
