<app-header></app-header>

<div class="main-content" [ngClass]="{'side-menu_collapsed': appSideMenuService.isMenuCollapsed}">
  <app-side-menu class="side-menu"></app-side-menu>

  <div class="router-outlet-container">
    <ng-progress
      [thick]="true"
      [color]="'#666666'"
      [showSpinner]="false"
      [class]="'ngx-progressbar'"
      [positionUsing]="'translate'"
      [direction]="isRtl ? 'rightToLeftIncreased' : 'leftToRightIncreased'"
    ></ng-progress>

    <router-outlet></router-outlet>
  </div>
</div>

<app-footer></app-footer>
