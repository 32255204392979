import { Component, ElementRef, Input, OnChanges, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';

import { TablePageService } from './table-page.service';
import { ConstantsService, LocalizationService } from '@app/shared/services';

@Component({
  selector: 'app-table-page',
  templateUrl: './table-page.component.html',
  styleUrls: [ './table-page.component.scss', './table-page.component.rtl.scss' ]
})
export class TablePageComponent implements OnInit, OnChanges {
  @Input() rows: any[] = [];
  @Input() pageConfig: any = [];
  @Input() displayTableMetaData = true;

  @ViewChild('editRowCell') public editRowCell: TemplateRef<any>;
  @ViewChild('popoverCell') public popoverCell: TemplateRef<any>;
  @ViewChild('statusCell') public statusCell: TemplateRef<any>;
  @ViewChild('normalCell') public normalCell: TemplateRef<any>;
  @ViewChild('selectCell') public selectCell: TemplateRef<any>;
  @ViewChild('durationCell') public durationCell: TemplateRef<any>;

  @select([ 'auth', 'user', 'id' ])
  public readonly userIdObs: Observable<any>;

  userId: string;
  categories: any;
  tableConfig: any;
  isLoading = true;

  constructor(
    public localizationService: LocalizationService,
    public tablePageService: TablePageService,
    private constantsService: ConstantsService
  ) {}

  @ViewChild('tableElement') set content(element: ElementRef) {
    this.tablePageService.tableElement = element;
  }

  get alteredRows(): any[] {
    return this.tablePageService.visibleRows;
  }

  get specificRowClassObjects(): any[] {
    return this.tablePageService.specificRowClassObjects;
  }

  ngOnInit(): void {
    this.userIdObs.subscribe(userId => this.userId = userId);
  }

  ngOnChanges(): void {
    this.tableConfig = this.pageConfig.tableConfig;

    if (this.tableConfig.categoriesToFetch) {
      this.fetchCategories(this.tableConfig.categoriesToFetch);
    } else {
      this.isLoading = false;
    }

    this.tablePageService.tableConfig = this.tableConfig;
    this.tablePageService.initRows(this.rows);
    this.tablePageService.selectedRows = [];
    this.tableConfig.columns = this.attachExtraPropsFromComponent(this.tableConfig.columns);
  }

  isRtl(): boolean {
    return this.localizationService.isRtl();
  }

  attachExtraPropsFromComponent(columns: any[]): any[] {
        // attach matching cellTemplate for columns who requires it
        // TODO: try and improve this with a generic service in the future
    if (columns) {
      columns.forEach(column => column['cellTemplate'] = column.cellTemplateName ? this[column.cellTemplateName] : this.normalCell);
    }

    return columns;
  }

  setSelectedRows(data: any): void {
    this.tablePageService.selectedRows = [ ...data.selected ];
  }

  public get selectedRows(): any[] {
    return this.tablePageService.selectedRows;
  }

  fetchCategories(categoriesToFetch: string[]): void {
    this.constantsService.fetchCategories(categoriesToFetch);
    this.constantsService.categories.subscribe(categories => {
      this.categories = categories;
      this.isLoading = false;
    })
  }
}
