import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import customerAddEditModalConfig from './customer-add-edit.component.config';
import { BaseService, CtrlFormsService } from '@app/shared/services';
import { MetadataService } from '@app/shared/services/metadata.service';
import { AddEditModalService } from '@app/shared/modules/add-edit-modal/services';

@Component({
  selector: 'app-add-edit-customers',
  templateUrl: './customer-add-edit.component.html',
  styleUrls: [ './customer-add-edit.component.scss', './customer-add-edit.component.rtl.scss' ]
})
export class CustomerAddEditComponent implements OnInit {
  editData: any;
  addEditForm: FormGroup;
  modalConfig: any = customerAddEditModalConfig;

  @Output() action = new EventEmitter();

  constructor(
    private baseService: BaseService,
    private ctrlFormsService: CtrlFormsService,
    private addEditModalService: AddEditModalService,
    private metadataService: MetadataService
  ) {}

  ngOnInit(): void {
    this.initAddEditForm();
    this.baseService.setPaths('customers');
    this.metadataService.setMetadataObs(this.modalConfig);

    if (this.editData) {
      this.ctrlFormsService.patchValueNested(this.addEditForm, this.editData, this.modalConfig.fields);
    }
  }

  initAddEditForm(): void {
    this.addEditForm = this.ctrlFormsService.generateModalTabsForm(this.modalConfig);
  }

  deleteCustomer(): void {
    this.addEditModalService.deleteEntity('customers.addEditCustomer.messages.deleteConfirm', this.editData, this.action);
  }

  submitForm = (): void => {
    if (this.addEditModalService.canSubmit(this.addEditForm)) {
      this.addEditModalService.submitForm(this.addEditForm.value, this.action, this.editData);
    }
  };
}
