<u-modal-entity
  class="u-modal-entity_app-license-agreement"
  [title]="'loginPage.licenseLink' | translate"
  [buttons]="buttons"
  [isRtl]="isRtl"
  (action)="checkActions($event)"
>
  <tabset class="u-tabset-pills" type="pills">
    <tab>
      <p style="text-align: center; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span><strong>תנאי שימוש במערכת Ctrl</strong></span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span><span style="font-weight: 400;">תנאי שימוש אלו נועדו להסדיר את השימוש במערכת Ctrl הכוללת מממשק אינטרנטי ("</span><strong>האתר</strong><span style="font-weight: 400;">") ואפליקציה ואשר מאפשרת ניהול ותכנון של מערך ההיסעים של הסעות עובדים ("</span><strong>השירות</strong><span style="font-weight: 400;">").</span></span></p>
      <h2 style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">אנו ממליצים להקדיש זמן לקריאה של תנאים אלה.&nbsp;</span></h2>
      <h2 style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">כשתקיש על הכפתור "אני מסכים", אתה מאשר שקראת, הבנת ואתה מסכים לתנאי שימוש אלה.</span></h2>
      <h2 style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span><span style="font-weight: 400;">בכל עת ניתן לפנות אלינו לכתובת: </span><a href="mailto:info@mashcal.co.il"><span style="font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500; color: #93929B; text-decoration: none; text-underline: none;">info@shiftlive.net</span></a><span style="font-weight: 400;"> בכל בקשה ופניה בנוגע לתנאים אלה.&nbsp;</span></span></h2>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">ייתכנו שינויים בשירות ובתנאים אלה המסדירים את השימוש בו, בשל אופיו הדינאמי של השירות, של האינטרנט ככלל, ושל היבטי החקיקה והרגולציה שיכולים לחול על הפעילות בשירות. אנו נפרסם מראש הודעה על שינויים צפויים בשירות עצמו, או בשיגור הודעת דואר אלקטרוני ובה נפרט את מהות השינוי ומועד כניסתו לתוקף.&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">הסכמתך לתנאי שימוש אלה מהווה גם הסכמה לתנאים שעברו שינוי ואולם ככל שנדרש על פי דין, נבקש שוב את הסכמתך לתנאים החדשים.&nbsp;</span></p>
      <h2 style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">בשל מגבלות השפה העברית, בחלק קטן מהמקרים הניסוח הוא בלשון זכר בלבד, אך תנאים אלה מתייחסים כמובן גם לכן, הנשים.&nbsp;</span></h2>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span><strong>השירות</strong></span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span><span style="font-weight: 400;">השירות מאפשר ניהול ותכנון של מערך הסעות עובדים במקום בו אתה עובד. השירות משמש ככלי לתכנון מסלולי הנסיעה ובקרה עליהם לצורך קבלת תמונת מצב מלאה אודות הנסיעות המבוצעות.</span><strong>&nbsp;</strong></span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span><strong>השימוש בשירות ומגבלותיו&nbsp;</strong></span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span><span style="font-weight: 400;">השימוש באתר זמין לעובדי החברה שהם בעלי תפקידים בקשר עם תחום ההסעות. בנוסף, לחברות ההסעות תינתן הרשאה לצפות באמצעות האתר בנתונים הרלוונטיים לנסיעות המבוצעות על ידן בלבד, וכן תינתן גישה לאתר לבעלי תפקידים רלוונטיים בחברת שיפט לייב בע"מ ("</span><strong>החברה</strong><span style="font-weight: 400;">").</span></span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">השימוש באפליקציה זמין לגורמים שפורטו לעיל ובנוסף לכך גם לנהגי והעובדים. לכל גורם יוצגו באפליקציה הנתונים הרלוונטיים אליו בלבד..ניתנת לך הרשות להשתמש בשירות אך ורק בהתאם להרשאות שהוגדרו עבורך באפליקציה ו/או באתר. אין להשתמש בשירות לכל מטרה אחרת שאינה בתחום הרשאתך ללא קבלת הסכמתנו בכתב, במפורש ומראש.&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">אין להעתיק את תכני השירות, להטמיע אותם (embed) באתרים אחרים, להציג אותם באמצעות פעולת מסגור (framing), ליצור אתרי מראה (mirroring), למכור, להעניק רישיון שימוש, להשכיר, להשאיל או להפיץ את תכני השירות בכל דרך שהיא.&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">אין לשנות, ליצור יצירות נגזרות, לבצע הנדסה חוזרת או לעשות כל פעולה במטרה לחשוף את קבצי המקור של תוכנת השירות.</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">אין לקשר לתוכן בשירות המציג אותו במנותק מדף האינטרנט שבו התוכן נמצא. בקישור לדפי אינטרנט של השירות, אין להסתיר, לחסום, לשבש או למחוק כל מידע מדפי האינטרנט של השירות, כפי שהם במקורם.&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">אין לגשת לשירות או לתכנים הכלולים בו במטרה לפתח או ליצור שירות דומה או מתחרה בשירות, לרבות באמצעות איסוף פרטים ומידע מתוך השירות.&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">ללא הסכמתנו הכתובה והמפורשת מראש, אין להשתמש בשירות למטרות בחינה, סריקה, איסוף אוטומטי של מידע (scraping), פעולות פצחנות (hacking), פריצת מערכות האבטחה של השירות וכל מערכת מחשב ותקשורת המשרתת אותו, הפרעה לפעולת השירות, עקיפת מנגנוני ההגנה של השירות, ביצוע מניפולציות בפעילות השירות, גישה שיטתית למחשבי השירות באמצעות כלי תוכנה אוטומטיים כדוגמת: 'bots' ו - 'crawlers' וגישה לתכונה או לפונקציונאליות של השירות שאינן מיועדות להיות נגישות או אינן מתועדות.&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">אין להשתמש בשירות לכל פעילות המהווה או מעודדת ביצוע מעשים המהווים עבירה פלילית, או עוולה אזרחית, או מפרים בכל דרך אחרת את החוק הרלבנטי, לרבות חוקים הנוגעים להטעיה, הונאה, זיוף, הגנת פרטיות, גניבת זהות, חדירה לחומר מחשב, הפצת וירוסים ותוכנות עוינות אחרות, זכות הפרסום, לשון הרע, הטרדה ופרסומי תועבה.&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">אנו נשתמש במידע שלך בהתאם להוראות תנאי שימוש אלה, לרבות ההוראות בדבר שמירת התוכן, זמינותו, פרסומו, הסרתו והשימוש בזכויות בו.&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span><strong>רישום&nbsp;</strong></span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">במסגרת ההרשמה לשירות תתבקש למסור את הפרטים הבאים: שם מלא, תפקיד, כתובת דוא"ל ומספר טלפון נייד. מסירת הפרטים והשימוש בהם על ידי החברה, כפופים לתנאי שימוש אלה.</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">בנוסף, אנו עשויים לבקש את מספר תעודת הזהות שלך ו/או מספר רישיון נהיגה, ככל שהדבר נדרש לאור לאופי שימושך בשירות.&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span><span style="font-weight: 400;">ההרשמה לאתר תיעשה על ידי נציגי החברה. לצורך ההרשמה לאפליקציה תתבקש להזין סיסמא שתישלח אליך לטלפון הנייד שמסרת לנציגי החברה ("</span><strong>הודעת אימות</strong><span style="font-weight: 400;">").&nbsp;</span></span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">אנו רשאים לשלוח לך הודעות הנוגעות לרישום ולשימוש שלך בשירות למשל בעת עדכון הסיסמה שלך לשירות.&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">בכל עת, אנו רשאים לשנות את אופי והיקף המידע שייחשף בפניך בשירות.</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">אנו רשאים לבטל את הרישום שלך לשירות או לא לאפשר לך להירשם אליו מלכתחילה, אם לפי שיקול דעתנו נרשמת כדי לבצע פעולה בלתי חוקית (לדוגמה, בדרך של התחזות לאחר), או כדי לפגוע באחרים, בשירות, או בנו &ndash; בעלי השירות, או במי מטעמנו, או אם פעילותך בשירות מפרה צו בית משפט או הוראה מחייבת של רשות, או מפרה תנאים אלה, או אם קיימת לדעתנו סיבה מוצדקת אחרת לכך, מבלי שנצטרך לנמק את החלטתנו.&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span><strong>גישה לחשבון</strong></span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">הגישה לחשבון המשתמש באתר תהיה באמצעות שם משתמש וסיסמה. הגישה לחשבון המשתמש באפליקציה תהיה באמצעות הודעת אימות. בעתיד יתכן ונאפשר או נחייב גישה לחשבון המשתמש שלך באמצעות אמצעים אחרים או נוספים, כפי שנראה לנכון.&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">לך ורק לך ניתנת הזכות להשתמש בחשבון שלך בשירות. עליך להימנע מלשתפו עם אחרים וכן עליך לשמור בסוד את הסיסמה ולהימנע מלמסור אותה לאחרים.&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">מעת לעת תתבקש להחליף את הסיסמא לחשבון המשתמש באתר.</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">אם לא תשתמש בשירות במשך 90 ימים, חשבונך יוקפא.&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span><strong>העלאת מסמכים לאתר</strong></span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">באפשרותך להעלות מסמכים לאתר בלבד. המסמכים המועלים על ידך לאתר הם באחריותך. איננו נושאים באחריות למסמכים שלך, או למסמכים שמעלים משתמשים אחרים, ובכלל זה לאמיתותם, למהימנותם, לחוקיותם ולכל פעולה הנעשית בקשר איתם.&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span>&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span><strong>עדכוני מסרונים ודוא"ל</strong></span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">במסגרת שימושך בשירות, אנו נשלח אליך עדכונים באמצעות מסרון או באמצעות דואר אלקטרוני, בנוגע לנסיעות הרלוונטיות אליך. בנוסף, אנו נשלח אליך הודעות שיווקיות לכתובת הדואר האלקטרוני שמסרת לנו..&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span><strong>פרטיות</strong></span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">השירות כאמור, מאפשר לנהל, לתכנן ולנטר את מערך ההיסעים בחברה. לפיכך, מטבע הדברים מידע אישי הוא חלק בלתי נפרד ממנו. תנאי שימוש אלו ולרבות פרק זה שעניינו פרטיות, עוסקים במידע שאנו אוספים, מה אנו עושים עם המידע, עם מי אנחנו משתפים אותו וכן היבטים נלווים נוספים.</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">במסגרת שימושך בשירות אנו נאסוף מידע אישי עליך כדלקמן: שם מלא, כתובת מייל, מס' טלפון נייד, תפקידך, מידע אודות מיקומך ומסלול נסיעתך.</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">כמו כן, אנו מנטרים את מרבית פעולות המשתמשים בשירות, לרבות: מס' הקלקות, כניסות, מסכים שהמשתמש עבר בהם, דוחות שהוציא המשתמש והתוכן שנצפה.</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">המידע שאנו אוספים מאפשר לנו לספק לך ולמשתמשי השירות האחרים את השירות, וכן דרוש לנו לשם פעילות שוטפת הכרוכה בניהול השירות, בבקרה על המתרחש בו, באבטחה שלו ובביצוע שיפורים ותוספות לשירות, כאשר מעת לעת החברה שומרת על זכותה לשלוח למשתמשים רשומים הודעות בדואר אלקטרוני, או בכל דרך אחרת.</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">ככל שנזדקק לכך, נשתמש במידע גם כדי לטפל, לחקור ולמנוע שימוש לרעה בשירות, כדוגמת הפרות זכויות, הונאות וגניבות זהות ובמידת הצורך אף לצורך הליכים משפטיים הנוגעים לכך.&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">אנחנו משתפים מידע עם חברות ככל שנדרש כדי לממש את מטרות איסוף המידע. בכלל זה נזדקק לשתף מידע עם חברות וגופים נוספים לאחסון ואבטחת מידע. כמובן שנשתף מידע אם החוק מחייב אותנו לעשות זאת, לדוגמה במקרה בו החברה עשויה לקבל צו בית משפט לצורך גילוי מידע.&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">המידע שאנו אוספים נשמר במאגר המידע של החברה, המאוחסן אצל ספק אירוח שרתים וגיבוי מידע הממוקם באירופה.</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">&nbsp;אנו רשאים לשמור את המידע שלך כל עוד אתה משתמש בשירות. לאחר מכן, נשמור את המידע אם יש צורך בכך, לדוגמה, אם אנחנו צריכים לשמור מידע הנדרש לשם דיווח לרשויות, למטרות היערכות אפשרית להליך משפטי, למטרות תיעוד וארכוב וכדי להמשיך ולהציג בשירות מידע רלבנטי. מידע שלא נזדקק לו יותר כמידע מזהה, יימחק או ייהפך למידע לא מזוהה.</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span><strong>מידע סטטיסטי ואנונימי</strong></span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">מידע סטטיסטי ואנונימי אינו כפוף לדיני הגנת הפרטיות. אנו רשאים לאסוף, למסור ולשתף מידע מסוג זה ללא מגבלות. יחד עם זאת, כאשר אנו משתמשים במידע מסוג זה, לא נעשה כל פעולה שביודעין עלולה לחשוף פרטים מזהים שלך, שלא נחשפו ממילא בפומבי במהלך השימוש שלך בשירות.&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span><strong>אבטחת מידע</strong></span></p>
      <h2 style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">אנו מיישמים מערכות ונהלים לאבטחת מידע במטרה להגן על המידע מפני שימוש לרעה ושימוש בלתי מורשה. כך לדוגמה, כל דפי השירות מאובטחים באמצעות פרוטוקול ההצפנה SSL וכן, ספקי שירותי האירוח של מאגרי השירות מיישמים מערכות לאבטחה פיזית ולמידור הגישה לשירות.&nbsp;</span></h2>
      <h2 style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">יחד עם זאת, המערכות הללו מצמצמות את סיכוני האבטחה, אך אף אחת מהן איננה מספקת ביטחון מלא ומערכות השירות, לרבות התוכנות המשמשות אותו אינן חסינות מתקלות. לכן, איננו מתחייבים שהמידע שלך יהיה חסין מפני פגיעה וגישה בלתי מורשית.&nbsp;</span></h2>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span><strong>אחריות</strong></span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">אנו עושים כמיטב יכולתנו לספק לך את השירות בצורה איכותית ומדויקת. עם זאת לעתים עלולות להתרחש תקלות בפעילות השירות, בגלל בעיות בפעילות ספקי התקשורת של השירות, מערכות התוכנה של השירות, שינויים ותחזוקה שאנחנו מבצעים בשירות ועוד. תקלות מסוג זה גורמות לאי נוחות וקושי בשימוש בשירות ואנו נעשה כמיטב יכולתנו כדי להתגבר עליהן במהירות המרבית. יחד עם זאת, לא נישא באחריות לכל אי נוחות או נזק העלולים להיגרם כתוצאה מהן.</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span><strong>זמינות&nbsp;</strong></span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">השירות נגיש בתחומי מדינת ישראל ומכל מחשב ומכשיר סלולארי המחובר לאינטרנט ונעשה כל מאמץ סביר כדי שהשירות יהיה זמין בכל עת. יחד עם זאת, אנו עשויים לחסום, בעצמנו או באמצעות צד שלישי מטעמנו, את הגישה לשירות ממחשבים המשתמשים בכתובות אינטרנט (IP address) מסוימות, או ממקורות אחרים, לשם הגנה על השירות ומניעת הפגיעה בו, במשתמשים ובנו.&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span><strong>קניין רוחני</strong><span style="font-weight: 400;">&nbsp;</span></span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">השירות כולל ממשקי משתמש, עיצובים גראפיים, איורים, טקסטים, צלמיות (icons), קוד תוכנה ועוד. כל אלה מוגנים בזכויות יוצרים וחלקם אף באמצעות זכויות קנין רוחני נוספות כדוגמת סימני מסחר וסודות מסחר. מלוא הזכויות בתכנים אלה (למעט בתכנים שמשתמשים העלו לשירות) בבעלותנו או שקיבלנו רשות של אחרים להשתמש בהם בשירות.&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">מלבד הרשות שניתנה לך במפורש להשתמש בשירות, כמפורט בתנאים אלה, אין לעשות בשירות ואין להרשות לאחרים לעשות בשירות ובכל מרכיב הכלול בו שימוש שלא הותר במפורש. הסימנים &copy; ו - &reg; מעידים על קיומם של זכויות קנייניות. אין לשבש את הצגתם.&nbsp;</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><br /><br /></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span style="font-weight: 400; ">עודכן לאחרונה בתאריך: 09.03.20</span></p>
      <p style="text-align: right; font-size: 13px; font-family: var(--app-primary-font-family); font-weight: 500;"><span>&nbsp;</span></p>
    </tab>
  </tabset>
</u-modal-entity>
