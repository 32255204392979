<div class="inspection-result-container">
  <div class="inspection-result-ok-container" *ngIf="!readonly">
    <span class="inspection-result-ok passed-result" (click)="setInspectionResult(true)" [ngClass]="{'selected-result': getInspectionResult()}">&#10003;</span>
    <span class="inspection-result-ok failed-result" (click)="setInspectionResult(false)" [ngClass]="{'selected-result': !getInspectionResult()}">✗</span>
  </div>

  <div class="inspection-result-ok-container" *ngIf="readonly">
    <span class="inspection-result-ok passed-result readonly-result" *ngIf="getInspectionResult()">&#10003;</span>
    <span class="inspection-result-ok failed-result readonly-result" *ngIf="!getInspectionResult()">✗</span>
  </div>

  <div class="inspection-metadata-container" *ngIf="(readonly && getCommentValue() !== '' && getCommentValue() !== null) || (!readonly)">
    <button [uPopover]="popover"
          [placement]="isRtl() ? 'left-center' : 'right-center'"
          [autoClose]="'outside'"
          [popoverClass]="'u-popover u-popover_rounded comment-popover'"
          [container]="'body'"
          #popoverElement="uPopover"
          class="u-btn-simple u-btn-small icon-btn add-comment-button">
        <span class="icon add-comment-icon" *ngIf="(getCommentValue() === '' || getCommentValue() === null) && !readonly"></span>
        <span class="icon existing-comment-icon" *ngIf="getCommentValue() !== '' && getCommentValue() !== null"></span>
    </button>
    <ng-template #popover>
      <span class="popover-close-btn" (click)="closePopover(popoverElement)"></span>
      <textarea uTextarea class="comment-input" [formControl]="commentFormControl"></textarea>
      <div class="comment-popover-actions">
        <button class="u-btn u-btn-simple u-btn-simple popover-ok" (click)="closePopover(popoverElement)">{{'general.ok' | translate}}</button>
      </div>
    </ng-template>
    <app-files-images-input [imagesForm]="imagesForm" [documentsForm]="documentsForm" [readonly]="readonly"></app-files-images-input>
  </div>
</div>
