import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep'

import { BaseService, CtrlFormsService } from '@app/shared/services';
import supplierAddEditModalConfig from './supplier-add-edit.component.config';
import { AddEditModalService } from '@app/shared/modules/add-edit-modal/services';

@Component({
  selector: 'app-supplier-add-edit',
  templateUrl: './supplier-add-edit.component.html',
  styleUrls: [ './supplier-add-edit.component.scss' ]
})
export class SupplierAddEditComponent implements OnInit {
  editData: any;
  modalConfig: any;
  addEditForm: FormGroup;

  @Output() action = new EventEmitter();

  constructor(
    private baseService: BaseService,
    private ctrlFormsService: CtrlFormsService,
    private addEditModalService: AddEditModalService
  ) {}

  ngOnInit(): void {
    this.baseService.setPaths('suppliers');
    this.setModalConfig();
    this.initAddEditForm();

    if (this.editData) {
      this.ctrlFormsService.patchValueNested(this.addEditForm, this.editData, this.modalConfig.fields);
    }
  }

  setModalConfig(): void {
    this.modalConfig = cloneDeep(supplierAddEditModalConfig);
    this.modalConfig.tabs = this.editData ? this.modalConfig.editTabs : this.modalConfig.tabs;
  }

  submitForm = (): void => {
    if (this.addEditModalService.canSubmit(this.addEditForm)) {
      this.addEditModalService.submitForm(this.addEditForm.value, this.action, this.editData);
    }
  };

  private initAddEditForm(): void {
    this.addEditForm = this.ctrlFormsService.generateModalTabsForm(this.modalConfig);
  }

  deleteSupplier(): void {
    this.addEditModalService.deleteEntity('suppliers.addEditSupplier.messages.deleteConfirm', this.editData, this.action);
  }
}
