import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';

import { LocalizationService } from '@app/shared/services';
import { MetadataService } from '@app/shared/services/metadata.service';

@Component({
  selector: 'app-vehicle-add-edit-authorized-drivers',
  templateUrl: './vehicle-add-edit-authorized-drivers.component.html',
  styleUrls: [ './vehicle-add-edit-authorized-drivers.component.scss', './vehicle-add-edit-authorized-drivers.component.rtl.scss' ]
})
export class VehicleAddEditAuthorizedDriversComponent implements OnInit {
  formObject: FormArray;
  drivers: { name: string, value: number }[] = [];
  dictionaryPath = 'vehicles.addEditVehicle.generalDetails.generalDetails.authorizedDrivers';

  constructor(
    private metadataService: MetadataService,
    public localizationService: LocalizationService
  ) {}

  ngOnInit(): void {
    this.setDrivers();
  }

  private setDrivers(): void {
    if (this.drivers.length === 0) {
      this.metadataService.metadataObsObj['drivers'].subscribe(drivers => this.drivers = drivers);
    }
  }

  removeAuthorizedDriver(index: number): void {
    this.formObject.removeAt(index);
    this.formObject.markAsDirty();
  }

  addNewAuthorizedDriver(): void {
    this.formObject.push(new FormControl(''));
  }
}
