const alertInputConfig = {
  periodUnits: {
    'alertPeriodUnit.none': {
    },
    'alertPeriodUnit.day': {
      minAmount: 1,
      maxAmount: 365
    },
    'alertPeriodUnit.week': {
      minAmount: 1,
      maxAmount: 52
    },
    'alertPeriodUnit.month': {
      minAmount: 1,
      maxAmount: 12
    }
  },
  periodUnitsNoneValue: 'alertPeriodUnit.none',
  periodUnitsDefaultValue: 'alertPeriodUnit.none'
};

export default alertInputConfig;
