import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/filter';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { UPopupService } from '@shift/ulib';
import { USidebarTopMenuItem } from '@shift/ulib/sidebar-menu/sidebar-menu.model';

import appConfig from '@app/app.config';
import { environment } from '@environments/environment';
import { SideMenuService } from '../../services';
import appSideMenuConfig from './side-menu.component.config';
import { CustomerRoleSwitchComponent } from '@app/modules/auth/components';
import apiServiceConfig from '@app/shared/services/api/api.service.config';
import { CobrowseIoService, AuthService, ApiService, CommonService, LocalizationService } from '@app/shared/services';

@Component({
  selector: 'app-side-menu',
  providers: [ BsModalRef, Location, { provide: LocationStrategy, useClass: PathLocationStrategy } ],
  templateUrl: './side-menu.component.html',
  styleUrls: [ './side-menu.component.scss', './side-menu.component.rtl.scss' ],
})
export class SideMenuComponent implements OnInit {
  @select([ 'auth', 'user', 'fullName' ])
    readonly fullName: Observable<any>;

  @select([ 'auth', 'user', 'customerName' ])
    readonly customerName: Observable<any>;

  @select([ 'auth', 'user', 'role' ])
    readonly userRole: Observable<any>;

  showLogout: boolean;
  showSettings: boolean;
  isMenuCollapsed: boolean;
  config = appSideMenuConfig;
  menuItems: USidebarTopMenuItem[] = [];
  isRtl: boolean = this.localizationService.isRtl();

  constructor(
    private commonService: CommonService,
    private authService: AuthService,
    private location: Location,
    private modalService: BsModalService,
    private modalRef: BsModalRef,
    private router: Router,
    private popupService: UPopupService,
    private apiService: ApiService,
    private sideMenuService: SideMenuService,
    private cobrowseIoService: CobrowseIoService,
    private localizationService: LocalizationService
  ) {}

  ngOnInit(): void {
    this.setMenuItems();
    this.sideMenuService.expandMenu();
    this.setMenuState();
    this.setHelpLink();
    this.subscribeToRouteChange();
  }

  private setHelpLink() {
    const helpLinkMenuBottomItem = this.config.menuBottomItems.find(menuBottomItem => menuBottomItem.id === 'help-link');

    if (helpLinkMenuBottomItem) {
      helpLinkMenuBottomItem.link = environment.helpLink;
    }
  }

  private setMenuState(): void {
    this.sideMenuService.isMenuCollapsedObs.subscribe(isMenuCollapsed => this.isMenuCollapsed = isMenuCollapsed);
  }

  private setMenuItems(): void {
    this.userRole.subscribe(userRole => {
      if (userRole) {
        this.menuItems = appSideMenuConfig.menu[userRole.toLowerCase()] || appSideMenuConfig.menu.customer;
        this.setActiveItem(this.location.path().substr(1));
        this.showSettings = appConfig.settings.allowedRoles.includes(userRole);
      }
    });
  }

  private setActiveItem(path: string): void {
    this.menuItems.forEach(item => {
      item.active = item.link === path;
      if (item.children) {
        item.children.forEach(child => {
          item.active = item.active || child.link === path;
          child.active = child.link === path;
        });
      }
    });
  }

  private subscribeToRouteChange(): void {
    this.router.events.filter(event => event instanceof NavigationEnd)
          .subscribe(() => this.setActiveItem(this.router.url.substr(1)));
  }

  onNavigate(item: USidebarTopMenuItem): void {
    this.sideMenuService.expandMenu();

    if (item.id === 'screen-share') {
      this.cobrowseIoService.createCode();
    }

    if (!item.link) {
      return;
    }

    this.commonService.previousUrl = this.router.url;

    if (!item.externalLink) {
      this.router.navigateByUrl(item.link);
    } else {
      window.open(item.link, '_blank');
    }
  }

  logout(): void {
    this.authService.logout();
  }

  toggleSidebar(): void {
    this.sideMenuService.toggleMenu(!this.isMenuCollapsed);
  }

  onMenuToggle(event): void {
    this.sideMenuService.toggleMenu(event);
  }

  showCustomerRoleSwitch(event: any): void {
    event.preventDefault();
    this.apiService.get(apiServiceConfig.paths.auth.basePath + apiServiceConfig.paths.auth.switchRoleInfo).subscribe((switchUserLists: any) => {
      const modalConfig = appSideMenuConfig.switchRoleModalConfig;
      modalConfig.initialState = {switchUserLists};
      this.modalRef = this.modalService.show(CustomerRoleSwitchComponent, modalConfig);
      this.modalRef.content.action.subscribe(({result, customerId, role}) => {
        if (result === 'Ok') {
          this.authService.switchRole(customerId, role).subscribe(
            () => window.location.reload(),
            err => this.popupService.showErrorMessage({message: err.errors && err.errors[0]}),
         () => {});
        }
      });
    });
  }
}
