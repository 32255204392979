import { CustomerAddEditComponent } from '../customer-add-edit/customer-add-edit.component';

const customersPageConfig = {
  modalComponent: CustomerAddEditComponent,
  tableConfig: {
    counterLabel: 'customers.table.customers',
    tableName: 'customers',
    columns: [
      {
        prop: 'check',
        name: '',
        width: 50,
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizeable: false,
        headerCheckboxable: true,
        checkboxable: true,
        custom: true,
        hideDivider: true,
        noValue: true,
        hideable: false
      },
      {
        prop: 'name',
        name: 'customers.table.name',
        filterType: 'select',
        minWidth: 240,
        cellTemplateName: 'editRowCell'
      },
      {
        prop: 'type',
        name: 'customers.table.type',
        translate: true,
        filterType: 'select',
        minWidth: 120,
        maxWidth: 240
      },
      {
        prop: 'address',
        name: 'customers.table.address',
        filterType: 'select',
        selectItems: [],
        minWidth: 220
      },
      {
        prop: 'phone',
        name: 'customers.table.phone',
        filterType: 'text',
        minWidth: 120,
        maxWidth: 250
      },
      {
        prop: 'usersNumber',
        name: 'customers.table.usersCount',
        filterType: 'text',
        minWidth: 80,
        maxWidth: 160
      }
    ]
  }
};

export default customersPageConfig;
