import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IntercomChatService {
  configureChatUser(user, customer) {
    if (user) {
      let emailAddress = '';
      let phone = '';

      if (user && user.lContactDetails && user.lContactDetails.length > 0) {
        const emailContact = user.lContactDetails.find(cd => cd.iContactMethodType === 37);
        const phoneContact = user.lContactDetails.find(cd => cd.iContactMethodType === 14);

        if (emailContact) {
          emailAddress = emailContact.nvContactAddress;
        }

        if (phoneContact) {
          phone = phoneContact.nvContactAddress;
        }
      }

      (<any>window).Intercom('boot', {
        app_id: 'zvel4vc8',
        custom_launcher_selector: '#chat-button',
        name: user.nvFirstName + ' ' + user.nvLastName,
        user_id: user.memberId,
        email: emailAddress,
        phone: phone,
        company: {
          name: customer.nvName,
          company_id: customer.iCustomerId
        }
      });
    }
  }
}
