import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';

@Injectable()
export class AuthActions {
  static readonly AUTH_USER = 'AUTH_USER';

  @dispatch()
    authUser = (payload: any) => ({
      type: AuthActions.AUTH_USER,
      ...payload
    })
}
