import { Injectable } from '@angular/core';
import { UPopupService } from '@shift/ulib';

import { environment } from '@environments/environment';

declare const CobrowseIO: any;

@Injectable()
export class CobrowseIoService {
  constructor(
    private uPopupService: UPopupService
  ) {}

  initializeService = async (data): Promise<void> => {
    CobrowseIO.customData = {
      user_id: data.customerName,
      user_name: data.fullName,
      user_email: '', // TODO: need to align this data to be same as Shift UserInfo (with all data fields)
      device_id: data.role,
      device_name:  null
    };

    CobrowseIO.license = environment.cobrowseLicense;

    await CobrowseIO.client();
    await CobrowseIO.start();

    CobrowseIO.confirmSession = () => {
      return new Promise((resolve, reject) => {
        this.uPopupService.showMessage({
          message: 'cobrowseIO.screenShare',
          yes: 'general.yes',
          no: 'general.no',
        }, () => {
          resolve();
        }, () => {
          reject();
        }, () => {
          reject();
        });
      });
    }
  }

  async createCode(): Promise<void> {
    const currentSession = CobrowseIO.currentSession;

    if (!currentSession || (currentSession && currentSession._resource.state !== 'active')) {
      await CobrowseIO.client();
      const createSessionCode = await CobrowseIO.createSessionCode();

      this.uPopupService.showMessage({
        message: 'cobrowseIO.shareCode',
        yes: 'general.ok',
        copyInput: {
          value: createSessionCode
        }
      }, null);
    }
  }
}
