<div class="inspection-summary-container">
  <div class="total-passed">
    <div class="inspection-result-icon">
      <span class="passed-result">&#10003;</span>
    </div>
    <div class="total-text-container">
      <span class="total-label">{{totalPassed}}</span>
      <span class="total-number">{{inspectionSummaryConfig.totalPassed.title | translate}}</span>
    </div>
  </div>
  <div class="total-failed">
    <div class="inspection-result-icon">
      <span class="failed-result">X</span>
    </div>
    <div class="total-text-container">
      <span class="total-label">{{totalFailed}}</span>
      <span class="total-number">{{inspectionSummaryConfig.totalFailed.title | translate}}</span>
    </div>
  </div>
</div>
