<div class="modal-header">
    <h4 id="dialog-sizes-name1" class="modal-title pull-left">{{'mainPage.customerAndRole.title' | translate}}</h4>
    <div class="u-modal__close" (click)="closeForm()"></div>
</div>
<div class="modal-body">
    <div class="customer-role-switch">
        <div class="customer-role-switch__inputs">
            <u-group [name]="'mainPage.customerAndRole.customer' | translate">
                <u-select-s class="form-select u-group__fields-el"
                            [items]="customersList"
                            [formControl]="selectedCustomer"
                            [valid]="selectedCustomer.valid"
                            [placeholder]="'general.select' | translate"
                            [emptyName]="'general.select' | translate"
                            [emptyValue]="null"
                            [emptyHidden]="true"
                            [emptyRemove]="null"
                            [messages]="'uSelectS' | translate"
                            [placement]="isRtl ? ['bottom-right', 'bottom-left', 'top-right', 'top-left'] : ['bottom-left', 'bottom-right', 'top-left', 'top-right']">
                </u-select-s>
            </u-group>
            <u-group [name]="'mainPage.customerAndRole.role' | translate">
                <u-select-s class="form-select u-group__fields-el"
                            [items]="rolesList"
                            [formControl]="selectedRole"
                            [valid]="selectedRole.valid"
                            [placeholder]="'general.select' | translate"
                            [emptyName]="'general.select' | translate"
                            [emptyHidden]="true"
                            [emptyValue]="null"
                            [emptyRemove]="null"
                            [messages]="'uSelectS' | translate"
                            [placement]="isRtl ? ['bottom-right', 'bottom-left', 'top-right', 'top-left'] : ['bottom-left', 'bottom-right', 'top-left', 'top-right']">
                </u-select-s>
            </u-group>
        </div>
        <div class="customer-role-switch__actions">
            <button class="u-btn u-btn-simple" (click)="setRole()">{{'general.ok' | translate}}</button>
        </div>
    </div>
</div>
