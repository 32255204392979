import { AddEditUsersComponent } from '@app/shared/components';
import inputsConfig from '@app/modules/inputs/ctrl-inputs.config';

const dictionaryPath = 'customers.addEditCustomer.generalDetails';

const fields = {
  id: {
    name: 'id',
    required: true,
    defaultValue: 0
  },
  name: {
    name: 'name',
    required: true,
    inputType: 'text',
  },
  type: {
    name: 'type',
    required: true,
    inputType: 'select',
    inputOptionsVar: 'customerType'
  },
  address: {
    name: 'address',
    required: true,
    inputType: 'address',
  },
  businessIdentity: {
    name: 'businessIdentity',
    required: true,
    valueType: 'string',
    inputType: 'number'
  },
  language: {
    name: 'language',
    required: true,
    inputType: 'select',
    defaultValue: 'he',
    inputOptionsArr: [
      {
        name: 'עברית',
        value: 'he'
      },
      {
        name: 'English',
        value: 'en'
      }
    ]
  },
  contactGroups: {
    name: 'contactGroups',
    arrayField: true,
    inputType: 'contactGroups'
  },
  users: {
    name: 'users',
    inputConfig: inputsConfig.users,
    bodyComponent: AddEditUsersComponent
  }
};

const tabs = [
  {
    id: 'info',
    notNestedForm: true,
    title: `${dictionaryPath}.title`,
    columns: [
      [
        {
          title: `${dictionaryPath}.generalDetails.title`,

          fields: [
            {
              ...fields.name,
              title: `${dictionaryPath}.generalDetails.customerName`
            },
            {
              ...fields.type,
              title: `${dictionaryPath}.generalDetails.customerType`
            },
            {
              ...fields.address,
              title: `${dictionaryPath}.generalDetails.address`
            },
            {
              ...fields.businessIdentity,
              title: `${dictionaryPath}.generalDetails.businessIdentity`
            }
          ]
        },
        {
          title: `${dictionaryPath}.generalDetails.emailNotifications.title`,
          fields: [
            {
              ...fields.language,
              title: `${dictionaryPath}.generalDetails.emailNotifications.language`
            }
          ]
        }
      ],
      [
        {
          fields: [ fields.contactGroups ]
        }
      ]
    ]
  },
  {
    id: 'users',
    notNestedForm: true,
    title: 'addEditUsers.title',
    columns: [
      [
        {
          fields: [ fields.users ]
        }
      ]
    ]
  }
];

const customerAddEditModalConfig = {
  fields,
  tabs,
  hiddenFields: {id: fields.id},
  title: 'customers.addEditCustomer.title',
  deleteLabel: 'customers.addEditCustomer.deleteCustomer',
  categoriesToFetch: [ 'contactType', 'customerType', 'roleType' ]
};

export default customerAddEditModalConfig;
