const dictionaryPath = 'vehicles.addEditVehicle.generalDetails.accounting.generalDetails';

const fields = {
  ownershipType: {
    name: 'ownershipType',
    inputType: 'select',
    required: true,
    inputOptionsVar: 'vehicleOwnershipType',
    defaultValue: 'vehicleOwnershipType.company'
  },
  accountingSupplierId: {
    name: 'accountingSupplierId',
    inputType: 'select',
    inputOptionsVar: 'suppliers'
  },
  contractNumber: {
    name: 'contractNumber',
    inputType: 'number',
    valueType: 'string'
  },
  contractStartDate: {
    name: 'contractStartDate',
    inputType: 'datepicker'
  },
  contractEndDate: {
    name: 'contractEndDate',
    inputType: 'datepicker'
  },
  annualMileage: {
    name: 'annualMileage',
    inputType: 'number',
    valueType: 'number'
  },
  periodMileage: {
    name: 'periodMileage',
    inputType: 'number',
    valueType: 'number'
  },
  oneTimePayment: {
    name: 'oneTimePayment',
    inputType: 'number',
    valueType: 'number',
    decimal: true
  },
  monthlyCost: {
    name: 'monthlyCost',
    inputType: 'number',
    valueType: 'number',
    decimal: true
  },
  worth: {
    name: 'worth',
    inputType: 'number',
    valueType: 'number',
    decimal: true
  },
  accountingKey: {
    name: 'accountingKey',
    inputType: 'text'
  },
  purchaseDate: {
    name: 'purchaseDate',
    inputType: 'datepicker'
  },
  purchaseCost: {
    name: 'purchaseCost',
    inputType: 'number',
    valueType: 'number',
    decimal: true
  },
  saleDate: {
    name: 'saleDate',
    inputType: 'datepicker'
  },
  saleCost: {
    name: 'saleCost',
    inputType: 'number',
    valueType: 'number',
    decimal: true
  }
};

const companyConfig = {
  fields: {
    ownershipType: fields.ownershipType,
    accountingSupplierId: fields.accountingSupplierId,
    worth: fields.worth,
    purchaseDate: fields.purchaseDate,
    purchaseCost: fields.purchaseCost,
    saleDate: fields.saleDate,
    saleCost: fields.saleCost,
    accountingKey: fields.accountingKey
  },
  columns: [
    [
      {
        title: `${dictionaryPath}.title`,
        fields: [
          {
            ...fields.ownershipType,
            title: `${dictionaryPath}.ownershipType`
          },
          {
            ...fields.accountingSupplierId,
            title: `${dictionaryPath}.accountingSupplierId`
          },
          {
            ...fields.worth,
            title: `${dictionaryPath}.worth`
          },
          {
            ...fields.purchaseDate,
            title: `${dictionaryPath}.purchaseDate`
          },
          {
            ...fields.purchaseCost,
            title: `${dictionaryPath}.purchaseCost`
          },
          {
            ...fields.saleDate,
            title: `${dictionaryPath}.saleDate`
          },
          {
            ...fields.saleCost,
            title: `${dictionaryPath}.saleCost`
          }
        ]
      },
      {
        title: `${dictionaryPath}.accounting.title`,
        fields: [
          {
            ...fields.accountingKey,
            title: `${dictionaryPath}.accounting.key`
          }
        ]
      }
    ]
  ]
}

const rentalConfig = {
  fields: {
    ownershipType: fields.ownershipType,
    accountingSupplierId: fields.accountingSupplierId,
    contractNumber: fields.contractNumber,
    contractStartDate: fields.contractStartDate,
    contractEndDate: fields.contractEndDate,
    annualMileage: fields.annualMileage,
    periodMileage: fields.periodMileage,
    oneTimePayment: fields.oneTimePayment,
    monthlyCost: fields.monthlyCost,
    worth: fields.worth,
    accountingKey: fields.accountingKey
  },
  columns: [
    [
      {
        title: `${dictionaryPath}.title`,
        fields: [
          {
            ...fields.ownershipType,
            title: `${dictionaryPath}.ownershipType`
          },
          {
            ...fields.accountingSupplierId,
            title: `${dictionaryPath}.accountingSupplierId`
          },
          {
            ...fields.contractNumber,
            title: `${dictionaryPath}.contractNumber`
          },
          {
            ...fields.contractStartDate,
            title: `${dictionaryPath}.contractStartDate`
          },
          {
            ...fields.contractEndDate,
            title: `${dictionaryPath}.contractEndDate`
          },
          {
            ...fields.annualMileage,
            title: `${dictionaryPath}.annualMileage`
          },
          {
            ...fields.periodMileage,
            title: `${dictionaryPath}.periodMileage`
          },
          {
            ...fields.oneTimePayment,
            title: `${dictionaryPath}.oneTimePayment`
          },
          {
            ...fields.monthlyCost,
            title: `${dictionaryPath}.monthlyCost`
          },
          {
            ...fields.worth,
            title: `${dictionaryPath}.worth`
          }
        ]
      },
      {
        title: `${dictionaryPath}.accounting.title`,
        fields:
        [
          {
            ...fields.accountingKey,
            title: `${dictionaryPath}.accounting.key`
          }
        ]
      }
    ]
  ]
};

const freelanceConfig = {
  fields: {
    ownershipType: fields.ownershipType,
    accountingKey: fields.accountingKey
  },
  columns: [
    [
      {
        title: `${dictionaryPath}.title`,
        fields: [
          {
            ...fields.ownershipType,
            title: `${dictionaryPath}.ownershipType`
          }
        ]
      },
      {
        title: `${dictionaryPath}.accounting.title`,
        fields: [
          {
            ...fields.accountingKey,
            title: `${dictionaryPath}.accounting.key`
          }
        ]
      }
    ]
  ]
};

const vehicleAddEditAccountingConfig = {
  company: companyConfig,
  leasing: rentalConfig,
  rental: rentalConfig,
  freelance: freelanceConfig
};

export default vehicleAddEditAccountingConfig;
