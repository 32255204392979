import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';

import { ConstantsService, LocalizationService } from '@app/shared/services';

@Component({
  selector: 'app-payment-terms-input',
  templateUrl: './payment-terms-input.component.html',
  styleUrls: [ './payment-terms-input.component.scss', './payment-terms-input.component.rtl.scss' ]
})
export class PaymentTermsInputComponent implements OnInit {
  @Input() formObject: FormGroup;

  public isRtl: boolean;
  public paymentTerms: [{}];
  public paymentTypes: [{}];

  constructor(
    private localizationService: LocalizationService,
    private constantsService: ConstantsService
  ) {}

  ngOnInit(): void {
    this.setPaymentTerms();
    this.subscribeToPaymentTypeChange();
    this.isRtl = this.localizationService.isRtl();
  }

  private setPaymentTerms(): void {
    if (!this.formObject.get('accountantPaymentTerms')) {
      this.formObject.addControl('accountantPaymentTerms', new FormControl(''));
    }
    this.constantsService.categories.subscribe(categories => {
      this.paymentTypes = categories['paymentType'];
      this.paymentTerms = categories['paymentTerms'];
    });
  }

  private subscribeToPaymentTypeChange(): void {
    this.paymentTypeControl.valueChanges.subscribe(paymentType => this.initPaymentTermControl(paymentType));
  }

  private initPaymentTermControl(paymentType: string): void {
    if (paymentType !== 'paymentType.current') {
      this.paymentTermControl.setValidators([]);
      this.paymentTermControl.setValue(null);
    } else if (this.paymentTypeControl.validator && this.paymentTypeControl.validator({} as AbstractControl).required) {
      this.paymentTermControl.setValidators([ Validators.required ]);
    }
  }

  get paymentTypeControl(): FormControl {
    return this.formObject.get('accountantPaymentType') as FormControl;
  }

  get paymentTermControl(): FormControl {
    return this.formObject.get('accountantPaymentTerms') as FormControl;
  }
}
