import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { FormsService } from '@app/shared/services';
import { InspectionService } from '../../services';
import inspectionSummaryConfig from './inspection-summary.component.config';

@Component({
  selector: 'app-inspection-summary',
  templateUrl: './inspection-summary.component.html',
  styleUrls: [ './inspection-summary.component.scss' ]
})
export class InspectionSummaryComponent implements OnInit {
  @Input() inspectionForm: FormGroup | FormArray;

  private inspectionSummaryForm: FormGroup;

  inspectionSummaryConfig = inspectionSummaryConfig;

  constructor(private formsService: FormsService, private inspectionService: InspectionService) { }

  ngOnInit(): void {
    this.initInspectionForm();
  }

  initInspectionForm(): void {
    this.inspectionSummaryForm = this.formsService.generateForm([ this.inspectionSummaryConfig.totalPassed, this.inspectionSummaryConfig.totalFailed ]);
    this.inspectionService.totalPassed.subscribe(totalPassed =>
          this.inspectionSummaryForm.get('totalPassed').setValue(totalPassed));
    this.inspectionService.totalFailed.subscribe(totalFailed =>
          this.inspectionSummaryForm.get('totalFailed').setValue(totalFailed));
  }

  get totalPassed(): string {
    return this.inspectionSummaryForm && this.inspectionSummaryForm.get('totalPassed').value;
  }

  get totalFailed(): string {
    return this.inspectionSummaryForm && this.inspectionSummaryForm.get('totalFailed').value;
  }
}
