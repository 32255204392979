import { InspectionSummaryComponent } from '../inspection-summary/inspection-summary.component';
import { InspectionCategoriesComponent } from '../inspection-categories/inspection-categories.component';

const dictionaryPath = 'inspections.addEditInspection';

const fields = {
  id: {
    name: 'id',
    inputType: 'label'
  },
  date: {
    name: 'date',
    inputType: 'label',
    dateFormat: 'dd/MM/yy',
  },
  safetyOfficerName: {
    name: 'safetyOfficerName',
    inputType: 'label'
  },
  vehicleLicenseNumber: {
    name: 'vehicleLicenseNumber',
    inputType: 'label',
    inputOptionsVar: 'vehicles'
  },
  driverName: {
    name: 'driverName',
    inputType: 'label'
  },
  currentMileage: {
    name: 'currentMileage',
    inputType: 'label'
  },
  nextInspectionMileage: {
    name: 'nextInspectionMileage',
    inputType: 'label'
  },
  nextInspectionDate: {
    name: 'nextInspectionDate',
    inputType: 'label',
    dateFormat: 'dd/MM/yy'
  },
  categories: {
    name: 'categories',
    inputType: 'categories',
    config: {
      readonly: true
    },
    bodyComponent: InspectionCategoriesComponent
  },
  inspectionSummary: {
    name: 'inspectionSummary',
    bodyComponent: InspectionSummaryComponent
  },
  status: {
    name: 'status',
    translate: true,
    inputType: 'label'
  },
  notes: {
    name: 'notes',
    inputType: 'label'
  }
};

const tabs = [
  {
    notNestedForm: true,
    columns: [
      [
        {
          title: `${dictionaryPath}.generalDetails.title`,
          fields: [
            {
              ...fields.id,
              title: `${dictionaryPath}.generalDetails.id`
            },
            {
              ...fields.date,
              title: `${dictionaryPath}.generalDetails.date`
            },
            {
              ...fields.safetyOfficerName,
              title: `${dictionaryPath}.generalDetails.safetyOfficer`
            },
            {
              ...fields.vehicleLicenseNumber,
              title: `${dictionaryPath}.generalDetails.licenseNumber`
            },
            {
              ...fields.driverName,
              title:  `${dictionaryPath}.generalDetails.driver`
            },
            {
              ...fields.currentMileage,
              title: `${dictionaryPath}.generalDetails.currentMileage`
            },
            {
              ...fields.nextInspectionMileage,
              title: `${dictionaryPath}.generalDetails.nextInspectionMileage`
            },
            {
              ...fields.nextInspectionDate,
              title: `${dictionaryPath}.generalDetails.nextInspectionDate`
            }
          ]
        },
        {
          name: 'categories',
          fields: [ fields.categories ]
        },
        {
          title: `${dictionaryPath}.summary.title`,
          fields: [ fields.inspectionSummary ]
        },
        {
          title: `${dictionaryPath}.vehicleStatus.title`,
          fields: [
            {
              ...fields.status,
              title: `${dictionaryPath}.vehicleStatus.vehicleStatus`
            },
            {
              ...fields.notes,
              title: `${dictionaryPath}.vehicleStatus.notes`
            }
          ]
        }
      ]
    ]
  }
];

const inspectionAddEditReadonlyConfig = {
  tabs,
  fields,
  id: 'inspection',
  readonly: true,
  enableDelete: true,
  title: `${dictionaryPath}.title`,
  deleteLabel: `${dictionaryPath}.deleteInspection`
};

export default inspectionAddEditReadonlyConfig;
