const historyTableConfig = {
  tabId: 'history',
  tableConfig: {
    columns: [
      {
        prop: 'type',
        name: 'historyTable.changeType',
        minWidth: 120,
        maxWidth: 240,
        filterType: 'select'
      },
      {
        prop: 'content',
        name: 'historyTable.changeContent',
        minWidth: 120,
        maxWidth: 240,
        filterType: 'text'
      },
      {
        prop: 'author',
        name: 'historyTable.changePerformer',
        filter: 'text',
        minWidth: 120,
        maxWidth: 240,
        filterType: 'select'
      },
      {
        prop: 'changeDate',
        name: 'historyTable.changeDate',
        minWidth: 120,
        maxWidth: 240,
        filterType: 'text'
      },
      {
        prop: 'changeTime',
        name: 'historyTable.changeTime',
        minWidth: 120,
        maxWidth: 240,
        filterType: 'text'
      }
    ]
  }
};

export default historyTableConfig;
