import { ToastrService, IndividualConfig } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';

import toastrConfig from './toastr.config';

@Injectable()
export class LocalizedToastrService {
  constructor(
    private toastr: ToastrService,
    public translate: TranslateService) {
    this.setToastrConfig();
  }

  private setToastrConfig() {
    Object.assign(this.toastr.toastrConfig, toastrConfig)
  }

  show(message?: string, title?: string, override?: Partial<IndividualConfig>, type?: string) {
    forkJoin(this.getToastrArr(message, title)).subscribe(([ localizedMessage, localizedTitle ]) => {
      this.toastr.show(localizedMessage, localizedTitle, override, type);
    })
  }

  success(message?: string, title?: string, override?: Partial<IndividualConfig>) {
    forkJoin(this.getToastrArr(message, title)).subscribe(([ localizedMessage, localizedTitle ]) => {
      this.toastr.success(localizedMessage, localizedTitle, override);
    })
  }

  error(message?: string, title?: string, override?: Partial<IndividualConfig>) {
    forkJoin(this.getToastrArr(message, title)).subscribe(([ localizedMessage, localizedTitle ]) => {
      this.toastr.error(localizedMessage, localizedTitle, override);
    })
  }

  info(message?: string, title?: string, override?: Partial<IndividualConfig>) {
    forkJoin(this.getToastrArr(message, title)).subscribe(([ localizedMessage, localizedTitle ]) => {
      this.toastr.info(localizedMessage, localizedTitle, override);
    })
  }

  warning(message?: string, title?: string, override?: Partial<IndividualConfig>) {
    forkJoin(this.getToastrArr(message, title)).subscribe(([ localizedMessage, localizedTitle ]) => {
      this.toastr.warning(localizedMessage, localizedTitle, override);
    })
  }

  getToastrArr(message?: string, title?: string) {
    const arr = [];
    if (message) {
      arr.push(this.translate.get(message))
    }
    if (title) {
      arr.push(this.translate.get(title))
    }

    return arr;
  }
}
