import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import safetyOfficerAddEditModalConfig from './safety-officer-add-edit.component.config';
import { BaseService, CtrlFormsService } from '@app/shared/services';
import { AddEditModalService } from '@app/shared/modules/add-edit-modal/services';

@Component({
  selector: 'app-safety-officer-add-edit',
  templateUrl: './safety-officer-add-edit.component.html',
  styleUrls: [ './safety-officer-add-edit.component.scss', './safety-officer-add-edit.component.rtl.scss' ]
})
export class SafetyOfficerAddEditComponent implements OnInit {
  addEditForm: FormGroup;
  editData: any;
  modalConfig: any;

  @Output() action = new EventEmitter();

  constructor(
    private baseService: BaseService,
    private ctrlFormsService: CtrlFormsService,
    private addEditModalService: AddEditModalService
  ) {}

  ngOnInit(): void {
    this.setModalConfig();
    this.initAddEditForm();
    this.baseService.setPaths('safetyOfficers');
    if (this.editData) {
      this.ctrlFormsService.patchValueNested(this.addEditForm, this.editData, this.modalConfig.fields);
    }
  }

  initAddEditForm(): void {
    this.addEditForm = this.ctrlFormsService.generateModalTabsForm(safetyOfficerAddEditModalConfig);
  }

  setModalConfig(): void {
    this.modalConfig = cloneDeep(safetyOfficerAddEditModalConfig);
    this.modalConfig.tabs = this.editData ? this.modalConfig.editTabs : this.modalConfig.tabs;
  }

  deleteSafetyOfficer(): void {
    this.addEditModalService.deleteEntity('safetyOfficers.addEditSafetyOfficer.messages.deleteConfirm', this.editData, this.action);
  }

  submitForm = (): void => {
    if (this.addEditModalService.canSubmit(this.addEditForm)) {
      this.addEditModalService.submitForm(this.addEditForm.value, this.action, this.editData);
    }
  };
}
