import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { LocalizationService } from '@app/shared/services';
import { ModalActions, ModalButton } from '@app/shared/models';

@Component({
  selector: 'app-license-agreement',
  templateUrl: './license-agreement.component.html',
  styleUrls: [ './license-agreement.component.scss', './license-agreement.component.rtl.scss' ]
})
export class LicenseAgreementComponent implements OnInit {
  isRtl: boolean = this.localizationService.isRtl();
  buttons: ModalButton[] = [ { name: 'general.close', action: ModalActions.shutDown } ];

  constructor(
    private modalRef: BsModalRef,
    private translate: TranslateService,
    private localizationService: LocalizationService
  ) {}

  ngOnInit() {
    this.translateButtons();
  }

  private translateButtons() {
    this.buttons.forEach(button =>
      this.translate.get(button.name)
        .pipe(first())
        .subscribe(translated => button.name = translated));
  }

  checkActions(action: ModalActions) {
    if (action === ModalActions.shutDown) {
      this.modalRef.hide();
    }
  }
}
