import { AppConstants } from '@app/shared/constants';

const dictionaryPath = 'alerts.table.expirationPopover';
const datePopoverConfig = {
  title: `${dictionaryPath}.updateDateTitle`,
  fields: [
    {
      name: 'date',
      required: true,
      inputType: 'datepicker',
      dateFormat: AppConstants.DATE_FORMAT_BASE_DOT_SHORT,
      linkedField: 'expirationDate',
      title: `${dictionaryPath}.updatedDate`
    },
    {
      name: 'notes',
      inputType: 'textarea',
      linkedField: 'notes',
      title: `${dictionaryPath}.notes`
    }
  ]
};
const mileagePopoverConfig = {
  title: `${dictionaryPath}.updateMileageTitle`,
  fields: [
    {
      name: 'mileage',
      required: true,
      inputType: 'number',
      valueType: 'number',
      linkedField: 'expirationMileage',
      title: `${dictionaryPath}.updatedMileage`
    },
    {
      name: 'notes',
      inputType: 'textarea',
      linkedField: 'notes',
      title: `${dictionaryPath}.notes`
    }
  ]
};

const alertExpirationPopoverConfig = {
  dictionaryPath,
  date: datePopoverConfig,
  mileage: mileagePopoverConfig,
  documentDate: datePopoverConfig
};

export default alertExpirationPopoverConfig;
