import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { LocalizationService } from '@app/shared/services';

@Component({
  selector: 'app-next-inspection-input',
  templateUrl: './next-inspection-input.component.html',
  styleUrls: [ './next-inspection-input.component.scss', './next-inspection-input.component.rtl.scss' ]
})
export class NextInspectionInputComponent implements OnInit {
  @Input() field: {};
  @Input() formObject: FormGroup;

  public isRtl: boolean;

  constructor(private localizationService: LocalizationService) { }

  ngOnInit(): void {
    this.isRtl = this.localizationService.isRtl();
  }

  get kilometerFormControl(): FormControl {
    return this.formObject.get('nextInspectionMileage') as FormControl;
  }

  get dateFormControl(): FormControl {
    return this.formObject.get('nextInspectionDate') as FormControl;
  }
}
