import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { ExistingEntityService } from '@app/shared/services/existing-entity/existing-entity.service';

@Component({
  selector: 'app-id-input',
  templateUrl: './id-input.component.html',
  styleUrls: [ './id-input.component.scss' ]
})
export class IdInputComponent implements OnInit {
  @Input() field: {};
  @Input() formObject: FormGroup;
  @Input() formControl: FormControl;

  constructor(public existingEntityService: ExistingEntityService) { }

  ngOnInit(): void {
    this.existingEntityService.init(this.field, this.formControl, this.formObject);
  }
}
