import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { TranslateModule } from '@ngx-translate/core';
import { UGridModule, UPopoverModule, USplitLayoutModule, UCommonModule } from '@shift/ulib';

import {
  ApiService,
  AppTitleService,
  ConstantsService,
  FormsService,
  GlobalSearchService,
  LocalizationService,
  LocalizedToastrService,
  StatusesService,
  ValidationService,
  MockUpService,
  LoaderService,
  CommonService,
  CtrlFormsService,
  CobrowseIoService,
  IntercomChatService,
  HeaderDataService,
  AuthService,
  TokenService,
  BaseService,
  PersonService
} from '@app/shared/services';
import {
  TablePageComponent,
  TableElementComponent,
  DocumentsPageComponent,
  PopoverCellComponent,
  HistoryTableComponent,
  AddEditUsersComponent,
  FooterComponent,
  WeekSwitchComponent,
  AlertExpirationPopoverComponent
} from '@app/shared/components';
import { TablePageService } from './components/table-page/table-page.service';
import { AddEditModalModule } from './modules/add-edit-modal/add-edit-modal.module';
import { InputsModule } from '@app/modules/inputs/inputs.module';
import { AddEditUsersService } from './components/add-edit-users/add-edit-users.service';
import { DurationPipe } from './pipes/duration/duration.pipe';
import { ExistingEntityService } from './services/existing-entity/existing-entity.service';
import { DynamicElementsModule } from './modules/dynamic-elements/dynamic-elements.module';
import { MetadataService } from './services/metadata.service';
import { PopoverCellService } from './components/table-page/popover-cell/popover-cell.service';
import { LoaderInterceptor } from '@app/shared/interceptors';
import { VehiclesService } from '@app/modules/vehicles/services';
import { EventAddEditComponent } from '@app/modules/events/components';
import { DriverAddEditComponent } from '@app/modules/drivers/components';
import { SupplierAddEditComponent } from '@app/modules/suppliers/components';
import { InspectionsModule } from '@app/modules/inspections/inspections.module';
import { SafetyOfficerAddEditComponent } from '@app/modules/safety-officers/components';
import { VehicleAddEditAuthorizedDriversComponent, VehicleAddEditComponent, VehicleAddEditSuppliersComponent } from '@app/modules/vehicles/components';

@NgModule({
  imports: [
    CommonModule,
    USplitLayoutModule,
    UGridModule,
    TranslateModule,
    NgHttpLoaderModule,
    UPopoverModule,
    AddEditModalModule,
    InputsModule,
    UCommonModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    DynamicElementsModule,
    ClickOutsideModule,
    FormsModule,
    InspectionsModule
  ],
  declarations: [
    TablePageComponent,
    TableElementComponent,
    AddEditUsersComponent,
    DocumentsPageComponent,
    PopoverCellComponent,
    HistoryTableComponent,
    DurationPipe,
    FooterComponent,
    WeekSwitchComponent,
    AlertExpirationPopoverComponent,
    DriverAddEditComponent,
    EventAddEditComponent,
    SupplierAddEditComponent,
    VehicleAddEditComponent,
    SafetyOfficerAddEditComponent,
    VehicleAddEditSuppliersComponent,
    VehicleAddEditAuthorizedDriversComponent
  ],
  providers: [
    ApiService,
    BaseService,
    AuthService,
    TokenService,
    FormsService,
    PersonService,
    CtrlFormsService,
    LoaderService,
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    AppTitleService,
    ConstantsService,
    GlobalSearchService,
    LocalizationService,
    LocalizedToastrService,
    ValidationService,
    StatusesService,
    MockUpService,
    TablePageService,
    AddEditUsersService,
    ExistingEntityService,
    CommonService,
    MetadataService,
    PopoverCellService,
    DatePipe,
    CobrowseIoService,
    IntercomChatService,
    HeaderDataService,
    VehiclesService
  ],
  exports: [
    TablePageComponent,
    AddEditUsersComponent,
    DocumentsPageComponent,
    FooterComponent,
    WeekSwitchComponent
  ]
})
export class SharedModule {
}
