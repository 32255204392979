import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class SideMenuService {
  isMenuCollapsed: boolean;
  isMenuCollapsedObs: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor() {}

  private dispatchResizeEvent(): void {
    setTimeout(() => window.dispatchEvent(new Event('resize')));
  }

  toggleMenu(isCollapsed = null): void {
    if (isCollapsed !== this.isMenuCollapsed) {
      this.isMenuCollapsed = isCollapsed  !== null ? isCollapsed : !this.isMenuCollapsed;
      this.isMenuCollapsedObs.next(this.isMenuCollapsed);
      this.dispatchResizeEvent();
    }
  }

  collapseMenu(): void {
    this.isMenuCollapsed = true;
    this.isMenuCollapsedObs.next(true);
    this.dispatchResizeEvent();
  }

  expandMenu(): void {
    this.isMenuCollapsed = false;
    this.isMenuCollapsedObs.next(false);
    this.dispatchResizeEvent();
  }
}
