const dictionaryPath = 'events.addEditEvent';

const addEditEventWitnessesConfig = {
  contactGroupsInputConfig: {
    contactsClass: 'witnesses',
    dictionaryPath: 'contactDetails',
    title: `${dictionaryPath}.witnesses.title`,
    buttons: {
      addNewContact: `${dictionaryPath}.witnesses.addNew`
    },
    fields: {
      contactInfo: {
        name: 'contact',
        title: `${dictionaryPath}.witnesses.name`,
        inputs: [
          {
            name: 'name',
            required: true,
            inputType: 'text',
            placeholder: `contactDetails.name`
          }
        ]
      }
    },
    formControls: [
      {
        name: 'name',
        required: true
      },
      {
        name: 'id',
        defaultValue: 0
      },
      {
        name: 'contacts',
        arrayField: true,
        inputType: 'contacts'
      }
    ]
  }
};

export default addEditEventWitnessesConfig;
