import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep'

import { MetadataService } from '@app/shared/services/metadata.service';
import eventAddEditConfig from './events-add-edit.component.config';
import { BaseService, CtrlFormsService } from '@app/shared/services';
import { AddEditModalService } from '@app/shared/modules/add-edit-modal/services';

@Component({
  selector: 'app-event-add-edit',
  templateUrl: './event-add-edit.component.html',
  styleUrls: [ './event-add-edit.component.scss', './event-add-edit.component.rtl.scss' ]
})
export class EventAddEditComponent implements OnInit {
  editData: any;
  modalConfig: any;
  addEditForm: FormGroup;
  isLoading = true;

  @Output() action = new EventEmitter();

  constructor(private baseService: BaseService, private ctrlFormService: CtrlFormsService, private addEditModalService: AddEditModalService, private metadataService: MetadataService) {}

  ngOnInit(): void {
    this.baseService.setPaths('events');
    this.setModalConfig();
    this.metadataService.setMetadataObs(this.modalConfig);
    this.fetchAvailableVehicles();
    this.initAddEditForm();

    if (this.editData) {
      this.ctrlFormService.patchValueNested(this.addEditForm, this.editData, this.modalConfig.fields);
    }
  }

  setModalConfig(): void {
    this.modalConfig = cloneDeep(eventAddEditConfig);
    this.modalConfig.tabs = this.editData ? this.modalConfig.editTabs : this.modalConfig.tabs;
  }

  initAddEditForm(): void {
    this.addEditForm = this.ctrlFormService.generateModalTabsForm(eventAddEditConfig, this.editData);
  }

  deleteEvent(): void {
    this.addEditModalService.deleteEntity('events.addEditEvent.messages.deleteConfirm', this.editData, this.action);
  }

  submitForm = (): void => {
    if (this.addEditModalService.canSubmit(this.addEditForm)) {
      this.addEditModalService.submitForm(this.addEditForm.value, this.action, this.editData);
    }
  };

  fetchAvailableVehicles(): void {
    this.baseService.getItems('availableVehicles').subscribe(vehicles => {
      this.metadataService.setMetadataItemValue('vehicles', vehicles);
      this.metadataService.setMetadataItemValue('drivers', []);
      this.onVehicleSelect();
      this.isLoading = false;
    });
  }

  onVehicleSelect(): void {
    this.addEditForm.get('vehicleId').valueChanges.subscribe(newVal => {
      this.metadataService.metadataObsObj['vehicles'].subscribe(vehicles => {
        const vehicleObject = vehicles.find(vehicle => vehicle.value === newVal);
        if (vehicleObject) {
          this.addEditForm.patchValue(vehicleObject);
          this.baseService.getAllSlim({BranchIds: [ vehicleObject.branchId ]}, 'drivers').subscribe(drivers => {
            this.metadataService.setMetadataItemValue('drivers', drivers);
            if (vehicleObject.driverId) {
              this.addEditForm.get('driverId').setValue(vehicleObject.driverId);
            }
          });
        }
      })
    });
  }
}
