import { Component, OnInit } from '@angular/core';

import { BaseService } from '@app/shared/services';
import safetyOfficersPageConfig from './safety-officers-page.component.config';

@Component({
  selector: 'app-safety-officers-page',
  templateUrl: './safety-officers-page.component.html',
  styleUrls: [ './safety-officers-page.component.scss', './safety-officers-page.component.rtl.scss' ]
})
export class SafetyOfficersPageComponent implements OnInit {
  rows: any[] = [];
  editData: any = null;
  safetyOfficersPageConfig: any = safetyOfficersPageConfig;

  constructor(private baseService: BaseService) { }

  ngOnInit(): void {
    this.baseService.setPaths('safetyOfficers');
    this.fetchSafetyOfficers();
  }

  fetchSafetyOfficers(): void {
    this.baseService.getAll().subscribe(safetyOfficers => this.rows = safetyOfficers);
  }
}
