import { combineReducers } from 'redux';
import { composeReducers, defaultFormReducer } from '@angular-redux/form';
import { routerReducer } from '@angular-redux/router';

import { createAuthAPIReducer } from './auth/auth.reducer';
import { createLanguagesAPIReducer } from './languages/languages.reducer';
import { createCommonAPIReducer } from './common/common.reducer';

export const rootReducer = composeReducers(
    defaultFormReducer(),
    combineReducers({
      auth: createAuthAPIReducer(),
      languages: createLanguagesAPIReducer(),
      common: createCommonAPIReducer(),
      router: routerReducer
    })
);
