export default {
  alert: {
    fields: [
      {
        name: 'type'
      },
      {
        name: 'periodUnit'
      },
      {
        name: 'amount'
      }
    ]
  },
  address: {
    generateFormControls: false,
    fields: [
      {
        name: 'fullAddress'
      },
      {
        name: 'latitude'
      },
      {
        name: 'longitude'
      }
    ]
  },
  categories: {
    arrayField: true,
    fields: [
      {
        name: 'items'
      },
      {
        name: 'category'
      }
    ]
  },
  nextInspection: {
    noParent: true,
    fields: [
      {
        name: 'nextInspectionMileage'
      },
      {
        name: 'nextInspectionDate'
      }
    ]
  },
  inspection: {
    fields: [
      {
        name: 'type'
      },
      {
        name: 'ok',
        defaultValue: true
      },
      {
        name: 'comment'
      },
      {
        name: 'images',
        arrayField: true
      },
      {
        name: 'documents',
        arrayField: true
      }
    ]
  },
  contactGroupsNoRole: {
    arrayField: true,
    generateFormControls: false,
    fields: [
      {
        name: 'name',
        required: true
      },
      {
        name: 'id',
        defaultValue: 0
      },
      {
        name: 'contacts',
        arrayField: true,
        inputType: 'contacts'
      }
    ]
  },
  contactGroups: {
    arrayField: true,
    generateFormControls: false,
    fields: [
      {
        name: 'name',
        required: true
      },
      {
        name: 'role',
        required: true
      },
      {
        name: 'id',
        defaultValue: 0
      },
      {
        name: 'contacts',
        arrayField: true,
        inputType: 'contacts'
      }
    ]
  },
  contacts: {
    arrayField: true,
    fields: [
      {
        name: 'type'
      },
      {
        name: 'contact'
      }
    ]
  },
  file: {
    fields: [
      {
        name: 'fileName'
      },
      {
        name: 'fileSrc'
      },
      {
        name: 'fileId'
      }
    ]
  },
  fileExpiration: {
    fields: [
      {
        name: 'fileName'
      },
      {
        name: 'fileSrc'
      },
      {
        name: 'fileId'
      },
      {
        name: 'expirationDate'
      }
    ]
  },
  users: {
    arrayField: true,
    fields: [
      {
        name: 'id'
      },
      {
        name: 'firstName'
      },
      {
        name: 'lastName'
      },
      {
        name: 'role'
      },
      {
        name: 'deletable'
      },
      {
        name: 'contacts',
        arrayField: true,
        inputType: 'contacts'
      }
    ]
  },
  documents: {
    arrayField: true,
    fields: [
      {
        name: 'type'
      },
      {
        name: 'fileId'
      },
      {
        name: 'fileName'
      },
      {
        name: 'expirationDate'
      }
    ]
  },
  departments: {
    arrayField: true,
    fields: [
      {
        name: 'id',
        defaultValue: 0
      },
      {
        name: 'name'
      }
    ]
  }
};
