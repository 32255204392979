import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TabsModule } from 'ngx-bootstrap';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { UCommonModule } from '@shift/ulib';

import { InputsModule } from '@app/modules/inputs/inputs.module';
import { EventAddEditComponent } from '@app/modules/events/components';
import { DriverAddEditComponent } from '@app/modules/drivers/components';
import { SupplierAddEditComponent } from '@app/modules/suppliers/components';
import { ContactGroupsInputComponent } from '@app/modules/inputs/components';
import { SafetyOfficerAddEditComponent } from '@app/modules/safety-officers/components';
import { DynamicFieldComponent } from './components/dynamic-field/dynamic-field.component';
import { DynamicComponentComponent } from './components/dynamic-component/dynamic-component.component';
import { VehicleAddEditAuthorizedDriversComponent, VehicleAddEditComponent, VehicleAddEditSuppliersComponent } from '@app/modules/vehicles/components';
import { AddEditUsersComponent, TableElementComponent, DocumentsPageComponent, HistoryTableComponent, AlertExpirationPopoverComponent } from '@app/shared/components';

@NgModule({
  imports: [
    CommonModule,
    InputsModule,
    UCommonModule,
    ReactiveFormsModule,
    TranslateModule,
    TabsModule,
    NgHttpLoaderModule
  ],
  declarations: [
    DynamicFieldComponent,
    DynamicComponentComponent
  ],
  entryComponents: [
    AddEditUsersComponent,
    ContactGroupsInputComponent,
    TableElementComponent,
    DocumentsPageComponent,
    HistoryTableComponent,
    DriverAddEditComponent,
    VehicleAddEditComponent,
    EventAddEditComponent,
    SupplierAddEditComponent,
    SafetyOfficerAddEditComponent,
    AlertExpirationPopoverComponent,
    VehicleAddEditSuppliersComponent,
    VehicleAddEditAuthorizedDriversComponent
  ],
  exports: [
    DynamicFieldComponent,
    DynamicComponentComponent
  ]
})
export class DynamicElementsModule { }
