import ctrlInputConfig from '../../../inputs/ctrl-inputs.config';
import eventAddEditDocumentsConfig from './event-add-edit-configs/event-add-edit-documents.config';
import addEditEventWitnessesConfig from './event-add-edit-configs/event-add-edit-witnesses.config';
import { HistoryTableComponent } from '@app/shared/components/history-table/history-table.component';
import { DocumentsPageComponent } from '@app/shared/components/documents-page/documents-page.component';

const dictionaryPath = 'events.addEditEvent';

const fields = {
  id: {
    name: 'id',
    required: true,
    defaultValue: 0
  },
  date: {
    name: 'date',
    required: true,
    inputType: 'datepicker'
  },
  address: {
    name: 'address',
    inputType: 'address'
  },
  localTime: {
    name: 'localTime',
    inputType: 'time'
  },
  type: {
    name: 'type',
    inputType: 'select',
    inputOptionsVar: 'eventType'
  },
  severity: {
    name: 'severity',
    inputType: 'select',
    inputOptionsVar: 'eventSeverity'
  },
  responsibility: {
    name: 'responsibility',
    required: true,
    inputType: 'select',
    inputOptionsVar: 'eventResponsibility',
    defaultValue: 'eventResponsibility.unknown'
  },
  safetyOfficerStatus: {
    name: 'safetyOfficerStatus',
    required: true,
    inputType: 'select',
    inputOptionsVar: 'eventSafetyOfficerStatus',
    defaultValue: 'eventSafetyOfficerStatus.new'
  },
  prosecutionStatus: {
    name: 'prosecutionStatus',
    required: true,
    inputType: 'select',
    inputOptionsVar: 'eventProsecutionStatus',
    defaultValue: 'eventProsecutionStatus.new'
  },
  vehicleId: {
    name: 'vehicleId',
    required: true,
    inputType: 'select',
    inputOptionsVar: 'vehicles'
  },
  driverId: {
    name: 'driverId',
    inputType: 'select',
    inputOptionsVar: 'drivers'
  },
  manufacturer: {
    name: 'manufacturer',
    inputType: 'label'
  },
  model: {
    name: 'model',
    inputType: 'label'
  },
  manufactureYear: {
    name: 'manufactureYear',
    inputType: 'label'
  },
  insurancePolicyNumber: {
    name: 'insurancePolicyNumber',
    inputType: 'number',
    valueType: 'string'
  },
  documents: {
    name: 'documents',
    inputType: 'documents',
    config: eventAddEditDocumentsConfig,
    bodyComponent: DocumentsPageComponent
  },
  policeReport: {
    name: 'policeReport',
    fields: {
      journalNumber: {
        name: 'journalNumber',
        inputType: 'number',
        valueType: 'string'
      },
      caseNumber: {
        name: 'caseNumber',
        inputType: 'number',
        valueType: 'string'
      },
      description: {
        name: 'description',
        inputType: 'textarea'
      }
    }
  },
  thirdParty: {
    name: 'thirdParty',
    fields: {
      driverName: {
        name: 'driverName',
        inputType: 'text'
      },
      identity: {
        name: 'identity',
        inputType: 'number'
      },
      address: {
        name: 'address',
        inputType: 'address'
      },
      vehicleLicenseNumber: {
        name: 'vehicleLicenseNumber',
        inputType: 'number',
        valueType: 'string'
      },
      vehicleType: {
        name: 'vehicleType',
        inputType: 'text'
      },
      manufactureYear: {
        name: 'manufactureYear',
        inputType: 'number',
        valueType: 'number'
      },
      color: {
        name: 'color',
        inputType: 'text'
      },
      insuranceCompany: {
        name: 'insuranceCompany',
        inputType: 'text'
      },
      policyNumber: {
        name: 'policyNumber',
        inputType: 'number',
        valueType: 'string'
      },
      notes: {
        name: 'notes',
        inputType: 'textarea'
      },
      contacts: {
        name: 'contacts',
        inputType: 'contacts'
      }
    }
  },
  witnesses: {
    name: 'witnesses',
    arrayField: true,
    inputType: 'contactGroups',
    config: addEditEventWitnessesConfig,
    inputConfig: ctrlInputConfig.contactGroupsNoRole
  }
};

const tabs = [
  {
    id: 'info',
    notNestedForm: true,
    title: `${dictionaryPath}.info.title`,
    columns: [
      [
        {
          title: `${dictionaryPath}.info.generalDetails.title`,
          fields: [
            {
              ...fields.date,
              title: `${dictionaryPath}.info.generalDetails.date`
            },
            {
              ...fields.address,
              title: `${dictionaryPath}.info.generalDetails.location`
            },
            {
              ...fields.localTime,
              title: `${dictionaryPath}.info.generalDetails.time`
            },
            {
              ...fields.type,
              title: `${dictionaryPath}.info.generalDetails.eventClassification`
            },
            {
              ...fields.severity,
              title: `${dictionaryPath}.info.generalDetails.eventSeverity`
            },
          ]
        },
        {
          title: `${dictionaryPath}.info.status.title`,
          fields: [
            {
              ...fields.responsibility,
              title: `${dictionaryPath}.info.status.responsibility`
            },
            {
              ...fields.safetyOfficerStatus,
              title: `${dictionaryPath}.info.status.safetyOfficerStatus`
            },
            {
              ...fields.prosecutionStatus,
              title: `${dictionaryPath}.info.status.prosecutionStatus`
            },
          ]
        },
        {
          title: `${dictionaryPath}.info.carDriverInfo.title`,
          fields: [
            {
              ...fields.vehicleId,
              title: `${dictionaryPath}.info.carDriverInfo.vehicleLicenseNumber`
            },
            {
              ...fields.driverId,
              title: `${dictionaryPath}.info.carDriverInfo.associatedDriver`
            },
            {
              ...fields.manufacturer,
              title: `${dictionaryPath}.info.carDriverInfo.manufacturer`
            },
            {
              ...fields.model,
              title: `${dictionaryPath}.info.carDriverInfo.model`
            },
            {
              ...fields.manufactureYear,
              title: `${dictionaryPath}.info.carDriverInfo.dateOfProduction`
            },
            {
              ...fields.insurancePolicyNumber,
              title: `${dictionaryPath}.thirdParty.details.policyNumber`
            }
          ]
        }
      ]
    ]
  },
  {
    id: 'documents',
    notNestedForm: true,
    title: `${dictionaryPath}.documents.title`,
    columns: [
      [
        {
          fields: [ fields.documents ]
        }
      ]
    ]
  },
  {
    id: 'policeReport',
    title: `${dictionaryPath}.policeReport.title`,
    columns: [
      [
        {
          title: `${dictionaryPath}.policeReport.title`,
          fields: [
            {
              ...fields.policeReport.fields.journalNumber,
              title: `${dictionaryPath}.policeReport.journalNumber`
            },
            {
              ...fields.policeReport.fields.caseNumber,
              title: `${dictionaryPath}.policeReport.caseNumber`
            },
            {
              ...fields.policeReport.fields.description,
              title: `${dictionaryPath}.policeReport.eventDescription`
            }
          ]
        }
      ]
    ]
  },
  {
    id: 'thirdParty',
    title: `${dictionaryPath}.thirdParty.title`,
    columns: [
      [
        {
          title: `${dictionaryPath}.thirdParty.details.title`,
          fields: [
            {
              ...fields.thirdParty.fields.driverName,
              title: `${dictionaryPath}.thirdParty.details.firstName`
            },
            {
              ...fields.thirdParty.fields.identity,
              title: `${dictionaryPath}.thirdParty.details.identity`
            },
            {
              ...fields.thirdParty.fields.address,
              title: `${dictionaryPath}.thirdParty.details.address`
            },
            {
              ...fields.thirdParty.fields.vehicleLicenseNumber,
              title: `${dictionaryPath}.thirdParty.details.vehicleLicenseNumber`
            },
            {
              ...fields.thirdParty.fields.vehicleType,
              title: `${dictionaryPath}.thirdParty.details.vehicleType`
            },
            {
              ...fields.thirdParty.fields.manufactureYear,
              title: `${dictionaryPath}.thirdParty.details.manufactureYear`
            },
            {
              ...fields.thirdParty.fields.color,
              title: `${dictionaryPath}.thirdParty.details.color`
            },
            {
              ...fields.thirdParty.fields.insuranceCompany,
              title: `${dictionaryPath}.thirdParty.details.insuranceCompany`
            },
            {
              ...fields.thirdParty.fields.policyNumber,
              title: `${dictionaryPath}.thirdParty.details.policyNumber`
            },
            {
              ...fields.thirdParty.fields.notes,
              title: `${dictionaryPath}.thirdParty.details.notes`
            }
          ]
        }
      ],
      [
        {
          fields: [ fields.thirdParty.fields.contacts ]
        }
      ]
    ]
  },
  {
    id: 'witnesses',
    notNestedForm: true,
    title: `${dictionaryPath}.witnesses.title`,
    columns: [
      [
        {
          fields: [ fields.witnesses ]
        }
      ]
    ]
  }
];

const historyTab = {
  id: 'history',
  title: `${dictionaryPath}.history.title`,
  columns: [
    [ {
      fields: [
        {
          bodyComponent: HistoryTableComponent
        }
      ]
    } ]
  ]
};

const eventAddEditConfig = {
  fields,
  tabs,
  editTabs: [ ...tabs, historyTab ],
  hiddenFields: {id: fields.id},
  title: 'events.addEditEvent.title',
  deleteLabel: 'events.addEditEvent.deleteEvent',
  categoriesToFetch: [ 'contactType', 'eventType', 'eventSeverity', 'eventResponsibility', 'eventSafetyOfficerStatus', 'eventProsecutionStatus' ]
};

export default eventAddEditConfig;
