import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { BsModalRef, TabDirective } from 'ngx-bootstrap';
import { cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { UPopupService } from '@shift/ulib';

import { AddEditModalService } from '../../services';
import { LocalizationService } from '@app/shared/services';
import { ModalActions, ModalButton } from '@app/shared/models';
import { MetadataService } from '@app/shared/services/metadata.service';
import addEditModalComponentConfig from './add-edit-modal.component.config';

@Component({
  selector: 'app-add-edit-modal',
  templateUrl: './add-edit-modal.component.html',
  styleUrls: [ './add-edit-modal.component.scss', './add-edit-modal.component.rtl.scss' ]
})
export class AddEditModalComponent implements OnInit, OnDestroy {
  @Input() public editData: any = {};
  @Input() public readonly: boolean;
  @Input() public modalConfig: any;
  @Input() public submitForm: Function;
  @Input() public formObject: FormGroup;
  @Output() public deleteEvent = new EventEmitter<any>();

  private isSubmitting: boolean;

  modalButtons: ModalButton[] = [];
  isRtl: boolean = this.localizationService.isRtl();

  constructor(
    private translate: TranslateService,
    private popupService: UPopupService,
    private modalRef: BsModalRef,
    private localizationService: LocalizationService,
    private addEditModalService: AddEditModalService,
    public metadataService: MetadataService
  ) {}

  ngOnInit(): void {
    this.setActionButtons();

    if (this.modalConfig['categoriesToFetch']) {
      this.metadataService.fetchCategories(this.modalConfig['categoriesToFetch']);
    }
    if (this.modalConfig['itemsToFetch']) {
      this.metadataService.fetchItems(this.modalConfig['itemsToFetch']);
    }
  }

  ngOnDestroy(): void {
    this.addEditModalService.isEditMode = false;
    this.addEditModalService.activeTabId = new ReplaySubject<string>(1);
  }

  private setActionButtons(): void {
    this.modalButtons = cloneDeep(this.modalConfig.buttons || addEditModalComponentConfig.defaultButtons);

    if (!this.addEditModalService.isEditMode) {
      this.modalButtons = this.modalButtons.filter(button => button.action !== ModalActions.delete);
    }

    this.modalButtons.forEach((button: ModalButton) =>
      this.translate.get(button.name).pipe(first())
        .subscribe(translatedName => button.name = translatedName));
  }

  private showCloseConfirmMessage(): void {
    this.popupService.showMessage({
      message: 'general.closeConfirm',
      yes: 'general.yes',
      no: 'general.no',
    },
      () => this.submitForm(),
      () => this.modalRef.hide()
    );
  }

  delete(): void {
    this.deleteEvent.emit();
  }

  canSubmit(): boolean {
    return this.formObject.valid && this.formObject.dirty && !this.isSubmitting && !this.modalConfig.apiNotReady;
  }

  closeForm(): void {
    this.formObject.dirty ? this.showCloseConfirmMessage() : this.modalRef.hide();
  }

  selectTab(tab: TabDirective) {
    this.addEditModalService.activeTabId.next(tab.id);
  }

  checkActions(modalAction: ModalActions) {
    switch (modalAction) {
      case ModalActions.submit: {
        this.submitForm();
        break;
      }

      case ModalActions.delete: {
        this.delete();
        break;
      }

      case ModalActions.shutDown: {
        this.closeForm();
        break;
      }
    }
  }
}
