import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { saveAs } from 'file-saver';
import { UPopupService } from '@shift/ulib';

import inputsConfig from '../../inputs.config';
import filesConfig from './file-input.component.config';
import { BaseService, FormsService } from '@app/shared/services';

@Injectable()
export class FileInputService {
  private errors: any[] = [];

  fileConfig: any;

  constructor(
    private popupService: UPopupService,
    private baseService: BaseService,
    private formsService: FormsService
  ) {}

  setFileConfig(fileType?: string): void {
    this.fileConfig = filesConfig[fileType] || filesConfig['other'];
  }

  checkFileValidation(selectedFileType: string, selectedFileSize: number): boolean {
    this.errors = [];
    if (!this.isFileTypeValid(selectedFileType, this.fileConfig.allowedFormats) || !this.isFileSizeValid(selectedFileSize, this.fileConfig.maxSizeMB)) {
      this.showErrors(this.errors);
      return false;
    }
    return true;
  }

  loadFile(fileObject: any, fileFormControl: FormGroup, fileType: 'documents' | 'images' = 'documents'): void {
    fileFormControl.patchValue({fileName: fileObject.name});
    this.baseService.uploadFile(fileObject, fileType).subscribe(fileId => fileFormControl.patchValue({fileId}));
  }

  loadMultipleFiles(files: any[], filesFormArray: FormArray, filesType?: 'documents' | 'images'): Promise<any> {
    return new Promise(mainResolve => {
      const requests = [];
      files.forEach(file =>
              requests.push(new Promise(fileResolve => {
                if (this.checkFileValidation(file['type'], file['size'])) {
                  const newFileForm = this.formsService.generateForm(inputsConfig.file.fields);
                  this.loadFile(file, newFileForm, filesType);
                  filesFormArray.push(newFileForm);
                }
                fileResolve();
              }))
            );
      Promise.all(requests).then(() => mainResolve());
    });
  }

  downloadFile(fileId: string, fileName: string, fileType?: 'documents' | 'images'): void {
    this.baseService.downloadFile(fileId, fileType).subscribe(file => saveAs(file, fileName));
  }

  isFileTypeValid(selectedFileType: string, allowedFormats: string): boolean {
    const regExp = `./${allowedFormats}`;
    if (selectedFileType.match(regExp)) {
      return true;
    } else {
      selectedFileType = selectedFileType.split('/')[1];
      if (!allowedFormats.includes(selectedFileType)) {
        this.errors.push({ message: filesConfig.invalidFormatError });
        return false;
      }
    }
    return true;
  }

  isFileSizeValid(selectedFileSize: number, maxSizeMB: number): boolean {
    if (maxSizeMB && (maxSizeMB < this.convertByteToMB(selectedFileSize))) {
      this.errors.push({ message: filesConfig.invalidSizeError });
      return false;
    }
    return true;
  }

  convertByteToMB(bytes: number): number {
    return bytes / 1024 / 1024;
  }

  showErrors(errors: any[]): void {
    this.popupService.showErrorMessage({message: errors[0]});
  }

  removeFile(fileForm: FormGroup): void {
    fileForm.get('fileId').reset();
    fileForm.get('fileName').reset();
    if (fileForm.controls['fileSrc']) {
      fileForm.get('fileSrc').reset();
    }
  }
}
