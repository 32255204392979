import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { UPopoverDirective } from '@shift/ulib';

import { LocalizationService } from '@app/shared/services';
import { InspectionService } from '@app/modules/inspections/services';

@Component({
  selector: 'app-inspection-input',
  templateUrl: './inspection-input.component.html',
  styleUrls: [ './inspection-input.component.scss', './inspection-input.component.rtl.scss' ]
})
export class InspectionInputComponent implements OnInit {
  @Input() readonly: boolean;
  @Input() formObject: FormGroup | FormArray;

  resultFormControl: FormControl;
  commentFormControl: FormControl;
  imagesForm: FormArray;
  documentsForm: FormArray;

  constructor(
    private inspectionService: InspectionService,
    private localizationService: LocalizationService
  ) {}

  ngOnInit(): void {
    this.initFormControls();
  }

  initFormControls(): void {
    this.resultFormControl = this.formObject.get('ok') as FormControl;
    this.commentFormControl = this.formObject.get('comment') as FormControl;
    this.imagesForm = this.formObject.get('images') as FormArray;
    this.documentsForm = this.formObject.get('documents') as FormArray;
  }

  getInspectionResult(): boolean {
    return this.resultFormControl && this.resultFormControl.value;
  }

  getCommentValue(): string {
    return this.commentFormControl && this.commentFormControl.value;
  }

  setInspectionResult(result): void {
    if (this.resultFormControl.value !== result) {
      this.resultFormControl.setValue(result);
      result ? this.inspectionService.addValid() : this.inspectionService.addInvalid();
    }
  }

  isRtl(): boolean {
    return this.localizationService.isRtl();
  }

  closePopover(popoverElement: UPopoverDirective): void {
    popoverElement.close();
  }
}
