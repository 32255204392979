import { ModalActions, ModalButton } from '@app/shared/models';
import { DriverAddEditComponent } from '@app/modules/drivers/components';
import { VehicleAddEditComponent } from '@app/modules/vehicles/components';
import { EventAddEditComponent } from '@app/modules/events/components';
import { SupplierAddEditComponent } from '@app/modules/suppliers/components';
import { ExpenseAddEditComponent } from '@app/modules/expenses/components';
import { InspectionAddEditComponent } from '@app/modules/inspections/components';
import { CustomerAddEditComponent } from '@app/modules/customers/components';
import { SafetyOfficerAddEditComponent } from '@app/modules/safety-officers/components';

const defaultButtons: ModalButton[] = [
  {name: 'addEditModal.delete', action: ModalActions.delete, clear: true},
  {name: 'addEditModal.saveAndClose', action: ModalActions.submit, clear: false}
];


const addEditModalComponentConfig = {
  modals: {
    drivers: DriverAddEditComponent,
    vehicles: VehicleAddEditComponent,
    events: EventAddEditComponent,
    suppliers: SupplierAddEditComponent,
    expenses: ExpenseAddEditComponent,
    inspections: InspectionAddEditComponent,
    customers: CustomerAddEditComponent,
    safetyOfficers: SafetyOfficerAddEditComponent,
  },
  defaultButtons
};

export default addEditModalComponentConfig;
