import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import appConfig from '@app/app.config';
import statusesServiceConfig from './statuses.service.config';

@Injectable()
export class StatusesService {
  constructor(private translate: TranslateService) {
  }

  getMessageByCode(code: number): string {
    return statusesServiceConfig.codes[this.getLanguage()][code];
  }

  getMessageByName(name: string): string {
    return statusesServiceConfig.messages[this.getLanguage()][name];
  }

  private getLanguage() {
    return this.translate.currentLang || appConfig.defaultLanguage.code;
  }
}
