import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

import { BaseService } from '@app/shared/services';
import { AppConstants } from '@app/shared/constants';
import historyTableConfig from './history-table.config';
import { AddEditModalService } from '@app/shared/modules/add-edit-modal/services';

@Component({
  selector: 'app-history-table',
  templateUrl: './history-table.component.html',
  styleUrls: [ './history-table.component.scss', 'history-table.component.rtl.scss' ]
})
export class HistoryTableComponent implements OnInit {
  @Input() id: number = null;

  public rows: any[] = [];
  public historyTableConfig: {} = historyTableConfig;

  constructor(
    private baseService: BaseService,
    private addEditModalService: AddEditModalService
  ) {}

  ngOnInit(): void {
    this.lazyLoadHistory();
  }

  private lazyLoadHistory(): void {
    this.addEditModalService.activeTabId.subscribe(tabId => {
      if (tabId === this.historyTableConfig['tabId']) {
        this.fetchHistory(this.id);
      }
      this.addEditModalService.activeTabId.unsubscribe();
    });
  }

  private fetchHistory(id: number): void {
    if (id) {
      this.baseService.getHistory(id).subscribe(history =>
                this.rows = this.parseDateTimeUTC(history));
    }
  }

  private parseDateTimeUTC(historyItems: any[]): any[] {
    historyItems.forEach(historyItem => {
      const dateTimeUtc = moment.utc(historyItem.dateTimeUtc).local();

      historyItem.changeTime = dateTimeUtc.format(AppConstants.TIME_FORMAT);
      historyItem.changeDate = dateTimeUtc.format(AppConstants.DATE_FORMAT_BASE_SLASH_SHORT);

      delete historyItem.dateTimeUtc;
    });

    return historyItems;
  }
}
