import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UPopupService } from '@shift/ulib';

import appConfig from '@app/app.config';
import { environment } from '@environments/environment';
import loginPageConfig from './login-page.component.config';
import { LicenseAgreementComponent } from '../../components';
import { Languages } from '@app/shared/models/languages.models';
import { LocalizationService, StatusesService, AuthService } from '@app/shared/services';

@Component({
  selector: 'app-login',
  templateUrl: './login-page.component.html',
  styleUrls: [ './login-page.component.scss', './login-page.component.rtl.scss' ]
})

export class LoginPageComponent implements OnInit {
  error: any;
  languages = Languages;
  loginForm: FormGroup;
  newPasswordForm: FormGroup;
  isSubmitting = false;
  logoText: string = appConfig.appName;

  public steps: any = loginPageConfig.steps;
  public errors: any = loginPageConfig.errors;
  currentStep = this.steps.loginForm;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    public translate: TranslateService,
    public localizationService: LocalizationService,
    private popupService: UPopupService,
    private statusesService: StatusesService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.initLoginForm();
    this.updateForm('loginForm');
    this.loginForm.valueChanges.subscribe(() => this.updateForm('loginForm'));
    this.translate.onLangChange.subscribe(() => this.updateForm('loginForm'));
  }

  private initLoginForm(): void {
    this.loginForm = this.fb.group({
      username: [ '', Validators.required ],
      password: [ '', Validators.required ],
      clientId: [ environment.clientId ]
    });
  }

  private initNewPasswordForm(): void {
    this.newPasswordForm = this.fb.group({
      username: [ this.loginForm.value.username, Validators.required ],
      password: [ '', Validators.required ],
      passwordRepeat: [ '', Validators.required ],
      code: [ '', Validators.required ],
      clientId: [ environment.clientId ]
    }, {validator: this.passwordMatchValidator});
  }

  private showNewPasswordForm(): void {
    this.initNewPasswordForm();
    this.showForm('newPasswordForm');
  }

  private showForm(formName): void {
    this.initNewPasswordForm();
    this.updateForm(formName);
    this[formName].valueChanges.subscribe(() => this.updateForm(formName));
    this.translate.onLangChange.subscribe(() => this.updateForm(formName));
    this.currentStep = this.steps[formName];
  }

  private updateForm(formName): void {
    this.onValueChanged(this[formName], this.errors[formName]);
    this.error = '';
  }

  // TODO: old code - check and improve
  private onValueChanged(form: any, formErrors: any): void {
    if (!form) {
      return;
    }

    for (const field of Object.keys(formErrors)) {
      const control: any = form.get(field);
      formErrors[field] = '';

      if (control && control.invalid && control.touched && control.errors) {
        formErrors[field] = this.statusesService.getMessageByName(Object.keys(control.errors)[0]);
      }
    }
  }

  private showError(errors): void {
    this.popupService.showErrorMessage({message: errors && errors[0] && errors[0].description});
  }

  private passwordMatchValidator(group: FormGroup): any {
    return (group && group.get('password').value !== group.get('passwordRepeat').value) ? {notMatching: true} : null;
  }

  stepBackToLogin(): void {
    this.currentStep = this.steps.loginForm;
  }


  newUserRequest(): void {
    this.isSubmitting = true;
    this.authService.newUserLogin(this.loginForm.value.username).subscribe(
      data => {
        if (data.newPasswordRequired) {
          this.showNewPasswordForm();
        }
      },
      err => this.showError(err.errors),
      () => this.isSubmitting = false);
  }

  forgotPassword(): void {
    this.isSubmitting = true;
    this.authService.forgotPassword(this.loginForm.value.username).subscribe(
    () => this.showNewPasswordForm(),
    err => this.showError(err.errors),
    () => this.isSubmitting = false);
  }

  checkPassword(credentials: any = this.loginForm.getRawValue()): void {
    this.isSubmitting = true;

    this.authService.login(credentials).subscribe(
    () => ({}),
    err => this.showError(err.errors),
    () => this.isSubmitting = false);
  }

  setPassword(): void {
    this.updateForm('newPasswordForm');
    if (this.newPasswordForm.valid) {
      this.isSubmitting = true;
      const newPasswordFormValues = this.newPasswordForm.getRawValue();
      newPasswordFormValues.code = newPasswordFormValues.code.trim();

      this.authService.changePassword({
        password: newPasswordFormValues.password,
        username: newPasswordFormValues.username,
        code: newPasswordFormValues.code
      }).subscribe(
     () => this.checkPassword(newPasswordFormValues),
     err => this.showError(err.errors),
     () => this.isSubmitting = false);
    }
  }

  showLicense() {
    this.modalService.show(
      LicenseAgreementComponent,
      {
        class: 'u-modal u-modal_content u-modal_app-login-license',
        animated: true,
        backdrop: true,
        keyboard: false,
        ignoreBackdropClick: true
      }
    );
  }
}
