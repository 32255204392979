import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';

import appConfig from '@app/app.config';
import { LanguagesActions } from '@app/store/languages/languages.actions';
import { AppTitleService } from '@app/shared/services/app-title/app-title.service';
import { AppLanguage, AppLanguageCode } from '@app/shared/models/languages.models';

@Injectable()
export class LocalizationService {
  @select([ 'languages', 'items' ])
    languages: Observable<AppLanguage[]>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService,
    private appTitleService: AppTitleService,
    private languagesActions: LanguagesActions
  ) {
    this.translate.setDefaultLang(appConfig.defaultLanguage.code);
    this.languages.subscribe(
        (languages: AppLanguage[]) => {
          if (languages.length > 0) {
            this.translate.addLangs(languages.map(lang => lang.code));
          }
        }
    );
  }

  init(): void {
    const language = this.getLanguage();

    this.setLanguage(language.match(/en|he/) ? language : appConfig.defaultLanguage.code);

    this.languagesActions.languagesInit({
      items: Object.values(appConfig.languages)
    });
  }

  setLanguage(language: AppLanguageCode): void {
    this.translate.use(language);

    if (appConfig.rtlLanguage.code === language) {
      this.document.body.classList.add('rtl');
    } else {
      this.document.body.classList.remove('rtl');
    }

    this.appTitleService.refreshPageTitle();
    this.saveLanguage(language);
  }

  getLanguage(): AppLanguageCode {
    return window.localStorage[appConfig.localStorageKeys.language] || this.translate.getBrowserLang();
  }

  saveLanguage(language: AppLanguageCode): void {
    window.localStorage[appConfig.localStorageKeys.language] = language;
  }

  isRtl(): boolean {
    return appConfig.rtlLanguage.code === this.getLanguage();
  }

  getDirection(): 'rtl' | 'ltr' {
    return this.isRtl() ? 'rtl' : 'ltr';
  }

  translateArray(arr: string[]): string[] {
    const translatedArr = [];

    arr.forEach(str => {
      this.translate.get(str || 'general.undefined')
              .subscribe(translatedStr => translatedArr.push(translatedStr));
    });

    return translatedArr;
  }
}
