import { SafetyOfficerAddEditComponent } from '../safety-officer-add-edit/safety-officer-add-edit.component';

const dictionaryPath = 'safetyOfficers.table';

const safetyOfficersPageConfig = {
  modalComponent: SafetyOfficerAddEditComponent,
  tableConfig: {
    counterLabel: `${dictionaryPath}.safetyOfficers`,
    tableName: 'safetyOfficers',
    columns: [
      {
        prop: 'check',
        name: '',
        width: 50,
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizeable: false,
        headerCheckboxable: true,
        checkboxable: true,
        custom: true,
        hideable: false,
        hideDivider: true,
        noValue: true
      },
      {
        prop: 'firstName',
        name: `${dictionaryPath}.firstName`,
        filterType: 'select',
        minWidth: 220,
        cellTemplateName: 'editRowCell',
        hideDivider: false,
        custom: true,
        clickble: true,
        highlight: false
      },
      {
        prop: 'lastName',
        name: `${dictionaryPath}.lastName`,
        filterType: 'select',
        minWidth: 220
      },
      {
        prop: 'address',
        name: `${dictionaryPath}.address`,
        filterType: 'text',
        minWidth: 220
      },
      {
        prop: 'mobile',
        name: `${dictionaryPath}.mobile`,
        filterType: 'text',
        minWidth: 120
      },
      {
        prop: 'notes',
        name: `${dictionaryPath}.notes`,
        filterType: 'text',
        minWidth: 220
      },
      {
        prop: 'status',
        translate: true,
        name: `${dictionaryPath}.status`,
        minWidth: 120,
        filterType: 'select'
      }
    ]
  }
};

export default safetyOfficersPageConfig;
