const appSideMenuConfig = {
  menu: {
    admin: [
      {
        link: 'customers',
        name: 'sidebar.menu.customers',
        icon: '/assets/images/icons/side-menu/driver.svg'
      }
    ],
    customer: [
      {
        link: 'rides',
        name: 'sidebar.menu.rides',
        icon: '/assets/images/icons/side-menu/rides.svg'
      },
      {
        link: 'RouteTemplates',
        name: 'sidebar.menu.routeTemplates',
        icon: '/assets/images/icons/side-menu/route-templates.svg'
      },
      {
        name: 'sidebar.menu.contacts.title',
        icon: '/assets/images/icons/side-menu/contacts.svg',
        children: [
          {
            link: 'customers',
            name: 'sidebar.menu.contacts.customers'
          },
          {
            link: 'suppliers',
            name: 'sidebar.menu.contacts.suppliers'
          },
          {
            link: 'accompanies',
            name: 'sidebar.menu.contacts.accompanies'
          },
          {
            link: 'safetyOfficers',
            name: 'sidebar.menu.contacts.safetyOfficers'
          },
          {
            link: 'shuttleCompanies',
            name: 'sidebar.menu.contacts.shuttleCompanies'
          }
        ]
      },
      {
        name: 'sidebar.menu.fleet.title',
        icon: '/assets/images/icons/side-menu/fleet.svg',
        children: [
          {
            link: 'drivers',
            name: 'sidebar.menu.fleet.drivers'
          },
          {
            link: 'vehicles',
            name: 'sidebar.menu.fleet.vehicles'
          },
          {
            link: 'tests',
            name: 'sidebar.menu.fleet.tests'
          },
          {
            link: 'events',
            name: 'sidebar.menu.fleet.events'
          },
          {
            link: 'alerts',
            name: 'sidebar.menu.fleet.alerts'
          }
        ]
      },
      {
        link: 'finance',
        name: 'sidebar.menu.finance',
        icon: '/assets/images/icons/side-menu/finance.svg'
      },
      {
        link: 'reports',
        name: 'sidebar.menu.reports',
        icon: '/assets/images/icons/side-menu/reports.svg'
      },
      {
        link: 'BI',
        name: 'sidebar.menu.bi',
        icon: '/assets/images/icons/side-menu/bi.svg'
      }
    ]
  },
  menuBottomItems: [
    {
      link: null,
      id: 'help-link',
      externalLink: true,
      name: 'sidebar.menu.bottom.tutorial',
      icon: '/assets/images/icons/side-menu/tutorial.svg'
    },
    {
      id: 'screen-share',
      name: 'sidebar.menu.bottom.screenShare',
      icon: '/assets/images/icons/side-menu/screen-share.svg'
    },
    {
      externalLink: true,
      link: 'http://muni.shiftlive.net/support/tickets/',
      name: 'sidebar.menu.bottom.serviceTickets',
      icon: '/assets/images/icons/side-menu/service-tickets.svg'
    },
    {
      id: 'chat-button',
      name: 'sidebar.menu.bottom.chat',
      icon: '/assets/images/icons/side-menu/chat.svg'
    }
  ],
  menuBottomStickyItem: {
    link: 'settings',
    name: 'sidebar.menu.bottom.settings',
    icon: '/assets/images/icons/side-menu/settings.svg'
  },
  links: {
    help: 'https://shiftlive.zendesk.com',
    remote: 'http://www.150.co.il/'
  },
  switchRoleModalConfig: {
    class: 'modal-md switch-role-modal',
    animated: true,
    keyboard: false,
    ignoreBackdropClick: true,
    initialState: {}
  }
};

export default appSideMenuConfig;
