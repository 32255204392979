import { EventAddEditComponent } from '../event-add-edit/event-add-edit.component';

const dictionaryPath = 'events.table';

const eventsPageConfig = {
  modalComponent: EventAddEditComponent,
  tableConfig: {
    categoriesToFetch: [ 'eventProsecutionStatus', 'eventSafetyOfficerStatus' ],
    counterLabel: `${dictionaryPath}.title`,
    tableName: 'events',
    columns: [
      {
        prop: 'check',
        name: '',
        width: 50,
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizeable: false,
        headerCheckboxable: true,
        checkboxable: true,
        custom: true,
        hideable: false,
        hideDivider: true,
        noValue: true
      },
      {
        prop: 'date',
        name: `${dictionaryPath}.date`,
        filterType: 'date',
        cellTemplateName: 'editRowCell',
        dateFormat: 'dd/MM/yy',
        hideDivider: false,
        custom: true,
        clickble: true,
        highlight: false,
        minWidth: 160,
        maxWidth: 250
      },
      {
        prop: 'vehicleLicenseNumber',
        name: `${dictionaryPath}.licenseNumber`,
        filterType: 'select',
        minWidth: 180,
        maxWidth: 250
      },
      {
        prop: 'vehicleType',
        name: `${dictionaryPath}.vehicleType`,
        translate: true,
        filterType: 'select',
        minWidth: 120,
        maxWidth: 250
      },
      {
        prop: 'manufacturer',
        name: `${dictionaryPath}.manufacturer`,
        filterType: 'select',
        minWidth: 150
      },
      {
        prop: 'driverName',
        name: `${dictionaryPath}.driver`,
        filterType: 'select',
        minWidth: 120,
        maxWidth: 250
      },
      {
        prop: 'address',
        name:  `${dictionaryPath}.location`,
        filterType: 'select',
        selectItems: [],
        minWidth: 150,
        maxWidth: 250
      },
      {
        prop: 'severity',
        name: `${dictionaryPath}.severity`,
        filterType: 'select',
        translate: true,
        minWidth: 120,
        maxWidth: 250
      },
      {
        prop: 'insurancePolicyNumber',
        name: `${dictionaryPath}.insurance`,
        filterType: 'text',
        minWidth: 120,
        maxWidth: 250
      },
      {
        prop: 'prosecutionStatus',
        name: `${dictionaryPath}.prosecutionStatus`,
        cellTemplateName: 'selectCell',
        inputOptionsVar: 'eventProsecutionStatus',
        filterType: 'select',
        formControl: true,
        translate: true,
        minWidth: 190
      },
      {
        prop: 'safetyOfficerStatus',
        name: `${dictionaryPath}.safetyOfficerStatus`,
        cellTemplateName: 'selectCell',
        inputOptionsVar: 'eventSafetyOfficerStatus',
        filterType: 'select',
        formControl: true,
        translate: true,
        minWidth: 190
      }
    ]
  }
};

export default eventsPageConfig;
