import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { select } from '@angular-redux/store';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

import addBtnConfig from './add-btn.component.config';
import { CommonService } from '@app/shared/services';
import { TablePageService } from '@app/shared/components/table-page/table-page.service';

@Component({
  selector: 'app-add-btn',
  templateUrl: './add-btn.component.html',
  styleUrls: [ './add-btn.component.scss', './add-btn.component.rtl.scss' ]
})
export class AddBtnComponent implements OnInit {
  @select([ 'auth', 'user', 'role' ])
    public readonly userRole: Observable<any>;

  private currentUrl: string;

  public userRoleName: any;
  public addBtnCurrentConfig: {};

  constructor(
    private router: Router,
    private tablePageService: TablePageService,
    public commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.setUserRole();
    this.onRouteChange();
    this.setCurrentRoute();
  }

  setUserRole(): void {
    this.userRole.subscribe(role => this.userRoleName = role);
  }

  onRouteChange(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
          .subscribe(() => this.setCurrentRoute());
  }

  setCurrentRoute(): void {
    this.currentUrl = this.router.url.substr(1);
    this.setCurrentRouteAddConfig(this.currentUrl);
  }

  openAddEditModal(addEditModal = this.addBtnCurrentConfig['addEditModal'], popoverItemKey?: string): void {
    if (addEditModal) {
      this.tablePageService.openAddEditModal(addEditModal, undefined, popoverItemKey ? popoverItemKey === this.currentUrl : true);
    }
  }

  setCurrentRouteAddConfig(route: string): void {
    this.addBtnCurrentConfig = addBtnConfig[route] || {};
  }
}
