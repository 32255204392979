import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import appConfig from '@app/app.config';
import { TokenService } from '@app/shared/services/auth/token.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private tokenService: TokenService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!!!this.tokenService.getToken()) {
      this.router.navigate([ appConfig.defaultRoutes.unauthorized ]);
    }

    return true;
  }
}
