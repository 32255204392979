const dictionaryPath = 'vehicles.addEditVehicle.generalDetails.suppliers';

const vehicleAddEditSupplierConfig = {
  title: `${dictionaryPath}.title`,
  tableColumns: [
    {
      prop: 'supplierName',
      name: `${dictionaryPath}.supplierName`,
      cellTemplateName: 'supplierNameCell'
    },
    {
      prop: 'supplierType',
      name: `${dictionaryPath}.supplierType`,
      cellTemplateName: 'supplierTypeCell'
    },
    {
      prop: 'delete',
      name: '',
      sortable: false,
      canAutoResize: false,
      draggable: false,
      resizeable: false,
      hideable: false,
      hideDivider: true,
      noValue: true,
      cellTemplateName: 'supplierDeleteCell'
    }
  ]
};

export default vehicleAddEditSupplierConfig;
