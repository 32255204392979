import { AppConstants } from '@app/shared/constants';

const headerConfig = {
  alertsUrl: '/alerts',
  timerInterval: 1000, // in ms
  searchDebounce: 300, // in ms
  searchMinCharacters: 2,
  headerDateFormat: AppConstants.DATE_FORMAT_BASE_SLASH_SHORT
};

export default headerConfig;
