import { Component, OnInit } from '@angular/core';
import { select } from '@angular-redux/store';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { filter, map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import appConfig from '@app/app.config';
import { AppConstants } from '@app/shared/constants';
import headerConfig from './header.component.config';
import { GlobalSearchService, LocalizationService, HeaderDataService, CommonService } from '@app/shared/services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss', './header.component.rtl.scss' ]
})
export class HeaderComponent implements OnInit {
  @select([ 'auth', 'user', 'role' ])
  public readonly userRole: Observable<any>;

  private searchSubject: Subject<string> = new Subject();

  isRtl: boolean;
  showFeed: boolean;
  currentDate: string;
  searchQuery: string;
  isAdminUser: boolean;
  appConfig = appConfig;
  appConstants = AppConstants;
  showTodayButton$: Observable<boolean>;
  showThisWeekButton$: Observable<boolean>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private globalSearchService: GlobalSearchService,
    private commonService: CommonService,
    private localizationService: LocalizationService,
    public headerDataService: HeaderDataService
  ) {}

  ngOnInit(): void {
    this.isRtl = this.localizationService.isRtl();

    this.onRouteChange();
    this.setUserRole();
    this.setHeaderDateButtons();
    this.subscribeForSearchQuery();
    this.toggleItemsVisibility();
  }

  private setHeaderDateButtons() {
    this.showTodayButton$ = this.headerDataService.date$
      .pipe(
        map(date => {
          const today = moment().startOf('day');
          const activeDay = moment(date).startOf('day');

          return !today.isSame(activeDay);
        })
      );

    this.showThisWeekButton$ = this.headerDataService.weekSwitchChange
     .pipe(
        map(data => {
          const today = moment().startOf('day');
          const { startDate, endDate } = data;

          return !(today.isSameOrAfter(startDate) && today.isSameOrBefore(endDate));
        })
     );
  }

  private onRouteChange(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.toggleItemsVisibility());
  }

  private toggleItemsVisibility(): void {
    let child = this.activatedRoute;
    while (child.firstChild) {
      child = child.firstChild;
    }
    this.searchQuery = '';
    this.globalSearchService.searchQuery.next(this.searchQuery);
    this.showFeed = child.snapshot.data.showFeed !== false;
  }

  private setUserRole(): void {
    this.userRole.subscribe(userRole => this.isAdminUser = userRole && userRole.toLowerCase() === appConfig.userRoles.admin.toLowerCase());
  }

  private subscribeForSearchQuery(): void {
    this.searchSubject.debounceTime(headerConfig.searchDebounce).subscribe((searchQuery: string) =>
      this.globalSearchService.updateSearch(searchQuery && searchQuery.length >= headerConfig.searchMinCharacters ? searchQuery : ''));
  }

  onSearchKeyUp(): void {
    this.searchSubject.next(this.searchQuery);
  }

  navigateToAlerts(): void {
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl(headerConfig.alertsUrl);
  }

  backToToday() {
    const today = moment().startOf('day').format();

    this.headerDataService.dateSet(today);
    this.commonService.setHeaderDate(moment(today));
  }

  backToThisWeek() {
    this.headerDataService.updateDateWeekSwitch(moment());
  }
}
