import apiServiceConfig from '@app/shared/services/api/api.service.config';
import { InspectionAddEditComponent } from '@app/modules/inspections/components';

const dictionaryPath = 'vehicles.addEditVehicle.generalDetails.inspections.table';

const inspectionsPageConfig = {
  tableConfig: {
    metadataSource: 'inspections',
    tableName: `${dictionaryPath}.title`,
    columns: [
      {
        prop: 'number',
        name: `${dictionaryPath}.inspectionNumber`,
        filterType: 'text',
        minWidth: 120,
        maxWidth: 250
      },
      {
        prop: 'date',
        name: `${dictionaryPath}.inspectionDate`,
        filterType: 'date',
        dateFormat: 'dd/MM/yy',
        minWidth: 120,
        maxWidth: 250
      },
      {
        prop: 'driverName',
        name: `${dictionaryPath}.driverName`,
        filterType: 'text',
        minWidth: 120,
        maxWidth: 250
      },
      {
        prop: 'currentMileage',
        name: `${dictionaryPath}.currentMileage`,
        filterType: 'text',
        minWidth: 120,
        maxWidth: 250
      },
      {
        prop: 'failCount',
        name: `${dictionaryPath}.failCount`,
        filterType: 'text',
        minWidth: 120,
        maxWidth: 250
      },
      {
        prop: 'status',
        translate: true,
        name: `${dictionaryPath}.status`,
        filterType: 'text',
        minWidth: 120,
        maxWidth: 250
      },
      {
        prop: 'safetyOfficerName',
        name: `${dictionaryPath}.safetyOfficer`,
        filterType: 'text',
        minWidth: 120,
        maxWidth: 250
      },
      {
        prop: 'notes',
        name: `${dictionaryPath}.notes`,
        filterType: 'text',
        minWidth: 120
      },
      {
        prop: 'openRowModal',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizeable: false,
        custom: true,
        hideDivider: true,
        noValue: true,
        minWidth: 80,
        cellTemplateName: 'openRowModalCell',
        basePath: apiServiceConfig.paths.inspection.basePath,
        openRowModalComponent: InspectionAddEditComponent
      }
    ]
  }
};

export default inspectionsPageConfig;
