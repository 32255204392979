import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import ctrlInputConfig from '@app/modules/inputs/ctrl-inputs.config';

@Injectable()
export class CtrlFormsService {

  constructor() {}

  generateModalTabsForm(modalConfig: {}, data?: any): FormGroup {
    const modalForm = new FormGroup({});
    const fieldsForm = this.generateFieldsForm(modalConfig['fields'], data);

    this.addHiddenFields(modalConfig['hiddenFields'], modalForm);
    modalConfig['tabs'].forEach(tab => this.addTabFields(modalForm, fieldsForm, tab));

    return modalForm;
  }

  generateSettingsTabsForm(settingSections: {}): FormGroup {
    const settingsForm = new FormGroup({});
    Object.keys(settingSections).forEach(sectionKey => {
      const sectionForm = new FormGroup({});
      Object.keys(settingSections[sectionKey].items).forEach(itemKey => {
        const itemTabConfig = settingSections[sectionKey].items[itemKey].tabConfig;
        if (itemTabConfig.fields) {
          const itemsForm = this.generateFieldsForm(itemTabConfig.fields);
          if (itemTabConfig.notNestedForm) {
            Object.keys(itemsForm.controls).forEach(controlKey => {
              sectionForm.addControl(controlKey, itemsForm.controls[controlKey]);
            });
          } else {
            sectionForm.addControl(itemKey, itemsForm);
          }
        }
      });
      if (settingSections[sectionKey].notNestedForm) {
        Object.keys(sectionForm.controls).forEach(controlKey => {
          settingsForm.addControl(controlKey, sectionForm.controls[controlKey]);
        });
      } else {
        settingsForm.addControl(sectionKey, sectionForm);
      }
    });

    return settingsForm;
  }

  generateFieldsForm(fields: {}, data?): FormGroup {
    const fieldsForm = new FormGroup({});

    Object.keys(fields).forEach(fieldKey =>
          fieldsForm.addControl(fields[fieldKey].name, this.generateFieldForm(fields[fieldKey], data ? data[fieldKey] : undefined)));

    return fieldsForm;
  }

  generateFieldForm(field: any, fieldData?): FormControl | FormArray | FormGroup {
    let fieldForm;

    if (field.fields) {
      fieldForm = this.generateFieldsForm(field.fields, fieldData);
    } else {
      const inputConfig = field.inputConfig || ctrlInputConfig[field.inputType];

      if (field.arrayField || (inputConfig && inputConfig.arrayField)) {
        fieldForm = new FormArray(field.default || [], field.required ? [ Validators.required ] : []);
        if (fieldData) {
          fieldData.forEach(itemData => {
            const newForm = this.generateFieldsForm(inputConfig.fields, itemData);
            fieldForm.push(newForm);
          });
        }
      } else if (inputConfig && inputConfig.fields) {
        fieldForm = fieldData || inputConfig.generateFormControls !== false ? this.generateFieldsForm(inputConfig.fields, fieldData) : new FormControl(null, field.required ? [ Validators.required ] : []);
      } else {
        fieldForm = new FormControl(field.defaultValue, field.required ? [ Validators.required ] : []);
      }
    }

    return fieldForm;
  }

  private addTabFields(modalForm: FormGroup, fieldsForm: FormGroup, tab: any): void {
    if (!tab.notNestedForm && fieldsForm.get(tab.id)) {
      modalForm.addControl(tab.id, fieldsForm.get(tab.id));
    } else if (tab.columns) {
      tab.columns.forEach(col => {
        for (const section of col) {
          section.fields.forEach(field => fieldsForm.controls[field.name] ? modalForm.addControl(field.name, fieldsForm.controls[field.name]) : '');
        }
      });
    }
  }

  private addHiddenFields(hiddenFields: any, modalForm: FormGroup): void {
    if (hiddenFields) {
      const hiddenFieldsForm = this.generateFieldsForm(hiddenFields);
      Object.keys(hiddenFieldsForm.controls).forEach(controlKey => modalForm.addControl(controlKey, hiddenFieldsForm.controls[controlKey]));
    }
  }

  patchValueNested(form: FormGroup, data: any, fields: any, clearExisting?: boolean): void {
    Object.keys(data).forEach(dataKey => {
      const fieldData = data[dataKey];
      if (fieldData && fields[dataKey]) {
        const inputConfig = fields[dataKey].inputConfig || ctrlInputConfig[fields[dataKey].inputType];
        const fieldForm = form.get(dataKey);
        if (Array.isArray(fieldForm.value) && (fieldForm as FormArray).controls) {
          if (clearExisting) {
            this.clearFormArray(form.get(dataKey) as FormArray);
          }
          fieldData.forEach(dataItem => (form.get(dataKey) as FormArray).push(inputConfig && inputConfig.fields ? this.generateFieldsForm(inputConfig.fields, dataItem) : new FormControl(dataItem)));
        } else if (inputConfig && inputConfig.fields) {
          form.setControl(dataKey, this.generateFieldsForm(inputConfig.fields, fieldData));
        }
      }
    });

    form.patchValue(data);
  }

  replaceFormFields(form: FormGroup, newFields: any, prevFields: any): FormGroup {
    const prevFieldNames = Object.keys(prevFields).map(fieldKey => prevFields[fieldKey].name);
    const newFieldNames = Object.keys(newFields).map(fieldKey => newFields[fieldKey].name);
    const removedControlKeys = prevFieldNames.filter(key => !newFieldNames.includes(key));
    const newControlForm = this.generateFieldsForm(newFields);

    removedControlKeys.forEach(key => form.removeControl(key));

    Object.keys(newControlForm.controls).forEach(controlKey => {
      if (!form.get(controlKey)) {
        form.addControl(controlKey, newControlForm.controls[controlKey]);
      }
    });

    return form;
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
    formArray.markAsDirty();
  }
}
