import { Component, OnInit } from '@angular/core';

import expensesPageConfig from './expenses-page.component.config';
import { BaseService, MockUpService } from '@app/shared/services';

@Component({
  selector: 'app-expenses-page',
  templateUrl: './expenses-page.component.html',
  styleUrls: [ './expenses-page.component.scss', 'expenses-page.component.rtl.scss' ]
})
export class ExpensesPageComponent implements OnInit {
  rows: any[] = [];
  expensesPageConfig = expensesPageConfig;

  private NUM_ROWS: number = 20;

  constructor(private baseService: BaseService, private mockUpService: MockUpService) {
  }

  ngOnInit() {
    this.baseService.setPaths('expenses');
    this.setUpMockRows();
  }

  setUpMockRows(): void {
    this.rows = this.mockUpService.generateMockRows(this.NUM_ROWS, expensesPageConfig.tableConfig.columns
      .map(({prop, mockUpOptions}) => ({prop, mockUpOptions})));
  }

  fetchExpenses(): void {
    this.baseService.getAll().subscribe(expenses => this.rows = expenses);
  }
}
