import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, BehaviorSubject, fromEvent, merge, Subject } from 'rxjs';

import appConfig from '@app/app.config';
import { TokenService } from './token.service';
import { fromEventCustom } from '@app/shared/utils';
import { environment } from '@environments/environment';
import { AuthActions } from '@app/store/auth/auth.actions';
import { ApiService } from '@app/shared/services/api/api.service';
import apiServiceConfig from '@app/shared/services/api/api.service.config';
import { CobrowseIoService } from '@app/shared/services/cobrowse-io.service';

class User {
  user = {};
  token = '';
}

@Injectable()
export class AuthService {
  private sessionTimer: any;
  private paths: any = apiServiceConfig.paths.auth;
  private sessionTimerSub: Subject<void> = new Subject();
  private currentUserSubject = new BehaviorSubject<User>(new User());
  private isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  isAuthenticated$: Observable<boolean> = this.isAuthenticated.asObservable();

  constructor(
    private apiService: ApiService,
    private tokenService: TokenService,
    private router: Router,
    private authActions: AuthActions,
    private bsModalService: BsModalService,
    private cobrowseIoService: CobrowseIoService
  ) {}

  private initSessionTimer() {
    clearTimeout(this.sessionTimer);
    this.sessionTimer = setTimeout(() => this.logout(), appConfig.logoutTimeMinutes * 60 * 1000);
  }

  init() {
    if (this.tokenService.getToken()) {
      this.apiService.get(this.paths.basePath).subscribe(
        user => this.setAuth(user),
        err => this.purgeAuth()
      );
    } else {
      this.purgeAuth();
    }
  }

  setAuth(user: any) {
    const token = this.tokenService.getToken();
    user.role = user.role.substring(user.role.indexOf('.') + 1);

    this.sessionTimerSub = new Subject();
    this.startSessionTimer();
    this.authActions.authUser({user, token});
    this.currentUserSubject.next(user);
    this.isAuthenticated.next(true);
    this.cobrowseIoService.initializeService(user);
  }

  startSessionTimer(): void {
    const events = [
      fromEvent(window, 'DOMContentLoaded'),
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'click'),
      fromEventCustom(window, 'scroll', true)
    ];

    this.initSessionTimer();

    merge(...events)
      .pipe(takeUntil(this.sessionTimerSub))
      .subscribe(() => this.initSessionTimer());
  }

  purgeAuth() {
    this.sessionTimerSub.next();
    this.sessionTimerSub.complete();
    this.currentUserSubject.next(new User());
    this.isAuthenticated.next(false);
  }

  login(credentials: object): Observable<any> {
    return this.apiService.postUrlEncoded(this.paths.basePath + this.paths.login, credentials)
      .pipe(
        map(res => {
          this.tokenService.saveToken(res.accessToken);
          this.apiService.get(this.paths.basePath)
            .subscribe(
              user => {
                this.setAuth(user);
                this.router.navigate([ appConfig.defaultRoutes.homePage[user.role.toLowerCase()] || appConfig.defaultRoutes.default ]);
              },
              err => this.purgeAuth()
            );

          return res;
        })
      );
  }

  newUserLogin(username: string) {
    return this.apiService.postUrlEncoded(this.paths.basePath + this.paths.newUserLogin, {username});
  }

  verifyCode(params: any): Observable<any> {
    return this.apiService.postUrlEncoded(this.paths.basePath + this.paths.verifyCode, params);
  }

  forgotPassword(username: string): Observable<any> {
    return this.apiService.postUrlEncoded(this.paths.basePath + this.paths.forgotPassword, {username});
  }

  changePassword(params: any): Observable<any> {
    return this.apiService.postUrlEncoded(this.paths.basePath + this.paths.changePassword, params);
  }

  update(user: string): Observable<User> {
    return this.apiService
      .put(this.paths.update, {user})
      .pipe(map(data => {
        this.currentUserSubject.next(data.user);

        return data.user;
      }));
  }

  closeAllModals() {
    for (let i = 1; i <= this.bsModalService.getModalsCount(); i++) {
      this.bsModalService.hide(i);
    }
  }

  logout() {
    this.purgeAuth();
    this.closeAllModals();
    this.tokenService.destroyToken();
    this.router.navigate([ appConfig.defaultRoutes.unauthorized ]);
  }

  switchRoleInfo() {
    return this.apiService.get(this.paths.basePath + this.paths.switchRoleInfo);
  }

  switchRole(customerId, role) {
    return this.apiService.postUrlEncoded(this.paths.basePath + this.paths.switchRole,
      {
        CustomerId: customerId,
        Role: role,
        ClientId: environment.clientId
      }).pipe(map(tokenInfo =>
      this.tokenService.saveToken(tokenInfo.accessToken)
    ));
  }
}
