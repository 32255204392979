import { ExpenseAddEditComponent } from '../expense-add-edit/expense-add-edit.component';

const dictionaryPath = 'expenses.table';

const expensesPageConfig = {
  modalComponent: ExpenseAddEditComponent,
  tableConfig: {
    disableEditing: true,
    counterLabel: `${dictionaryPath}.expenses`,
    tableName: 'expenses',
    columns: [
      {
        prop: 'check',
        name: '',
        width: 50,
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizeable: false,
        headerCheckboxable: true,
        checkboxable: true,
        custom: true,
        hideable: false,
        hideDivider: true,
        noValue: true
      },
      {
        prop: 'licenseNumber',
        name: `${dictionaryPath}.licenseNumber`,
        mockUpOptions: {
          type: 'licenseNumber'
        },
        cellTemplateName: 'editRowCell',
        hideDivider: false,
        custom: true,
        clickble: true,
        highlight: false,
        filterType: 'text',
        minWidth: 160
      },
      {
        prop: 'type',
        name: `${dictionaryPath}.type`,
        filterType: 'text',
        minWidth: 160,
        mockUpOptions: {
          type: 'employmentType'
        }
      },
      {
        prop: 'orderId',
        name: `${dictionaryPath}.orderId`,
        filterType: 'text',
        minWidth: 120,
        mockUpOptions: {
          type: 'id',
          maxDigits: 5
        }
      },
      {
        prop: 'orderDate',
        name: `${dictionaryPath}.orderDate`,
        filterType: 'text',
        minWidth: 120,
        mockUpOptions: {
          type: 'date'
        }
      },
      {
        prop: 'manufacturer',
        name: `${dictionaryPath}.manufacturer`,
        mockUpOptions: {
          type: 'manufacturer'
        },
        filterType: 'select',
        minWidth: 150
      },
      {
        prop: 'costInclVAT',
        name: `${dictionaryPath}.costInclVAT`,
        mockUpOptions: {
          type: 'price',
          maxDigits: 5
        },
        filterType: 'text',
        minWidth: 120
      },
      {
        prop: 'invoiceId',
        name: `${dictionaryPath}.invoiceId`,
        mockUpOptions: {
          type: 'id',
          maxDigits: 3
        },
        filterType: 'text',
        minWidth: 120
      },
      {
        prop: 'internalNotes',
        name: `${dictionaryPath}.internalNotes`,
        mockUpOptions: {
          type: 'words',
          length: 3
        },
        filterType: 'text',
        minWidth: 120,
        maxWidth: 250
      },
      {
        prop: 'status',
        name: `${dictionaryPath}.status`,
        minWidth: 150,
        filterType: 'text',
        popoverConfig: {
          title: `${dictionaryPath}.statusPopover.title`,
          fields: [ {
            name: 'status',
            inputType: 'select',
            linkedField: 'status',
            title: `${dictionaryPath}.statusPopover.status`,
            inputOptionsArr: [
              {
                name: 'Sent',
                value: 'Sent'
              },
              {
                name: 'Cancelled',
                value: 'Cancelled'
              },
              {
                name: 'Approved',
                value: 'Approved'
              },
              {
                name: 'Opened',
                value: 'Opened'
              }
            ]
          }
          ]
        },
        cellTemplateName: 'popoverCell',
        mockUpOptions: {
          type: 'expenseStatus'
        }
      }
    ]
  }
};

export default  expensesPageConfig;
