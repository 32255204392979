import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TabsModule } from 'ngx-bootstrap';
import { UCommonModule } from '@shift/ulib';

import { LoginPageComponent, LicenseAgreementComponent, CustomerRoleSwitchComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    UCommonModule,
    TabsModule
  ],
  declarations: [
    LoginPageComponent,
    CustomerRoleSwitchComponent,
    LicenseAgreementComponent
  ],
  entryComponents: [
    LicenseAgreementComponent,
    CustomerRoleSwitchComponent
  ],
  exports: [
    LoginPageComponent
  ]
})

export class AuthModule {
}
