import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'errorsTranslate'
})

export class ErrorsTranslatePipe implements PipeTransform {
  errors: object;

  constructor(public translate: TranslateService) {
    this.translate.get('errors').subscribe((err: any) => {
      this.errors = err;
    });
  }

  transform(items: any) {
    return items ? Object.keys(items).map((el: any) => this.errors[el])[0] : items;
  }
}
