import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';

@Injectable()
export class CommonService {
  previousUrl: string = '';
  headerDate: Subject<any> = new Subject();
  headerDateActive: moment.Moment = moment();

  constructor() {}

  // Workaround for angular bug of key-value pipe wrong order
  asIsOrder(): number {
    return 1;
  }

  setHeaderDate(date: moment.Moment, needEvent = true) {
    this.headerDateActive = date;

    if (needEvent) {
      this.headerDate.next(date);
    }
  }
}
