import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { UPopupService } from '@shift/ulib';

import { FormsService } from '@app/shared/services';
import inputsConfig from '../../inputs.config';
import contactGroupsInputConfig from './contact-groups-input.component.config';

@Component({
  selector: 'app-contact-groups-input',
  templateUrl: './contact-groups-input.component.html',
  styleUrls: [ './contact-groups-input.component.scss', './contact-groups-input.component.rtl.scss' ]
})
export class ContactGroupsInputComponent implements OnInit {
  @Input() config: any = {};
  @Input() public formObject: FormGroup | FormArray;

  editData: any;
  public contactGroupsInputConfig: any = contactGroupsInputConfig;

  constructor(
    private popupService: UPopupService,
    private formsService: FormsService
  ) {}

  ngOnInit(): void {
    this.contactGroupsInputConfig = (this.config && this.config.contactGroupsInputConfig) || contactGroupsInputConfig;
    this.initContactsForm();
  }

  initContactsForm(): void {
    if (this.contactRequired() && !this.contactGroupsData) {
      this.addContact();
    }
  }

  contactRequired(): boolean {
    return this.contactsForm.validator && this.contactsForm.validator({} as AbstractControl).required;
  }

  get contactsForm(): FormArray {
    return (this.formObject.get('contactGroups') || this.formObject) as FormArray;
  }

  get contactGroupsData(): Array<any> {
    return this.editData ? this.editData.contactGroups : null;
  }

  getContacts(index): FormArray {
    return this.contactsForm.at(index).get('contacts') as FormArray;
  }

  addContact(): void {
    const contactForm = this.formsService.generateForm(this.contactGroupsInputConfig.formControls || inputsConfig.contactGroups.fields);
    this.contactsForm.push(contactForm);
  }

  removeContact(index: number): void {
    this.popupService.showMessage({
      message: 'contactDetails.deleteContactConfirm',
      yes: 'general.yes',
      no: 'general.no',
    },
            () => {
              this.contactsForm.removeAt(index);
              this.formObject.markAsDirty();
            });
  }
}
