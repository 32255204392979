<div class="footer">
  <div class="footer__language">
    <span class="footer__language-item" [ngClass]="{'footer__language-item_active': selectedLanguageCode === languages.English}" (click)="setLanguage(languages.English)">EN</span>
    <span class="footer__language-separator">|</span>
    <span class="footer__language-item" [ngClass]="{'footer__language-item_active': selectedLanguageCode === languages.Hebrew}" (click)="setLanguage(languages.Hebrew)">עב</span>
  </div>
  <div class="footer__copyrights">
    <span class="footer__copyrights-symbol">©</span>
    <span class="footer__copyrights-image"></span>
    <span class="footer__copyrights-ltd">LTD.</span>
  </div>
</div>
