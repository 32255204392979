import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { UPopupService } from '@shift/ulib';

import appConfig from '@app/app.config';
import apiServiceConfig from './api.service.config';
import { TokenService } from '@app/shared/services/auth/token.service';

@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private tokenService: TokenService,
    private bsModalService: BsModalService,
    private popupService: UPopupService,
    private translate: TranslateService
  ) {}

  private setHeaders(type: string = 'plain'): HttpHeaders {
    return this.getHttpHeaders(apiServiceConfig.headers[type]);
  }

  private getHttpHeaders(headersConfig) {
    headersConfig['language'] = this.translate.currentLang;

    if (this.tokenService.getToken()) {
      headersConfig['Authorization'] = `Bearer ${this.tokenService.getToken()}`;
    }

    return new HttpHeaders(headersConfig);
  }

  private toHttpParams(params: any): HttpParams {
    return Object.getOwnPropertyNames(params)
            .reduce((p, key) => p.set(key, params[key]), new HttpParams());
  }

  private extractData(res: any) {
    try {
      return JSON.parse(res);
    } catch (e) {
      return {};
    }
  }

  closeAllModals() {
    for (let i = 1; i <= this.bsModalService.getModalsCount(); i++) {
      this.bsModalService.hide(i);
    }
  }

  private formatErrors = (res: any) => {
    if (res.status === 401) {
      this.closeAllModals();
      this.tokenService.destroyToken();
      this.router.navigate([ appConfig.defaultRoutes.unauthorized ]);
      return throwError({ code: res.status });
    }

    if (res.status < 200 || res.status >= 300) {
      try {
        const error = JSON.parse(res.error) || { code: res.status };
        if (!error.errors) {
          this.showGeneralErrorMessage();
        }
        return throwError(error);
      } catch (e) {
        return throwError({ code: res.status });
      }
    }
  };

  showGeneralErrorMessage(): void {
    this.popupService.showErrorMessage({ message: 'general.generalErrorMessage' });
  }

  get(path: string, params: Object = {}): Observable<any> {
    return this.http.get(
            `${appConfig.apiUrl}${path}`,
            { headers: this.setHeaders(), params: this.toHttpParams(params), responseType: 'text' })
            .pipe(
                map(this.extractData),
                catchError(this.formatErrors)
            );
  }

  getBlob(path: string, params: Object = {}): Observable<any> {
    return this.http.get(
          `${appConfig.apiUrl}${path}`,
          {headers: this.setHeaders('blob'), params: this.toHttpParams(params), responseType: 'blob'})
          .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
            `${appConfig.apiUrl}${path}`,
            JSON.stringify(body),
            { headers: this.setHeaders(), responseType: 'text' })
            .pipe(
                map(this.extractData),
                catchError(this.formatErrors)
            );
  }

  post(path: string, body: Object = {}, responseType: any = 'text'): Observable<any> {
    return this.http.post(
            `${appConfig.apiUrl}${path}`,
            JSON.stringify(body),
            { headers: this.setHeaders(), responseType })
            .pipe(
                map(this.extractData),
                catchError(this.formatErrors)
            );
  }

  postBlob(path: string, body: Object = {}, responseType: any = 'text'): Observable<any> {
    return this.http.post(
            `${appConfig.apiUrl}${path}`,
            JSON.stringify(body),
            { headers: this.setHeaders(), responseType }
        );
  }

  delete(path: string, params: Object = {}): Observable<any> {
    return this.http.delete(
            `${appConfig.apiUrl}${path}`,
            { headers: this.setHeaders(), params: this.toHttpParams(params), responseType: 'text' })
            .pipe(
                map(this.extractData),
                catchError(this.formatErrors)
            );
  }

  private setHeadersUrlEncoded(): HttpHeaders {
    return this.getHttpHeaders(apiServiceConfig.headers.urlEncoded);
  }

  private urlEncoded(obj: any) {
    const str = [];

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        str.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
      }
    }

    return str.join('&');
  }

  private formatErrorsEncoded(res: any) {
    const error = JSON.parse(res.error) || { code: res.status };

    return throwError(error);
  }

  postUrlEncoded(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
            `${appConfig.apiUrl}${path}`,
            this.urlEncoded(body),
            { headers: this.setHeadersUrlEncoded(), responseType: 'text' })
            .pipe(
                map(this.extractData),
                catchError(this.formatErrorsEncoded)
            );
  }

  postFormData(path: string, form: FormData): Observable<any> {
    return this.http.post(
          `${appConfig.apiUrl}${path}`, form,
          {headers: this.getHttpHeaders(apiServiceConfig.headers.formData), responseType: 'text'})
          .pipe(
            map(this.extractData),
            catchError(this.formatErrors)
          );
  }

  patch(path: string, body: Object): Observable<any> {
    return this.http.patch(
          `${appConfig.apiUrl}${path}`,
          JSON.stringify(body),
          { headers: this.setHeaders() })
          .pipe(
            map(this.extractData),
            catchError(this.formatErrors)
          );
  }
}
