<div class="add-edit-users">
  <div class="add-edit-users__block add-edit-users__block_users {{usersForm.length > 0 ? 'division-border': ''}}">
    <div class="add-edit-users-header">
      <div class="u-text-line u-text-line_border-off ctrl-section-title">
        {{(config && config.dictionary && config.dictionary['title']) || usersInputConfig.dictionary['usersListTitle'] | translate}}
        ({{usersForm.length}})
      </div>
      <div class="add-edit-users__actions">
        <button class="u-btn-simple {{!canAddNewUser()?'u-btn-disabled':''}}" (click)="addNewUser()"
                [disabled]="!canAddNewUser()">
          {{(config && config.dictionary && config.dictionary['addNew']) || usersInputConfig.dictionary['addNewUser'] | translate}}</button>
      </div>
    </div>
    <div class="add-edit-users-items">
      <div class="add-edit-users-items__item" *ngFor="let userForm of usersForm.controls; let i=index;"
           [ngClass]="{'add-edit-users-items__item_active': i === selectedUserIndex,
                             'add-edit-users-items__item_invalid': userForm.invalid}"
           (click)="selectUserIndex(i, true)">
        <u-group [name]="(config && config.dictionary && config.dictionary['name'])|| usersInputConfig.dictionary['userName'] | translate">
          <div class="add-edit-users-items__name">{{userForm.value.firstName}}
            {{userForm.value.lastName}}</div>
          <div class="add-edit-users-items__actions">
            <button class="add-edit-users-items__actions-delete u-btn-simple u-btn-small icon-btn" (click)="deleteUser(i)" *ngIf="userForm.value.deletable !== false">
              <span class="icon delete-user-icon"></span>
            </button>
          </div>
        </u-group>
      </div>
    </div>
  </div>

  <div class="add-edit-users__block add-edit-users__block_details">
    <div class="u-text-line u-text-line_border-off ctrl-section-title" *ngIf="selectedUser">
      {{(config && config.dictionary && config.dictionary['userDetailsTitle']) || usersInputConfig.dictionary['userDetailsTitle'] | translate}}</div>
    <div class="add-edit-users__details" *ngIf="selectedUser">
      <app-dynamic-field *ngFor="let field of userDetailsConfig['fields'];" [formObject]="selectedUser"
                     [metadata]="metadata" [field]="field"></app-dynamic-field>
    </div>
  </div>
</div>
