import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgReduxModule } from '@angular-redux/store';
import { NgReduxRouterModule } from '@angular-redux/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ToastrModule } from 'ngx-toastr';
import { ClickOutsideModule } from 'ng-click-outside';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CommonModule } from '@angular/common';
import { NgReduxFormModule } from '@angular-redux/form';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AgmCoreModule, GoogleMapsAPIWrapper, MarkerManager } from '@agm/core';
import { DndModule } from 'ng2-dnd';
import { TooltipModule } from 'ngx-bootstrap';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgHttpLoaderModule } from 'ng-http-loader';
import {
  UGridModule,
  USplitLayoutModule,
  USidebarMenuModule,
  UCommonModule,
  UTooltipModule
} from '@shift/ulib';

import { AppComponent } from './app.component';
import { appRoutes } from './routes/app.routes';
import { StoreModule } from './store/store.module';
import { translateLoader } from '@app/shared/utils';
import { SharedModule } from './shared/shared.module';
import { environment } from '@environments/environment';
import { AuthModule } from './modules/auth/auth.module';
import { LayoutModule } from './modules/layout/layout.module';
import { RolesGuard, AuthGuard, AuthGuardNo } from '@app/routes/guards';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgReduxFormModule,
    TranslateModule,
    NgxDatatableModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
      libraries: [ 'places' ]
    }),
    DndModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    ClickOutsideModule,
    UGridModule,
    UCommonModule,
    USplitLayoutModule,
    USidebarMenuModule,
    UTooltipModule,
    NgxChartsModule,
    PopoverModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoader,
        deps: [ HttpClient ]
      }
    }),
    VirtualScrollerModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgReduxModule,
    NgReduxRouterModule.forRoot(),
    StoreModule,
    BrowserAnimationsModule,
    UGridModule,
    UCommonModule,
    USplitLayoutModule,
    USidebarMenuModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCheckboxModule,
    ToastrModule.forRoot(),
    ClickOutsideModule,
    PopoverModule.forRoot(),
    AuthModule,
    SharedModule,
    LayoutModule,
    NgHttpLoaderModule
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    AuthGuard,
    AuthGuardNo,
    RolesGuard,
    GoogleMapsAPIWrapper,
    MarkerManager
  ],
  exports: [
    BrowserAnimationsModule
  ]
})

export class AppModule {
}
