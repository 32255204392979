import { SupplierAddEditComponent } from '../supplier-add-edit/supplier-add-edit.component';
const dictionaryPath = 'suppliers.table';

const suppliersPageConfig = {
  modalComponent: SupplierAddEditComponent,
  tableConfig: {
    counterLabel: `${dictionaryPath}.suppliers`,
    tableName: 'suppliers',
    columns: [
      {
        prop: 'check',
        name: '',
        width: 50,
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizeable: false,
        headerCheckboxable: true,
        checkboxable: true,
        custom: true,
        hideable: false,
        hideDivider: true,
        noValue: true
      },
      {
        prop: 'name',
        name: `${dictionaryPath}.name`,
        filterType: 'select',
        minWidth: 200,
        cellTemplateName: 'editRowCell',
        hideDivider: false,
        custom: true,
        clickble: true,
        highlight: false
      },
      {
        prop: 'type',
        name: `${dictionaryPath}.type`,
        translate: true,
        filterType: 'select',
        minWidth: 120,
        maxWidth: 240
      },
      {
        prop: 'address',
        name: `${dictionaryPath}.address`,
        filterType: 'select',
        selectItems: [],
        minWidth: 220
      },
      {
        prop: 'phone',
        name: `${dictionaryPath}.phone`,
        filterType: 'text',
        minWidth: 120,
        maxWidth: 250
      },
      {
        prop: 'mobile',
        name: `${dictionaryPath}.mobile`,
        filterType: 'text',
        minWidth: 120,
        maxWidth: 250
      },
      {
        prop: 'email',
        name: `${dictionaryPath}.email`,
        filterType: 'text',
        minWidth: 120,
        maxWidth: 250
      },
      {
        prop: 'notes',
        name: `${dictionaryPath}.notes`,
        filterType: 'text',
        minWidth: 120,
        maxWidth: 250
      }
    ]
  }
};

export default suppliersPageConfig;
