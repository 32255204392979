import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux/store';

@Injectable()
export class CommonActions {
  static readonly SET_CURRENT_DATE = 'SET_CURRENT_DATE';

  @dispatch()
    setCurrentDate = (payload: any) => ({
      type: CommonActions.SET_CURRENT_DATE,
      ...payload
    })
}
