import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

import inputsConfig from '../../inputs.config';
import { FileInputService } from './file-input.service';
import { FormsService, LocalizationService } from '@app/shared/services';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: [ './file-input.component.scss', './file-input.component.rtl.scss' ],
  providers: [ FileInputService ]
})
export class FileInputComponent implements OnInit {
  @Input() public fileType: string;
  @Input() public innerDelete: boolean;
  @Input() public outsideDelete: boolean | 'forced';
  @Input() public formObject: FormGroup;
  @Input() public fileInputType: 'normal' | 'icon' = 'normal';
  @Output() public removeEvent = new EventEmitter();

  constructor(
    private fileInputService: FileInputService,
    private formsService: FormsService,
    private localizationService: LocalizationService
  ) {}

  ngOnInit(): void {
    if (this.formObject && this.formObject.value === null) {
      this.formObject = this.formsService.generateForm(inputsConfig.file.fields);
    }
    this.fileInputService.setFileConfig(this.fileType);
  }

  isRtl(): boolean {
    return this.localizationService.isRtl();
  }

  fileChangeEvent(fileInput: any): void {
    const fileObject = fileInput.target.files && fileInput.target.files[0];
    if (fileObject) {
      if (this.fileInputService.checkFileValidation(fileObject.type, fileObject.size)) {
        this.fileInputService.loadFile(fileObject, this.formObject);
        this.setFormControlValue();
      }
    }
  }

  removeFile(): void {
    this.fileInputService.removeFile(this.formObject);
    this.setFormControlValue();
    this.removeEvent.emit();
  }

  setFormControlValue(): void {
    this.formObject.markAsDirty();
    this.formObject.updateValueAndValidity();
  }

  getFileSrc(): string {
    return this.formObject.get('fileSrc') && this.formObject.get('fileSrc').value;
  }

  get fileName(): string {
    return this.formObject.get('fileName') && this.formObject.get('fileName').value;
  }

  public get fileConfig(): any {
    return this.fileInputService.fileConfig;
  }

  downloadFile(): void {
    this.fileInputService.downloadFile(this.formObject.get('fileId').value, this.formObject.get('fileName').value, 'documents');
  }
}
