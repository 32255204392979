import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services';
import apiServiceConfig from '@app/shared/services/api/api.service.config';

@Injectable()
export class AlertsService {
  private paths: any = apiServiceConfig.paths.alerts;

  constructor(private apiService: ApiService) {}

  getAlerts(filterType?: string): Observable<any> {
    if (filterType) {
      return this.apiService.get(this.paths.basePath, {type: filterType});
    }
    return this.apiService.get(this.paths.basePath);
  }

  getFeed(): Observable<any> {
    return this.apiService.get(this.paths.basePath + this.paths.feed);
  }

  updateExpiration(body, type: 'date' | 'mileage' | 'documentDate'): Observable<any> {
    return this.apiService.put(this.paths.basePath + this.paths[type], body);
  }
}
