import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import apiServiceConfig from './api/api.service.config';
import { ApiService } from '@app/shared/services/api';

@Injectable()
export class ConstantsService {
  private firstInit: boolean;
  private paths: any = apiServiceConfig.paths.constants;
  categories: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private apiService: ApiService) {
  }

  fetchCategories(categories: string[]): void {
    this.apiService.get(this.paths.basePath, {categories: categories.join(',')})
        .subscribe(res => {
          Object.keys(res).forEach(key =>
              res[key] = res[key].map(value => {
                return {value, name: value}
              }));
          if (!this.firstInit) {
            this.categories.next(res);
            this.firstInit = true;
          } else {
            let currentValue = {};
            this.categories.subscribe(categoriesObs => currentValue = categoriesObs);
            this.categories.next(Object.assign(currentValue, res));
          }
        })
  }
}
