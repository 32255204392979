import { HistoryTableComponent } from '@app/shared/components/history-table/history-table.component';
import { DocumentsPageComponent } from '@app/shared/components/documents-page/documents-page.component';

const dictionaryPath = 'drivers.addEditDriver';

const fields = {
  id: {
    name: 'id',
    required: true,
    defaultValue: 0
  },
  firstName: {
    name: 'firstName',
    required: true,
    inputType: 'text'
  },
  lastName: {
    name: 'lastName',
    required: true,
    inputType: 'text'
  },
  identity: {
    name: 'identity',
    required: true,
    inputType: 'id',
    config: {
      role: 'driver',
      isPerson: true
    }
  },
  birthDate: {
    name: 'birthDate',
    inputType: 'datepicker'
  },
  address: {
    name: 'address',
    inputType: 'address'
  },
  nickname: {
    name: 'nickname',
    inputType: 'text'
  },
  employmentType: {
    name: 'employmentType',
    inputType: 'select',
    defaultValue: 'driverEmploymentType.company',
    required: true,
    inputOptionsVar: 'driverEmploymentType'
  },
  contractor: {
    name: 'contractor',
    inputType: 'text',
    showIf: {
      formControl: 'employmentType',
      equals: 'driverEmploymentType.freelance'
    }
  },
  branchId: {
    name: 'branchId',
    inputType: 'select',
    required: true,
    inputOptionsVar: 'branches'
  },
  departmentId: {
    name: 'departmentId',
    inputType: 'select',
    inputOptionsVar: 'departments'
  },
  workStartDate: {
    name: 'workStartDate',
    inputType: 'datepicker'
  },
  workEndDate: {
    name: 'workEndDate',
    inputType: 'datepicker'
  },
  status: {
    name: 'status',
    inputType: 'select',
    defaultValue: 'driverStatus.active',
    required: true,
    inputOptionsVar: 'driverStatus'
  },
  contacts: {
    name: 'contacts',
    required: true,
    arrayField: true,
    inputType: 'contacts',
    config: {
      role: 'driver',
      fetchExistingDetails: true,
      existingRoleMessage: `${dictionaryPath}.generalDetails.existingDriver`
    }
  },
  licenseNumber: {
    name: 'licenseNumber',
    inputType: 'number',
    title: `${dictionaryPath}.generalDetails.licenseDetails.licenseNumber`
  },
  licenseCategories: {
    name: 'licenseCategories',
    inputType: 'multiSelect',
    defaultValue: [],
    title: `${dictionaryPath}.generalDetails.licenseDetails.licenseCategories`,
    inputOptionsVar: 'drivingLicenseCategory'
  },
  licenseIssueDate: {
    name: 'licenseIssueDate',
    inputType: 'datepicker',
    title: `${dictionaryPath}.generalDetails.licenseDetails.licenseIssueDate`
  },
  licenseExpirationDate: {
    name: 'licenseExpirationDate',
    inputType: 'datepicker',
    title: `${dictionaryPath}.generalDetails.licenseDetails.licenseExpirationDate`
  },
  canDriveKids: {
    name: 'canDriveKids',
    inputType: 'select',
    title: `${dictionaryPath}.generalDetails.licenseDetails.canDriveKids`,
    inputOptionsArr: [
      {
        name: `general.yes`,
        value: true
      },
      {
        name: `general.no`,
        value: false
      }
    ]
  },
  needGlasses: {
    name: 'needGlasses',
    inputType: 'select',
    title: `${dictionaryPath}.generalDetails.licenseDetails.needGlasses`,
    inputOptionsArr: [
      {
        name: `general.yes`,
        value: true
      },
      {
        name: `general.no`,
        value: false
      }
    ]
  },
  vehicleIds: {
    name: 'vehicleIds',
    inputType: 'multiSelect',
    defaultValue: [],
    title: `${dictionaryPath}.generalDetails.associatedVehicles.vehicleLicenseNumber`,
    inputOptionsVar: 'vehicles'
  },
  licenseDealerNumber: {
    name: 'licenseDealerNumber',
    inputType: 'number',
    valueType: 'string',
    title: `${dictionaryPath}.accounting.licenseDealerNumber`
  },
  taxPercentage: {
    name: 'taxPercentage',
    inputType: 'percent',
    defaultValue: 1,
    minValue: 1,
    title: `${dictionaryPath}.accounting.tax`
  },
  taxExpirationDate: {
    name: 'taxExpirationDate',
    inputType: 'datepicker',
    title: `${dictionaryPath}.accounting.taxExpirationDate`
  },
  accountantPaymentType: {
    name: 'accountantPaymentType',
    inputType: 'paymentTerms',
    title: `${dictionaryPath}.accounting.paymentTerms.title`
  },
  accountantPaymentTerms: {
    name: 'accountantPaymentTerms'
  },
  accountingKey: {
    name: 'accountingKey',
    inputType: 'text',
    title: `${dictionaryPath}.accounting.key`
  },
  notes: {
    name: 'notes',
    inputType: 'textarea',
    title: `${dictionaryPath}.accounting.notes`
  },
  documents: {
    name: 'documents',
    inputType: 'documents',
    bodyComponent: DocumentsPageComponent
  }
};

fields.identity.config['formFields'] = fields;

const tabs = [
  {
    id: 'info',
    notNestedForm: true,
    title: `${dictionaryPath}.generalDetails.title`,
    columns: [
      [
        {
          name: 'general',
          title: `${dictionaryPath}.generalDetails.title`,
          fields: [
            {
              ...fields.firstName,
              title: `${dictionaryPath}.generalDetails.firstName`
            },
            {
              ...fields.lastName,
              title: `${dictionaryPath}.generalDetails.lastName`
            },
            {
              ...fields.identity,
              title: `${dictionaryPath}.generalDetails.identity`
            },
            {
              ...fields.birthDate,
              title: `${dictionaryPath}.generalDetails.birthDate`
            },
            {
              ...fields.address,
              title: `${dictionaryPath}.generalDetails.address`
            },
            {
              ...fields.nickname,
              title: `${dictionaryPath}.generalDetails.nickname`
            },
            {
              ...fields.employmentType,
              title: `${dictionaryPath}.generalDetails.employmentType`,
            },
            {
              ...fields.contractor,
              title: `${dictionaryPath}.generalDetails.operatorContractor`
            },
            {
              ...fields.branchId,
              title: `${dictionaryPath}.generalDetails.branchId`
            },
            {
              ...fields.departmentId,
              title: `${dictionaryPath}.generalDetails.department`
            },
            {
              ...fields.workStartDate,
              title: `${dictionaryPath}.generalDetails.startDate`
            },
            {
              ...fields.workEndDate,
              title: `${dictionaryPath}.generalDetails.endDate`
            },
            {
              ...fields.status,
              title: `${dictionaryPath}.generalDetails.status`
            }
          ]
        }
      ],
      [
        {
          title: `${dictionaryPath}.generalDetails.contacts.title`,
          fields: [ fields.contacts ]
        },
        {
          title: `${dictionaryPath}.generalDetails.licenseDetails.title`,
          fields: [
            {
              ...fields.licenseNumber,
              title: `${dictionaryPath}.generalDetails.licenseDetails.licenseNumber`
            },
            {
              ...fields.licenseCategories,
              title: `${dictionaryPath}.generalDetails.licenseDetails.licenseCategories`
            },
            {
              ...fields.licenseIssueDate,
              title: `${dictionaryPath}.generalDetails.licenseDetails.licenseIssueDate`
            },
            {
              ...fields.licenseExpirationDate,
              title: `${dictionaryPath}.generalDetails.licenseDetails.licenseExpirationDate`
            },
            {
              ...fields.canDriveKids,
              title: `${dictionaryPath}.generalDetails.licenseDetails.canDriveKids`
            },
            {
              ...fields.needGlasses,
              title: `${dictionaryPath}.generalDetails.licenseDetails.needGlasses`
            }
          ]
        },
        {
          title: `${dictionaryPath}.generalDetails.associatedVehicles.title`,
          fields: [
            {
              ...fields.vehicleIds,
              title: `${dictionaryPath}.generalDetails.associatedVehicles.vehicleLicenseNumber`
            }
          ]
        }
      ] ]
  },
  {
    id: 'accounting',
    notNestedForm: true,
    title: `${dictionaryPath}.accounting.title`,
    columns: [
      [
        {
          title: `${dictionaryPath}.accounting.title`,
          fields: [
            {
              ...fields.licenseDealerNumber,
              title: `${dictionaryPath}.accounting.licenseDealerNumber`
            },
            {
              ...fields.taxPercentage,
              title: `${dictionaryPath}.accounting.tax`
            },
            {
              ...fields.taxExpirationDate,
              title: `${dictionaryPath}.accounting.taxExpirationDate`
            },
            {
              ...fields.accountantPaymentType,
              title: `${dictionaryPath}.accounting.paymentTerms.title`
            },
            {
              ...fields.accountingKey,
              title: `${dictionaryPath}.accounting.key`
            },
            {
              ...fields.notes,
              title: `${dictionaryPath}.accounting.notes`
            }
          ]
        }
      ]
    ]
  },
  {
    id: 'documents',
    notNestedForm: true,
    title: `${dictionaryPath}.documents.title`,
    columns: [
      [
        {
          fields: [ fields.documents ]
        }
      ]
    ]
  }
];

const historyTab = {
  id: 'history',
  title: `${dictionaryPath}.history.title`,
  columns: [
    [ {
      fields: [
        {
          bodyComponent: HistoryTableComponent
        }
      ]
    } ]
  ]
};

const driverAddEditModalConfig = {
  fields,
  tabs,
  editTabs: [ ...tabs, historyTab ],
  hiddenFields: {id: fields.id, accountantPaymentTerms: fields.accountantPaymentTerms},
  title: 'drivers.addEditDriver.title',
  deleteLabel: 'drivers.addEditDriver.deleteDriver',
  categoriesToFetch: [ 'contactType', 'driverEmploymentType', 'driverStatus', 'drivingLicenseCategory', 'roleType', 'paymentTerms', 'paymentType', 'operatorContractors' ],
  itemsToFetch: [
    {
      basePath: 'departments', fetchItems: [ {name: 'departments'} ]
    },
    {
      basePath: 'branches', fetchItems: [ {name: 'branches'} ]
    },
    {
      basePath: 'vehicles', fetchItems: [ {name: 'vehicles'} ]
    }
  ]
};

export default driverAddEditModalConfig;
