import { Languages } from '@app/shared/models';
import { environment } from '@environments/environment';

const LANGUAGES = {
  hebrew: {
    code: Languages.Hebrew,
    name: 'עברית'
  },
  english: {
    code: Languages.English,
    name: 'English'
  }
};

const appConfig = {
  appName: 'Ctrl',
  apiUrl: `${environment.serverUrl}/api`,
  userRoles: {
    customer: 'Customer',
    admin: 'Admin'
  },
  logoutTimeMinutes: 15,
  languages: LANGUAGES,
  defaultLanguage: LANGUAGES.english,
  rtlLanguage: LANGUAGES.hebrew,
  localStorageKeys: {
    token: 'token',
    language: 'lang'
  },
  defaultRoutes: {
    homePage: {
      customer: 'alerts',
      admin: 'customers'
    },
    unauthorized: 'login',
    default: ''
  },
  settings: {
    allowedRoles: [ 'trainer', 'manager', 'admin' ]
  }
};

export default appConfig;
