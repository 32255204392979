<div class="header">
  <div class="header__logo">
    <span class="header__logo-name">{{appConfig.appName}}</span>
  </div>

  <div class="header__inputs">
    <div class="header__icons">
      <app-add-btn></app-add-btn>

      <div class="header__icons-btns" *ngIf="!isAdminUser">
        <button [uTooltip]="'header.alerts' | translate" type="button" class="header__icons-feed" *ngIf="showFeed" (click)="navigateToAlerts()"></button>
        <button [uTooltip]="'header.activities' | translate" type="button" class="header__icons-activities"></button>
        <button [uTooltip]="'header.mapView' | translate" type="button" class="header__icons-map"></button>
        <button [uTooltip]="'header.refresh' | translate" type="button" class="header__icons-refresh"></button>
        <button [uTooltip]="'header.refresh' | translate" type="button" class="header__icons-dots"></button>
      </div>
    </div>

    <div class="header-search" *ngIf="headerDataService.showGlobalSearch">
      <input type="text" [(ngModel)]="searchQuery" placeholder="{{'header.search' | translate }}"
             class="header-search__input" (keyup)="onSearchKeyUp()">
      <div class="header-search__filter"></div>
    </div>

    <div
      class="header-date"
      *ngIf="headerDataService.showDate$ | async"
      [ngClass]="{'header-date_disabled': headerDataService.isDateDisabled }"
    >
      <div class="header-date__day" *ngIf="!(headerDataService.showWeekSwitch$ | async)">
        <div class="header-date__day-box">
          <div class="header-date__day-calendar">
            <u-input-date
              class="u-input-date_app-header"
              [mode]="'pick'"
              [showArrows]="true"
              [formControl]="headerDataService.dateForm"
              [format]="appConstants.DATE_FORMAT_BASE_SLASH_SHORT"
              [valid]="headerDataService.dateForm.valid"
              [placement]="isRtl ? ['bottom-left'] : ['bottom-right']"
              [readonly]="true"
              [defaultUncheckDate]="true"
              [messages]="'uInputDate' | translate"
            ></u-input-date>
          </div>
          <div
            class="header-date__day-today"
            *ngIf="showTodayButton$ | async"
            (click)="backToToday()"
          >
            {{'header.today'|translate}}
          </div>
        </div>
      </div>
      <div class="header-date__week" *ngIf="headerDataService.showWeekSwitch$ | async">
        <div class="header-date__week-box">
          <app-week-switch
            class="header-date__week-calendar week-switch_app-header"
            [initDate]="headerDataService.weekSwitch.initDate"
            [startDateLimit]="headerDataService.weekSwitch.startDateLimit"
            [endDateLimit]="headerDataService.weekSwitch.endDateLimit"
            (weekChange)="headerDataService.weekSwitchChangeSet($event)"
          ></app-week-switch>
          <div
            class="header-date__day-this-week"
            *ngIf="showThisWeekButton$ | async"
            (click)="backToThisWeek()"
          >
            {{'header.thisWeek'|translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
