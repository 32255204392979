import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from '@app/shared/services';
import apiServiceConfig from '@app/shared/services/api/api.service.config';

@Injectable({
  providedIn: 'root'
})
export class InspectionService {
  private paths = apiServiceConfig.paths.inspection;
  public totalPassed: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public totalFailed: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private apiService: ApiService) {}

  startInspection(): Observable<any> {
    return this.apiService.post(this.paths.basePath + this.paths.start);
  }

  initCounters(categoriesForm): void {
    this.totalPassed.next(0);
    this.totalFailed.next(0);

    categoriesForm.controls.forEach(categoryForm => {
      const itemsFormControls = categoryForm.get('items').controls;
      if (itemsFormControls) {
        itemsFormControls.forEach(itemForm => {
          const result = itemForm.value && itemForm.value.ok;
          if (result === true) {
            this.totalPassed.next(this.totalPassed.value + 1);
          } else if (result === false) {
            this.totalFailed.next(this.totalFailed.value + 1);
          }
        });
      }
    });
  }

  getAvailableVehicles(): Observable<any> {
    return this.apiService.get(this.paths.basePath + this.paths.availableVehicles);
  }

  addValid(): void {
    this.totalPassed.next(this.totalPassed.value + 1);
    this.totalFailed.next(this.totalFailed.value - 1);
  }

  addInvalid(): void {
    this.totalPassed.next(this.totalPassed.value - 1);
    this.totalFailed.next(this.totalFailed.value + 1);
  }
}
