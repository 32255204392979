import appConfig from '@app/app.config';
import { LoginPageComponent } from '@app/modules/auth/components';
import { AuthGuardNo } from '@app/routes/guards/auth-guard-no.service';
import { NotFoundComponent } from '@app/modules/layout/components';

const defaultRoutes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/alerts'
  },
  {
    path: 'alerts',
    loadChildren: '@app/modules/alerts/alerts.module#AlertsModule',
    data: {
      title: 'alerts.pageTitle'
    }
  },
    // {
    //     path: 'expenses',
    //     loadChildren: '@app/modules/expenses/expenses.module#ExpensesModule',
    //     data: {
    //         title: 'expenses.pageTitle'
    //     }
    // },
  {
    path: 'events',
    loadChildren: '@app/modules/events/events.module#EventsModule',
    data: {
      title: 'events.pageTitle'
    }
  },
  {
    path: 'drivers',
    loadChildren: '@app/modules/drivers/drivers.module#DriversModule',
    data: {
      title: 'drivers.pageTitle'
    }
  },
  {
    path: 'vehicles',
    loadChildren: '@app/modules/vehicles/vehicles.module#VehiclesModule',
    data: {
      title: 'vehicles.pageTitle'
    }
  },
  {
    path: 'suppliers',
    loadChildren: '@app/modules/suppliers/suppliers.module#SuppliersModule',
    data: {
      title: 'suppliers.pageTitle'
    }
  },
  {
    path: 'safetyOfficers',
    loadChildren: '@app/modules/safety-officers/safety-officers.module#SafetyOfficersModule',
    data: {
      title: 'safetyOfficers.pageTitle'
    }
  },
  {
    path: 'reports',
    loadChildren: '@app/modules/reports/reports.module#ReportsModule',
    data: {
      showSearch: false,
      title: 'reports.pageTitle'
    }
  }
];

export const routesConfig = {
  admin: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: '/customers'
    },
    {
      path: 'customers',
      loadChildren: '@app/modules/customers/customers.module#CustomersModule',
      data: {
        title: 'customers.pageTitle'
      }
    }
  ],
  common: [
    {
      path: 'settings',
      loadChildren: '@app/modules/settings/settings.module#SettingsModule',
      data: {
        showFeed: false,
        title: 'settings.pageTitle',
        allowedRoles: appConfig.settings.allowedRoles
      }
    },
    {
      path: 'NotFound',
      component: NotFoundComponent,
      data: {
        title: 'general.comingSoon'
      }
    }
  ],
  manager: defaultRoutes,
  trainer: defaultRoutes,
  customer: defaultRoutes,
  safetyOfficer: defaultRoutes,
  login: {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [ AuthGuardNo ],
    data: {
      title: 'loginPage.pageTitle'
    }
  },
  notFound: {
    path: '**',
    redirectTo: '/NotFound',
    data: {
      notFound: true,
      title: 'notFoundPage.pageTitle'
    }
  }
};
