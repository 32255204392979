import addEditModalComponentConfig from '@app/shared/modules/add-edit-modal/components/add-edit-modal/add-edit-modal.component.config';

const addPopoverItems = {
  drivers: {
    title: 'alerts.addPopover.addDriver',
    icon: '/assets/images/icons/side-menu/driver.svg',
    addEditModal: addEditModalComponentConfig.modals.drivers
  },
  vehicles: {
    title: 'alerts.addPopover.addVehicle',
    icon: '/assets/images/icons/side-menu/vehicle2.svg',
    addEditModal: addEditModalComponentConfig.modals.vehicles
  },
  events: {
    title: 'alerts.addPopover.addEvent',
    icon: '/assets/images/icons/side-menu/events.svg',
    addEditModal: addEditModalComponentConfig.modals.events
  },
  suppliers: {
    title: 'alerts.addPopover.addSupplier',
    icon: '/assets/images/icons/side-menu/supplier2.svg',
    addEditModal: addEditModalComponentConfig.modals.suppliers
  },
    // expenses: {
    //     title: 'alerts.addPopover.addExpense',
    //     icon: '/assets/images/icons/side-menu/wallet.svg',
    //     addEditModal: addEditModalConfig.expenses
    // },
  inspections: {
    title: 'alerts.addPopover.addInspection',
    icon: '/assets/images/icons/icons-header/search.svg',
    addEditModal: addEditModalComponentConfig.modals.inspections,
    permittedUserRoles: [ 'safetyOfficer' ]
  },
  safetyOfficers: {
    title: 'alerts.addPopover.addSafetyOfficer',
    icon: '/assets/images/icons/side-menu/safety-officer.svg',
    addEditModal: addEditModalComponentConfig.modals.safetyOfficers
  }
};

const addBtnConfig = {
  customers: {
    addEditModal: addEditModalComponentConfig.modals.customers
  },
  alerts: { addPopoverItems },
  expenses: { addPopoverItems },
  events: { addPopoverItems },
  drivers: { addPopoverItems },
  vehicles: { addPopoverItems },
  suppliers: { addPopoverItems },
  reports: { addPopoverItems },
  safetyOfficers: { addPopoverItems },
  settings: {}
};

export default addBtnConfig;
