import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ExpenseOrderService } from '../expense-order.service';
import expenseAddEditOrderConfig from '../expense-add-edit-order-details.component.config';

@Component({
  selector: 'app-expense-order-summary',
  templateUrl: './expense-order-summary.component.html',
  styleUrls: [ './expense-order-summary.component.scss', './expense-order-summary.component.rtl.scss' ]
})
export class ExpenseOrderSummaryComponent implements OnInit {
  discountPercentControl: FormControl = new FormControl(0, Validators.max(100));
  discountNISControl: FormControl = new FormControl(0);
  expenseAddEditOrderConfig = expenseAddEditOrderConfig;

  constructor(public expenseOrderService: ExpenseOrderService) { }

  ngOnInit(): void {
    this.subscribeToTotalChange();
  }

  subscribeToTotalChange(): void {
    this.expenseOrderService.totalRowsPriceSubject.subscribe(totalRowsPrice => {
      if (totalRowsPrice) {
        this.updateDiscountNISValue();
        this.updateDiscountPercentValue();
        this.discountNISControl.setValidators(Validators.max(totalRowsPrice));
        this.discountNISControl.updateValueAndValidity();
      }
    });
  }

  updateDiscountPercentValue(): void {
    this.discountPercentControl.setValue(this.expenseOrderService.calculateDiscountPercent(this.discountNISControl.value));
  }

  updateDiscountNISValue(): void {
    this.discountNISControl.setValue(this.expenseOrderService.calculateDiscountNis(this.discountPercentControl.value));
  }

  public get sumVat(): number {
    return this.expenseOrderService.calculateSumVat();
  }

  public get totalPayment(): number {
    return this.expenseOrderService.calculateTotalPayment(this.sumVat, this.discountNISControl.value);
  }
}
