import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import find from 'lodash/find';

import appConfig from '@app/app.config';
import { routesConfig } from '@app/routes/app.routes.config';

@Injectable()
export class RolesGuard implements CanActivateChild {
  @select([ 'auth', 'user', 'role' ])
    readonly userRole: Observable<any>;

  constructor(private router: Router) {}

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const requestedRoute = state.url.substr(1);
    this.userRole.subscribe(userRole => {
      if (userRole) {
        this.guardRoleRoutes(requestedRoute, userRole);
      }
    });

    return true;
  }

  guardRoleRoutes(routePath, userRole): void {
    const routeConfig = find(routesConfig[userRole], {path: routePath}) || find(routesConfig.common, {path: routePath});

    if (!routeConfig || (routeConfig.data.allowedRoles && !routeConfig.data.allowedRoles.includes(userRole))) {
      this.redirect(userRole);
    }
  }

  redirect(userRole): void {
    this.router.navigate([ appConfig.defaultRoutes.homePage[userRole] || appConfig.defaultRoutes.default ]);
  }
}
