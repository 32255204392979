import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgProgressModule } from 'ngx-progressbar';
import { UCommonModule, UPopoverModule, USidebarMenuModule, UTooltipModule } from '@shift/ulib';

import { SideMenuService } from './services';
import { SharedModule } from '@app/shared/shared.module';
import { AuthModule } from '@app/modules/auth/auth.module';
import { LayoutComponent, SideMenuComponent, HeaderComponent, NotFoundComponent, AddBtnComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    USidebarMenuModule,
    ClickOutsideModule,
    UPopoverModule,
    FormsModule,
    NgProgressModule,
    SharedModule,
    UCommonModule,
    ReactiveFormsModule,
    UTooltipModule,
    AuthModule
  ],
  declarations: [
    LayoutComponent,
    SideMenuComponent,
    HeaderComponent,
    AddBtnComponent,
    NotFoundComponent
  ],
  exports: [
    LayoutComponent,
    SideMenuComponent,
    HeaderComponent
  ],
  providers: [
    SideMenuService
  ]
})
export class LayoutModule { }
