import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()

export class CustomersService {
  public customersList = new ReplaySubject<[{}]>(1);

  constructor() {}

  setCustomersList(customers): void {
    this.customersList.next(customers);
  }
}
