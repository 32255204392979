import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { VehiclesService } from '../../services';
import { BaseService, CtrlFormsService } from '@app/shared/services';
import { MetadataService } from '@app/shared/services/metadata.service';
import vehicleAddEditModalConfig from './vehicle-add-edit.component.config';
import { AddEditModalService } from '@app/shared/modules/add-edit-modal/services';
import vehicleAddEditAccountingConfig from './vehicle-add-edit-configs/vehicle-add-edit-accounting.component.config';

@Component({
  selector: 'app-vehicle-add-edit',
  templateUrl: './vehicle-add-edit.component.html',
  styleUrls: [ './vehicle-add-edit.component.scss', './vehicle-add-edit.component.rtl.scss' ]
})
export class VehicleAddEditComponent implements OnInit {
  @Output() action = new EventEmitter();

  editData: any;
  modalConfig: any;
  addEditForm: FormGroup;
  currentOwnershipType: string;

  constructor(
    private baseService: BaseService,
    private ctrlFormsService: CtrlFormsService,
    private vehiclesService: VehiclesService,
    public addEditModalService: AddEditModalService,
    private metadataService: MetadataService
  ) {}

  ngOnInit(): void {
    this.baseService.setPaths('vehicles');
    this.setModalConfig();
    this.metadataService.setMetadataObs(this.modalConfig);

    this.initAddEditForm();
    this.setDefaultBranchAsValue();

    if (this.editData) {
      const prevOwnershipType = this.addEditForm.get('ownershipType').value;
      this.ctrlFormsService.patchValueNested(this.addEditForm, this.editData, this.modalConfig.fields);
      this.switchAccountingConfig(this.addEditForm.get('ownershipType').value, prevOwnershipType);
      this.getVehicleInspections(this.editData.id);
      this.getVehicleEvents(this.editData.id);
    }
  }

  initAddEditForm(): void {
    this.addEditForm = this.ctrlFormsService.generateModalTabsForm(this.modalConfig);
    this.currentOwnershipType = this.addEditForm.get('ownershipType').value;

    this.subscribeToAccountingTypeChange();
  }

  getVehicleInspections(vehicleId: number): void {
    this.vehiclesService.getVehicleInspections(vehicleId).subscribe(inspections =>
          this.metadataService.setMetadataItemValue('inspections', inspections));
  }

  getVehicleEvents(vehicleId: number): void {
    this.vehiclesService.getVehicleEvents(vehicleId).subscribe(events =>
          this.metadataService.setMetadataItemValue('events', events));
  }

  setModalConfig(): void {
    this.modalConfig = cloneDeep(vehicleAddEditModalConfig);
    this.modalConfig.tabs = this.editData ? this.modalConfig.editTabs : this.modalConfig.tabs;
  }

  subscribeToAccountingTypeChange(): void {
    this.addEditForm.get('ownershipType').valueChanges
          .subscribe(newVal => this.switchAccountingConfig(newVal));
  }

  switchAccountingConfig(selectedType: string, prevType: string = this.currentOwnershipType): void {
    if (selectedType && selectedType !== prevType) {
      const selectedTypeName = selectedType.substring(selectedType.indexOf('.') + 1);

      const prevTypeName = prevType.substring(prevType.indexOf('.') + 1);
      const prevAccountingTabFields = vehicleAddEditAccountingConfig[prevTypeName].fields;

      this.ctrlFormsService.replaceFormFields(this.addEditForm, vehicleAddEditAccountingConfig[selectedTypeName].fields, prevAccountingTabFields);
      this.modalConfig.tabs.find(tab => tab.id === 'accounting').columns = vehicleAddEditAccountingConfig[selectedTypeName].columns;

      this.currentOwnershipType = selectedType;
      this.subscribeToAccountingTypeChange();
    }
  }

  setDefaultBranchAsValue(): void {
    if (!this.addEditForm.get('branchId').value) {
      this.metadataService.metadataObsObj['branches'].subscribe(branches => {
        if (branches) {
          const branchId = branches.find(branch => branch.isDefault).value;
          this.addEditForm.get('branchId').patchValue(branchId);
          this.fetchDrivers(branchId);
        }
      });
    } else {
      this.fetchDrivers(this.addEditForm.get('branchId').value);
    }
  }

  fetchDrivers(branchId: string): void {
    this.baseService.getAllSlim({BranchIds: [ branchId ]}, 'drivers').subscribe(drivers => {
      this.metadataService.setMetadataItemValue('drivers', drivers);
    });
  }

  deleteVehicle(): void {
    this.addEditModalService.deleteEntity('vehicles.addEditVehicle.messages.deleteConfirm', this.editData, this.action);
  }

  submitForm = (): void => {
    if (this.addEditModalService.canSubmit(this.addEditForm)) {
      this.addEditModalService.submitForm(this.addEditForm.value, this.action, this.editData);
    }
  };
}
