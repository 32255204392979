import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { FormsService } from '@app/shared';
import { ExpenseOrderService } from './expense-order.service';
import expenseAddEditOrderConfig from './expense-add-edit-order-details.component.config';

@Component({
  selector: 'app-expense-add-edit-order-details',
  templateUrl: './expense-add-edit-order-details.component.html',
  styleUrls: [ './expense-add-edit-order-details.component.scss', 'expense-add-edit-order-details.component.rtl.scss' ]
})
export class ExpenseAddEditOrderDetailsComponent implements OnInit {
  @Input() public formObject: FormGroup;

  orderItemForm: FormGroup;
  expenseAddEditOrderConfig = expenseAddEditOrderConfig;
  expenseOrderItemConfig = expenseAddEditOrderConfig.expenseOrderItemConfig;

  constructor(private formsService: FormsService, private expenseOrderService: ExpenseOrderService) { }

  ngOnInit(): void {
    this.expenseOrderService.initOrderItemListForm();
    this.initOrderForm();
    this.addOrderItem();
  }

  initOrderForm(): void {
    this.formObject.controls = this.formsService.generateForm(expenseAddEditOrderConfig.extraFieldsConfig.fields).controls;
    this.formObject.addControl('orderItems', this.expenseOrderService.orderItemListForm);
  }

  addOrderItem(): void {
    this.orderItemForm = this.formsService.generateForm(this.expenseOrderItemConfig.fields);
    this.expenseOrderService.orderItemListForm.push(this.orderItemForm);
  }

  removeOrderItem(index: number): void {
    this.expenseOrderService.orderItemListForm.removeAt(index);
  }

  get orderItemListForm(): FormArray {
    return this.expenseOrderService.orderItemListForm;
  }
}
