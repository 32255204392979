import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { Component, ElementRef, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UGridComponent, UPopupService } from '@shift/ulib';

import { AddEditModalService } from '@app/shared/modules/add-edit-modal/services';
import { BaseService, GlobalSearchService, ApiService, HeaderDataService } from '@app/shared/services';

@Injectable({
  providedIn: 'root',
})
export class TablePageService {
  public rows: any[];
  public tableConfig: any;
  public searchRows: any[];
  public visibleRows: any[];
  public selectedRows: any[] = [];
  public specificRowClassObjects: any[];
  public tableElement: ElementRef<UGridComponent>;

  constructor(
    private headerDataService: HeaderDataService,
    private baseService: BaseService,
    private addEditModalService: AddEditModalService,
    private searchService: GlobalSearchService,
    private apiService: ApiService,
    private popupService: UPopupService,
    private translate: TranslateService,
    private datePipe: DatePipe
  ) {
    this.searchListener();
  }

  initRows(rows?: any[]): void {
    this.visibleRows = [];
    this.rows = rows || this.rows || [];
    if (this.tableConfig && this.rows) {
      this.addRowsManipulatedValues(this.rows, this.tableConfig.columns);
    }
    this.visibleRows = this.rows;
    if (this.tableElement) {
            // @ts-ignore - u-grid function
      this.tableElement.updateRows();
    }
  }

  deleteRow(id: number): void {
    this.rows = this.rows.filter(row => row.id !== id);
    this.initRows();
  }

  addRow(newRow: {}): void {
    this.rows.push(newRow);
    this.initRows();
  }

  replaceRow(id: number, newRow: {}): void {
    const rowsArrayIndex = this.rows.findIndex(row => row.id === id);
    if (rowsArrayIndex >= 0) {
      this.rows[rowsArrayIndex] = Object.assign(this.rows[rowsArrayIndex], newRow);
      this.initRows();
    }
  }

  editRow(row, modalComponent: Component, basePath?: string): void {
    this.addEditModalService.isEditMode = true;

    if (basePath) {
      this.apiService.get(basePath, {id: row.id}).subscribe(data => {
        this.openAddEditModal(modalComponent, data);
      });
    } else {
      this.baseService.byId(row.id)
              .subscribe(editData => this.openAddEditModal(modalComponent, editData));
    }
  }

  addRowsManipulatedValues(rows: any[], columns: any[]): void {
    rows.forEach(row => row = this.addRowManipulatedValues(row, columns));
  }

  addRowManipulatedValues(row, columns): any {
    columns.forEach(column => {
      if (row[column.prop]) {
        if (column['formControl']) {
          this.setColumnFormControl(column, row);
        }
        if (column['translate']) {
          this.setColumnTranslatedValue(column, row);
        }
        if (column['dateFormat']) {
          row[column.prop] = this.datePipe.transform(row[column.prop], column['dateFormat']);
        }
      }
    });

    return row;
  }

  setColumnTranslatedValue(column, row): void {
    row[column.prop + 'TranslatePath'] = row[column.prop];
    this.translate.get(row[column.prop]).subscribe(translatedVal => row[column.prop] = translatedVal);
  }

  setColumnFormControl(column, row): void {
    const colName = column.prop;
    const cellFormControl = new FormControl(row[colName]);

    cellFormControl.valueChanges.subscribe(newVal => {
      this.baseService.patchProperty(colName, {value: newVal, id: row.id}).subscribe(() => {
        this.popupService.showMessage({
          message: 'table.successfullyUpdated',
          yes: 'general.ok'
        }, () => {});
      });
      if (column['translate']) {
        this.setColumnTranslatedValue(column, row);
      } else {
        row[colName] = cellFormControl.value;
      }
    });
    row[colName + 'FormControl'] = cellFormControl;
  }

  openAddEditModal(modalComponent, editData?, subscribeForChanges: boolean = true): void {
    const modalRef = this.addEditModalService.openModal(modalComponent, editData);

    if (subscribeForChanges) {
      modalRef.content.action.subscribe(({ type, data }) => {
        const newRowObj = this.addRowManipulatedValues(data, this.tableConfig.columns);

        switch (type) {
          case 'add':
            this.rows = [ newRowObj, ...this.rows ];
            this.highlightRow(newRowObj['id']);
            break;
          case 'edit':
            this.rows = this.rows.map(row => {
              if (row.id === data.id) {
                return {
                  ...row,
                  ...newRowObj
                }
              }
              return { ...row };
            });
            this.highlightRow(newRowObj['id']);
            break;
          case 'delete':
            this.rows = this.rows.filter(row => row.id !== data.id);
            break;
        }
        this.visibleRows = this.rows;
      });
    }
  }

  private searchListener(): void {
    this.headerDataService.showGlobalSearch = true;

    this.searchService.searchQuery.subscribe(query => {
      if (query) {
        const searchArray = [];
        this.rows.forEach(row => {
          const searchResult = Object.values(row).filter(val => {
            let value = val;
            while (value && value['value']) {
              value = value['value'];
            }
            return value && value.toString().toLowerCase().includes(query.toString().toLowerCase());
          });
          if (searchResult.length > 0) {
            searchArray.push(row);
          }
        });
        this.searchRows = searchArray;
        this.visibleRows = this.searchRows;
      } else {
        this.searchRows = [];
        this.visibleRows = this.rows;
      }
    });
  }

  highlightRow(rowId: number): void {
    this.specificRowClassObjects = [ {
      className: 'highlighted-row',
      rowPropertyName: 'id',
      value: rowId
    } ];

    setTimeout(() => this.specificRowClassObjects = [], 3000);
  }
}
