import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { UPopupService } from '@shift/ulib';

import { FormsService } from '@app/shared/services';
import { AddEditUsersService } from './add-edit-users.service';
import addEditUsersComponentConfig from './add-edit-users.component.config';
import { MetadataService } from '@app/shared/services/metadata.service';

@Component({
  selector: 'app-add-edit-users',
  templateUrl: './add-edit-users.component.html',
  styleUrls: [ './add-edit-users.component.scss', './add-edit-users.component.rtl.scss' ]
})
export class AddEditUsersComponent implements OnInit, OnChanges {
  @Input() config: any;
  @Input() formObject: FormGroup;

  editData: any;
  selectedUser: any;
  selectedUserIndex: number;
  metadata: any = {
    usersForm: {}
  };
  userDetailsConfig: any;
  usersInputConfig: any = addEditUsersComponentConfig;

  constructor(
    private formsService: FormsService,
    private popupService: UPopupService,
    private metadataService: MetadataService,
    private usersService: AddEditUsersService
  ) {}

  ngOnInit(): void {
    if (this.config) {
      Object.assign(this.usersInputConfig, this.config);
    }

    this.metadataService.setMetadataObs(this.usersInputConfig);
    this.userDetailsConfig = this.usersInputConfig.userDetailsConfig;
    this.usersService.initSelectedUserIndex();
    this.subscribeToUserSelection();

    if (this.usersData && this.usersData.length) {
      this.selectUserIndex(0);
    }

    this.metadataService.fetchCategories(this.usersInputConfig['categoriesToFetch']);
  }

  ngOnChanges(): void {
    this.selectDefaultUser();
    this.setMetaData();
  }

  setMetaData(): void {
    this.metadata.usersForm = this.usersForm;
    this.editData = this.usersData && this.usersData[this.selectedUserIndex];
  }

  selectDefaultUser(): void {
    if (this.selectedUserIndex >= 0 && this.usersForm.value.length > 0) {
      this.selectUserIndex(0);
    }
  }

  subscribeToUserSelection(): void {
    this.usersService.selectedUserIndex.subscribe(selectedIndex => {
      if (selectedIndex !== this.selectedUserIndex) {
        this.selectUserIndex(selectedIndex, true);
      }
    });
  }

  get usersForm(): FormArray {
    return (this.formObject && this.formObject.get('users') || this.formObject) as FormArray;
  }

  get usersData(): Array<any> {
    return this.editData ? this.editData.users : null;
  }

  addNewUser(): void {
    if (this.canAddNewUser()) {
      const userForm = this.formsService.generateForm(this.userDetailsConfig.fields);
      userForm.addControl('id', new FormControl(0, [ Validators.required ]));
      this.usersForm.push(userForm);
      this.selectUserIndex(this.usersForm.length - 1);
    }
  }

  canAddNewUser(): Boolean {
    return this.usersForm.length === 0 || this.usersForm.valid;
  }

  deleteUser(index: number): void {
    this.popupService.showMessage({
      message: 'addEditUsers.messages.deleteUserConfirm',
      yes: 'general.yes',
      no: 'general.no',
    },
      () => {
        this.usersForm.removeAt(index);
        this.selectUserIndex(index - 1, true);
        this.formObject.markAsDirty();
      });
  }

  selectUserIndex(index: number, forcedSelection: boolean = false): void {
    if ((!this.selectedUser || this.selectedUser.valid) || forcedSelection) {
      this.selectedUserIndex = index;
      this.selectedUser = this.usersForm.at(index);
      this.usersService.selectedUserIndex.next(index);
    }
  }
}
