<div class="files-images-input">
  <input multiple type="file" #imageFileInput (change)="fileChangeEvent($event, 'images', imagesForm)" [accept]="fileConfig['image'].allowedFormats" class="file-input-elem">
  <input multiple type="file" #documentFileInput (change)="fileChangeEvent($event, 'documents', documentsForm)" [accept]="fileConfig['other'].allowedFormats" class="file-input-elem">
  <button *ngIf="!readonly"
          [uPopover]="chooseUploadTypePopover"
          [placement]="isRtl() ? 'left-center' : 'right-center'"
          [autoClose]="true"
          [popoverClass]="'u-popover u-popover_rounded choose-upload-type-popover'"
          [container]="'body'"
          class="u-btn-simple u-btn-small icon-btn upload-file-button">
    <span class="icon upload-icon"></span>
  </button>
  <button *ngIf="documentsForm && documentsForm.value.length > 0"
          [uPopover]="filesPopover"
          [placement]="isRtl() ? 'left-center' : 'right-center'"
          [autoClose]="'outside'"
          [popoverClass]="'u-popover u-popover_rounded files-popover'"
          [container]="'body'"
          class="u-btn-simple u-btn-small icon-btn existing-file-button">
    <span class="icon existing-file-icon"></span>
  </button>
  <button *ngIf="imagesForm && imagesForm.value.length > 0"
          [uPopover]="imagesPopover"
          [placement]="isRtl() ? 'left-center' : 'right-center'"
          [autoClose]="'outside'"
          [popoverClass]="'u-popover u-popover_rounded images-popover'"
          class="u-btn-simple u-btn-small icon-btn existing-image-button"
          (click)="fetchThumbnails()">
    <span class="icon existing-image-icon"></span>
  </button>
</div>
<ng-template #chooseUploadTypePopover>
  <div class="upload-type-container">
    <button (click)="uploadImage()" class="u-btn u-btn-simple u-btn-small">{{'general.files.uploadImages' | translate}}</button>
    <button (click)="uploadDocument()" class="u-btn u-btn-simple u-btn-small">{{'general.files.uploadDocuments' | translate}}</button>
  </div>
</ng-template>

<ng-template #filesPopover>
  <div class="file-label-container" *ngFor="let file of documentsForm.value; let i = index;">
    <label class="file-input-label" title="{{'general.download' | translate}}" (click)="downloadFile(file)">{{file.fileName}}</label>
    <button class="u-btn-simple u-btn-small icon-btn remove-file-btn" (click)="removeFile(documentsForm, i)" *ngIf="!readonly">
      <span class="icon x-icon"></span>
    </button>
  </div>
</ng-template>

<ng-template #imagesPopover>
  <div class="image-container" *ngFor="let image of imagesForm.value; let i = index;">
    <img *ngIf="image.fileSrc" class="image-file-preview" [src]="image.fileSrc" alt="image-thumbnail">
    <div class="image-actions-section">
      <button *ngIf="!readonly" class="u-btn-simple u-btn-small icon-btn delete-image" (click)="removeFile(imagesForm, i)" title="{{'general.delete' | translate}}">
        <span class="icon trash-icon-white"></span>
      </button>
      <button class="u-btn-simple u-btn-small icon-btn expand-image" (click)="expandImage(image)" title="{{'general.expand' | translate}}">
        <span class="icon expand-icon"></span>
      </button>
    </div>
  </div>
</ng-template>

<div bsModal #expandImageModal="bs-modal" class="modal fade expand-image-modal" tabindex="0" aria-labelledby="expandImage" aria-hidden="true">
  <div class="modal-dialog">
    <img *ngIf="expandImageObject" class="full-size-image" [src]="expandImageObject.fileSrc" alt="full-size-image">
    <button class="u-btn-simple u-btn-small icon-btn download-image" (click)="downloadImage(expandImageObject)" title="{{'general.download' | translate}}">
      <span class="icon download-icon"></span>
    </button>
  </div>
</div>
