import { Component, OnInit } from '@angular/core';

import { BaseService } from '@app/shared/services';
import suppliersPageConfig from './suppliers-page.component.config';

@Component({
  selector: 'app-suppliers-page',
  templateUrl: './suppliers-page.component.html',
  styleUrls: [ './suppliers-page.component.scss', './suppliers-page.component.rtl.scss' ]
})
export class SuppliersPageComponent implements OnInit {
  rows: any[] = [];
  editData: any = null;
  suppliersPageConfig: {} = suppliersPageConfig;

  constructor(private baseService: BaseService) {
  }

  ngOnInit(): void {
    this.baseService.setPaths('suppliers');
    this.fetchSuppliers();
  }

  fetchSuppliers(): void {
    this.baseService.getAll().subscribe(suppliers => this.rows = suppliers);
  }
}
