import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UCommonModule } from '@shift/ulib';

import { InputsModule } from '../inputs/inputs.module';
import { AddEditModalModule } from '@app/shared/modules/add-edit-modal/add-edit-modal.module';
import { InspectionAddEditComponent, InspectionSummaryComponent, InspectionCategoriesComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    AddEditModalModule,
    TranslateModule,
    InputsModule,
    UCommonModule
  ],
  declarations: [
    InspectionAddEditComponent,
    InspectionSummaryComponent,
    InspectionCategoriesComponent
  ],
  entryComponents: [
    InspectionAddEditComponent,
    InspectionSummaryComponent,
    InspectionCategoriesComponent
  ]
})
export class InspectionsModule { }
