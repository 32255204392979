import { AfterViewInit, Component, ComponentFactoryResolver, EventEmitter, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dynamic-component',
  template: '<ng-container #componentContainer></ng-container>'
})
export class DynamicComponentComponent implements OnDestroy, AfterViewInit, OnInit {
  @ViewChild('componentContainer', { read: ViewContainerRef }) componentContainer: ViewContainerRef;

  @Input() data;
  @Input() component;
  @Input() config: any;
  @Input() editData: {};
  @Input() public formObject;
  @Input() onSubmit: EventEmitter<any>;
  @Input() addNewFieldObs: Subject<any> = new Subject();

  private cmpRef: any;
  private isViewInitialized = false;

  constructor(private resolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    this.updateComponent();
  }

  ngAfterViewInit(): void {
    this.isViewInitialized = true;
    this.updateComponent();
  }

  ngOnDestroy(): void {
    if (this.cmpRef) {
      this.cmpRef.destroy();
    }
  }

  updateComponent(): void {
    if (!this.isViewInitialized) {
      return;
    }
    if (this.cmpRef) {
      this.cmpRef.destroy();
    }

    if (this.component) {
      const resolvedComponent = this.resolver.resolveComponentFactory(this.component);
      this.cmpRef = this.componentContainer.createComponent(resolvedComponent);
      this.cmpRef.instance.formObject = this.formObject;

      if (this.editData) {
        this.cmpRef.instance.editData = this.editData;
      }

      if (this.data) {
        this.cmpRef.instance.data = this.data;
      }

      if (this.addNewFieldObs) {
        this.cmpRef.instance.addNewFieldObs = this.addNewFieldObs;
      }

      if (this.config) {
        this.cmpRef.instance.config = this.config;
      }

      if (this.onSubmit) {
        this.cmpRef.instance.onSubmit = this.onSubmit;
      }

      if (this.cmpRef.instance.hasOwnProperty('id') && this.editData) {
        this.cmpRef.instance.id = this.editData['id'];
      }
    }
  }
}
