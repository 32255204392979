import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services';
import apiServiceConfig from '@app/shared/services/api/api.service.config';

@Injectable()
export class VehiclesService {
  private paths: any = apiServiceConfig.paths.vehicles;

  constructor(private apiService: ApiService) {}

  getVehicleInspections(vehicleId: number): Observable<any> {
    return this.apiService.get(this.paths.basePath + this.paths.inspections, {vehicleId});
  }

  getVehicleEvents(vehicleId: number): Observable<any> {
    return this.apiService.get(this.paths.basePath + this.paths.events, {vehicleId});
  }
}
