const loginPageConfig = {
  errors: {
    loginForm: {
      'username': '',
      'password': ''
    },
    codeForm: {
      'username': '',
      'code': ''
    },
    newPasswordForm: {
      'username': '',
      'password': '',
      'passwordRepeat': ''
    }
  },
  steps: {
    loginForm: 0,
    codeForm: 1,
    newPasswordForm: 2
  }
};

export default loginPageConfig;
