import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UPopupService } from '@shift/ulib';

import { FormsService } from '@app/shared/services';
import { AlertsService } from '@app/modules/alerts/services/alerts.service';
import { TablePageService } from '@app/shared/components/table-page/table-page.service';
import alertExpirationPopoverConfig from './alert-expiration-popover.component.config';

@Component({
  selector: 'app-alert-popover',
  templateUrl: './alert-expiration-popover.component.html',
  styleUrls: [ './alert-expiration-popover.component.scss', 'alert-expiration-popover.component.rtl.scss' ]
})
export class AlertExpirationPopoverComponent implements OnInit, OnDestroy {
  @Input() data: Object;
  @Input() formObject: FormGroup;
  @Input() onSubmit: EventEmitter<any>;

  onSubmitSubscription: any;
  type: 'date' | 'mileage' | 'documentDate';
  config: any = alertExpirationPopoverConfig;

  constructor(private alertsService: AlertsService, private formsService: FormsService, private tablePageService: TablePageService, private popupService: UPopupService) { }

  ngOnInit(): void {
    this.type = this.data['row']['subject'] === 'documentDate' ? 'date' : this.data['row']['subject'];
    const alertExpirationForm = this.formsService.generateForm(this.config[this.type].fields);
    Object.keys(alertExpirationForm.controls).forEach(controlKey => {
      this.formObject.addControl(controlKey, alertExpirationForm.controls[controlKey]);
    })
    this.setFormValues();
    this.onPopoverSubmit();
  }

  ngOnDestroy(): void {
    if (this.onSubmitSubscription) {
      this.onSubmitSubscription.unsubscribe();
    }
  }

  onPopoverSubmit(): void {
    this.onSubmitSubscription = this.onSubmit.subscribe(() => {
      const putBody = {
        id: this.data['row'].id,
        notes: this.formObject.get('notes').value
      };
      putBody[this.type] = this.formObject.get(this.type).value;

      this.alertsService.updateExpiration(putBody, this.type).subscribe(res => {
        const rowId = this.data['row'].id;

        if (res === {} || res === null) {
          this.tablePageService.deleteRow(rowId);
        }

        if (res) {
          this.tablePageService.replaceRow(rowId, Object.assign(this.data['row'], res));
        }

        this.popupService.showMessage({
          message: `${this.config.dictionaryPath}.successfullyUpdated`,
          yes: 'general.ok'
        },
        () => {});
      });
    })
  }

  setFormValues(): void {
    this.config[this.type].fields.forEach(field => {
      const linkedFieldValue = this.data['row'][field.linkedField];
      this.formObject.get(field.name).patchValue(field.inputType === 'number' ? +linkedFieldValue : linkedFieldValue);
    });
  }
}
