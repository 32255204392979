import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class AddEditUsersService {
  public selectedUserIndex: ReplaySubject<number>;

  constructor() {
    this.initSelectedUserIndex();
  }

  initSelectedUserIndex() {
    this.selectedUserIndex = new ReplaySubject<number>(0);
  }

  getExistingContactIndexFE(contactValues, userForms, userForm) {
    return userForms.controls.findIndex(user => {
      if (userForm !== user) {
        return this.isExistingContactWithinUser(contactValues, user.value.contacts);
      }
    }
        );
  }

  isExistingContactWithinUser(newContactValues, otherContactValues) {
    return otherContactValues.some(value =>
            value.contact === newContactValues.contact &&
            value.type === newContactValues.type
        );
  }
}
