export enum ModalActions {
  'submit' = 'submit',
  'add' = 'add',
  'edit' = 'edit',
  'delete' = 'delete',
  'shutDown' = 'shutDown'
}

export interface ModalButton {
  name: string;
  clear?: boolean;
  action: ModalActions;
}
