import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap';
import { UPopupService } from '@shift/ulib';

import { FileInputService } from '../file-input/file-input.service';
import filesConfig from '../file-input/file-input.component.config';
import { BaseService, LocalizationService } from '@app/shared/services';

@Component({
  selector: 'app-files-images-input',
  templateUrl: './files-images-input.component.html',
  styleUrls: [ './files-images-input.component.scss', 'files-images-input.component.rtl.scss' ],
  providers: [ FileInputService ]
})
export class FilesImagesInputComponent implements OnInit {
  @Input() public readonly: boolean;
  @Input() public imagesForm: FormArray;
  @Input() public documentsForm: FormArray;
  @ViewChild('imageFileInput') imageFileInput: ElementRef;
  @ViewChild('documentFileInput') documentFileInput: ElementRef;
  @ViewChild('expandImageModal') public expandImageModal: ModalDirective;

  expandImageObject;
  fileConfig = filesConfig;

  private MAX_FILES = 5;

  constructor(
    public fileInputService: FileInputService,
    private localizationService: LocalizationService,
    private popupService: UPopupService,
    private baseService: BaseService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.fileInputService.setFileConfig();
  }

  isRtl(): boolean {
    return this.localizationService.isRtl();
  }

  fileChangeEvent(fileInput: any, fileType: 'images' | 'documents', form: FormArray): void {
    const files = fileInput.target.files;
    if (files.length + form.value.length > this.MAX_FILES) {
      this.tooManyFilesError(fileType);
    } else if (files) {
      this.fileInputService.loadMultipleFiles(Array.from(files), form, fileType)
              .then(() => this.setFormControlValue(form));
    }
  }

  tooManyFilesError(fileType: 'images' | 'documents'): void {
    this.popupService.showErrorMessage({message: 'general.files.tooManyFiles'},
            () => {
              if (fileType === 'images') {
                this.uploadImage();
              } else {
                this.uploadDocument();
              }
            });
  }

  removeFile(form: FormArray, index: number): void {
    form.removeAt(index);
    form.markAsDirty();
    this.setFormControlValue(form);
  }

  setFormControlValue(form: FormArray): void {
    form.markAsDirty();
    form.updateValueAndValidity();
  }

  uploadImage(): void {
    this.clearFileInput(this.imageFileInput);
    this.fileInputService.setFileConfig('image');
    this.imageFileInput.nativeElement.click();
  }

  uploadDocument(): void {
    this.clearFileInput(this.documentFileInput);
    this.fileInputService.setFileConfig();
    this.documentFileInput.nativeElement.click();
  }

  clearFileInput(fileInput: ElementRef): void {
    fileInput.nativeElement.value = '';

    if (!/safari/i.test(navigator.userAgent)) {
      fileInput.nativeElement.type = '';
      fileInput.nativeElement.type = 'file';
    }
  }

  downloadFile(file: any): void {
    this.fileInputService.downloadFile(file.fileId, file.fileName);
  }

  downloadImage(image: any = this.expandImageObject): void {
    saveAs(image.file, image.fileName);
  }

  expandImage(image: any): void {
    this.expandImageObject = image;
    this.baseService.downloadFile(image.fileId).subscribe(file => {
      this.expandImageObject.file = file;
      this.expandImageObject.fileSrc = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
      this.expandImageModal.show();
    });
  }

  fetchThumbnails(): void {
    this.imagesForm.controls.forEach((image) => {
      if (!image.value.fileSrc) {
        this.baseService.downloadThumbnail(image.value.fileId)
                  .subscribe(thumbnail => {
                    (image as FormGroup).setControl('fileSrc', new FormControl(this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(thumbnail))));
                  });
      }
    });
  }
}
