import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import expenseAddEditModalConfig from './expense-add-edit.component.config';
import { BaseService, CtrlFormsService } from '@app/shared/services';
import { MetadataService } from '@app/shared/services/metadata.service';
import { AddEditModalService } from '@app/shared/modules/add-edit-modal/services';

@Component({
  selector: 'app-expense-add-edit',
  templateUrl: './expense-add-edit.component.html',
  styleUrls: [ './expense-add-edit.component.scss', './expense-add-edit.component.rtl.scss' ]
})
export class ExpenseAddEditComponent implements OnInit {
  @Output() action = new EventEmitter();

  editData: any;
  addEditForm: FormGroup;
  modalConfig: any = expenseAddEditModalConfig;

  constructor(
    private baseService: BaseService,
    private ctrlFormsService: CtrlFormsService,
    private addEditModalService: AddEditModalService,
    private metadataService: MetadataService
  ) {}

  ngOnInit(): void {
    this.initAddEditForm();
    this.metadataService.setMetadataObs(this.modalConfig);
    this.baseService.setPaths('expenses');
    if (this.editData) {
            // TODO remove when api ready
      if (!expenseAddEditModalConfig.apiNotReady) {
        this.ctrlFormsService.patchValueNested(this.addEditForm, this.editData, this.modalConfig.fields);
      }
    }
  }

  initAddEditForm(): void {
    this.addEditForm = this.ctrlFormsService.generateModalTabsForm(this.modalConfig);
  }

  deleteExpense(): void {
    this.addEditModalService.deleteEntity('expenses.addEditExpense.messages.deleteConfirm', this.editData, this.action);
  }

  submitForm = (): void => {
    if (this.addEditModalService.canSubmit(this.addEditForm)) {
      this.addEditModalService.submitForm(this.addEditForm.value, this.action, this.editData);
    }
  };
}
