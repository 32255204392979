import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';

import { InspectionService } from '../../services';
import { BaseService, CtrlFormsService } from '@app/shared/services';
import { MetadataService } from '@app/shared/services/metadata.service';
import inspectionAddEditConfig from './inspection-add-edit.component.config';
import inspectionAddEditReadonlyConfig from './inspection-add-edit.component.readonly-config';
import { AddEditModalService } from '@app/shared/modules/add-edit-modal/services';

@Component({
  selector: 'app-inspection-add-edit',
  templateUrl: './inspection-add-edit.component.html',
  styleUrls: [ './inspection-add-edit.component.scss', './inspection-add-edit.component.rtl.scss' ]
})
export class InspectionAddEditComponent implements OnInit {
  @select([ 'auth', 'user', 'fullName' ])
    readonly userNameObservable: Observable<any>;

  modalConfig: any;
  addEditForm: any;
  isLoading = true;
  data: any = null;

  @Output() action = new EventEmitter();

  constructor(
    private baseService: BaseService,
    private ctrlFormsService: CtrlFormsService,
    private inspectionService: InspectionService,
    private addEditModalService: AddEditModalService,
    private metadataService: MetadataService
  ) {}

  ngOnInit(): void {
    this.baseService.setPaths('inspection');

    if (this.data) {
      this.modalConfig = inspectionAddEditReadonlyConfig;
      this.addEditForm = this.ctrlFormsService.generateModalTabsForm(this.modalConfig);
      this.metadataService.setMetadataItemValue('inspection', this.data);
      this.ctrlFormsService.patchValueNested(this.addEditForm, this.data, this.modalConfig.fields);
      this.isLoading = false;
    } else {
      this.modalConfig = inspectionAddEditConfig;
      this.addEditForm = this.ctrlFormsService.generateModalTabsForm(this.modalConfig);

      this.fetchVehicles();
      this.startInspection();
      this.onVehicleSelect();
      this.setSafetyOfficer();
    }
    this.metadataService.setMetadataObs(this.modalConfig);
  }

  setSafetyOfficer(): void {
    this.userNameObservable.subscribe(userName =>
          this.addEditForm.get('safetyOfficerName').patchValue(userName));
  }

  startInspection(): void {
    this.inspectionService.startInspection().subscribe(inspection => {
      this.metadataService.setMetadataItemValue('inspection', inspection);
      this.addEditForm.get('id').patchValue(inspection.id);
      this.isLoading = false;
    });
  }

  fetchVehicles(): void {
    this.inspectionService.getAvailableVehicles().subscribe(vehicles => {
      this.metadataService.setMetadataItemValue('vehicles', vehicles);
      this.metadataService.setMetadataItemValue('drivers', []);
    });
  }

  onVehicleSelect(): void {
    this.addEditForm.get('vehicleId').valueChanges.subscribe(newVal => {
      this.metadataService.metadataObsObj['vehicles'].subscribe(vehicles => {
        const vehicleObject = vehicles.find(vehicle => vehicle.value === newVal);
        if (vehicleObject) {
          this.baseService.getAllSlim({BranchIds: [ vehicleObject.branchId ]}, 'drivers').subscribe(drivers => {
            this.metadataService.setMetadataItemValue('drivers', drivers);
            if (vehicleObject.driverId) {
              this.addEditForm.get('driverId').setValue(vehicleObject.driverId);
            }
          });
        }
      })
    });
  }

  submitForm = (): void => {
    if (this.addEditModalService.canSubmit(this.addEditForm)) {
      this.addEditModalService.submitForm(this.getCreateInspectionRequestBody(), this.action, false);
    }
  };

  deleteInspection(): void {
    this.addEditModalService.deleteEntity('inspections.addEditInspection.messages.deleteConfirm', this.addEditForm.value);
  }

  private getCreateInspectionRequestBody() {
    const formValues = this.addEditForm.value;
    formValues['number'] = formValues.id.toString();

    return formValues;
  }
}
