import { AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { LocalizationService } from '@app/shared/services';
import { MetadataService } from '@app/shared/services/metadata.service';
import vehicleAddEditSupplierConfig from './vehicle-add-edit-suppliers.component.config';

@Component({
  selector: 'app-vehicle-add-edit-suppliers',
  templateUrl: './vehicle-add-edit-suppliers.component.html',
  styleUrls: [ './vehicle-add-edit-suppliers.component.scss', './vehicle-add-edit-suppliers.component.rtl.scss' ]
})
export class VehicleAddEditSuppliersComponent implements OnInit, AfterViewInit {
  @Input() formObject: FormArray;

  isRtl: boolean;
  editData: any = null;
  suppliers: any[] = [];
  tableRows: any[] = [];
  vehicleAddEditSupplierConfig = vehicleAddEditSupplierConfig;
  tableColumns: any[] = this.vehicleAddEditSupplierConfig.tableColumns;

  @ViewChild('suppliersTable') public tableElement: TemplateRef<any>;
  @ViewChild('supplierNameCell') public supplierNameCell: TemplateRef<any>;
  @ViewChild('supplierTypeCell') public supplierTypeCell: TemplateRef<any>;
  @ViewChild('supplierDeleteCell') public supplierDeleteCell: TemplateRef<any>;

  constructor(
    private translate: TranslateService,
    private metadataService: MetadataService,
    public localizationService: LocalizationService
  ) {}

  ngOnInit(): void {
    this.isRtl = this.localizationService.isRtl();
    this.setCellTemplates(this.tableColumns);
  }

  ngAfterViewInit(): void {
    this.fetchSuppliers();
  }

  fetchSuppliers(): void {
    this.metadataService.metadataObsObj['suppliers'].subscribe(suppliers => {
      this.suppliers = suppliers;

      if (suppliers && this.formObject && this.tableRows.length === 0) {
        this.formObject.controls.forEach(control => this.addNewSupplier(control as FormControl));
      }
    });
  }

  setCellTemplates(columns: any[]): void {
    columns.forEach(col => {
      if (col.cellTemplateName) {
        col['cellTemplate'] = this[col.cellTemplateName];
      }
    });
  }

  removeSupplier(index: number): void {
    this.formObject.removeAt(index);
    this.formObject.markAsDirty();
    this.tableRows.splice(index, 1);
        // @ts-ignore - u-grid function
    this.tableElement.updateRows();
  }

  addNewSupplier(supplierIdControl?: FormControl): void {
    const supplierTypeForm = new FormControl(null);
    const supplierIdNewControl = new FormControl('');
    if (supplierIdControl) {
      this.setSupplierType(supplierTypeForm, supplierIdControl.value);
      supplierIdControl.valueChanges.subscribe(newVal => {
        this.setSupplierType(supplierTypeForm, newVal);
      });
    } else {
      supplierIdNewControl.valueChanges.subscribe(newVal => {
        this.setSupplierType(supplierTypeForm, newVal);
      });
      this.formObject.push(supplierIdNewControl);
    }

    this.tableRows.push({
      supplierName: supplierIdControl || supplierIdNewControl,
      supplierType: supplierTypeForm
    });
        // @ts-ignore - u-grid function
    this.tableElement.updateRows();
  }

  setSupplierType(supplierTypeForm: FormControl, supplierId: number): void {
    const supplierObjectIndex = this.suppliers && this.suppliers.findIndex(supplier => supplier.value === supplierId);
    const supplierType = this.suppliers[supplierObjectIndex] && this.suppliers[supplierObjectIndex].type;
    if (supplierType) {
      this.translate.get(supplierType).subscribe(translatedType => supplierTypeForm.patchValue(translatedType));
    } else {
      supplierTypeForm.patchValue('');
    }
  }
}
