import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-percent-input',
  templateUrl: './percent-input.component.html',
  styleUrls: [ './percent-input.component.scss', './percent-input.component.rtl.scss' ]
})
export class PercentInputComponent implements OnInit {
  @Input() field: any;
  @Input() formObject: FormControl;

  constructor() {}

  ngOnInit(): void {
    this.formObject = this.formObject as FormControl;
  }
}
