import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import appConfig from '@app/app.config';

@Injectable()
export class AppTitleService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translate: TranslateService
  ) {}

  init(): void {
    this.titleService.setTitle(appConfig.appName);
    this.changeTitleOnRouteChange();
  }

  refreshPageTitle(): void {
    this.translateAndSetPageTitle(this.getCurrentRouteTitle());
  }

  private getCurrentRouteTitle(): string {
    const appTitle = this.titleService.getTitle();

    let child = this.activatedRoute;
    while (child.firstChild) {
      child = child.firstChild;
    }
    return child.snapshot.data.title || appTitle;
  }

  private translateAndSetPageTitle(title: string) {
    this.translate.get(title).subscribe(translatedTitle => {
      this.titleService.setTitle(translatedTitle);
    });
  }

  private changeTitleOnRouteChange(): void {
    this.router
            .events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => {
              return this.getCurrentRouteTitle();
            })
        ).subscribe((title: string) => {
          this.translateAndSetPageTitle(title);
        });
  }
}
