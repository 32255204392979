<u-modal-entity
  *ngIf="!metadataService.isLoading"
  class="add-edit-modal"
  [title]="modalConfig.title | translate"
  [buttons]="modalButtons"
  [isRtl]="isRtl"
  [formChanged]="formObject.dirty"
  (action)="checkActions($event)"
>
  <tabset class="add-edit-modal__tabs u-tabset-pills" type="pills" [ngClass]="{'long-title': modalConfig.longTitle}">
    <tab *ngFor="let tab of modalConfig.tabs;" id="{{tab['id']}}" heading="{{tab['title'] | translate}}"
         (select)="selectTab($event)">
      <div class="modal-tab-body modal-tab-body-columns" *ngIf="tab['columns']">
        <div class="modal-col modal-col-{{colIndex}}" *ngFor="let col of tab['columns']; let colIndex=index;">
          <div class="section" *ngFor="let section of col">
            <div class="u-text-line u-text-line_border-off ctrl-section-title" *ngIf="section.title">
              {{section.title | translate}}
            </div>
            <div class="field" *ngFor="let field of section['fields']"
                 [ngClass]="field['bodyComponent'] ? 'full-height-field':''">
              <app-dynamic-field
                *ngIf="!field.showIf || field.showIf && formObject.controls[field.showIf.formControl].value === field.showIf.equals"
                [field]="field"
                [formObject]="tab.notNestedForm ? formObject : formObject && formObject.controls[tab.id]"
                [editData]="editData"></app-dynamic-field>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-tab-body modal-tab-body-rows" *ngIf="tab['rows']">
        <div class="modal-row modal-row-{{rowIndex}}" *ngFor="let row of tab['rows']; let rowIndex=index;">
          <div class="u-text-line u-text-line_border-off ctrl-section-title" *ngIf="row.title">
            {{row.title | translate}}
          </div>
          <div class="field" *ngFor="let field of row['fields']"
               [ngClass]="field['bodyComponent'] ? 'full-height-field':''">
            <app-dynamic-field [field]="field"
                               [formObject]="tab.notNestedForm ? formObject : formObject && formObject.controls[tab.id]"
                               [editData]="editData"></app-dynamic-field>
          </div>
        </div>
      </div>
    </tab>
  </tabset>
</u-modal-entity>
