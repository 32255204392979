import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import apiServiceConfig from './api.service.config';

@Injectable()
export class PersonService {
  private paths: any = apiServiceConfig.paths.person;

  constructor(private apiService: ApiService) {}

  findByIdentity(identity: string): Observable<any> {
    return this.apiService.get(this.paths.basePath + this.paths.findByIdentity, {identity});
  }

  findByContact(contact: object = {}): Observable<any> {
    return this.apiService.get(this.paths.basePath + this.paths.findByContact, contact);
  }
}
