const dictionaryPath = 'addEditUsers';

const addEditUsersComponentConfig = {
  categoriesToFetch: [ 'roleType', 'contactType' ],
  userDetailsConfig: {
    id: 'userDetails',
    fields: [
      {
        name: 'firstName',
        required: true,
        inputType: 'text',
        title: `${dictionaryPath}.userDetails.firstName`
      },
      {
        name: 'lastName',
        required: true,
        inputType: 'text',
        title: `${dictionaryPath}.userDetails.lastName`
      },
      {
        name: 'role',
        required: true,
        inputType: 'select',
        title: `${dictionaryPath}.userDetails.role`,
        placeholder: `${dictionaryPath}.userDetails.selectRole`,
        inputOptionsVar: 'roleType'
      },
      {
        name: 'contacts',
        required: true,
        arrayField: true,
        inputType: 'contacts',
        title: `${dictionaryPath}.userDetails.contacts`
      }
    ]
  },
  dictionary: {
    userName: `${dictionaryPath}.usersList.userName`,
    addNewUser: `${dictionaryPath}.usersList.addNewUser`,
    usersListTitle: `${dictionaryPath}.usersList.title`,
    userDetailsTitle: `${dictionaryPath}.userDetails.title`,
  }
};

export default addEditUsersComponentConfig;
