import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import alertInputConfig from './alert-input.component.config';
import { ConstantsService, LocalizationService } from '@app/shared/services';

@Component({
  selector: 'app-alert-input',
  templateUrl: './alert-input.component.html',
  styleUrls: [ './alert-input.component.scss', './alert-input.component.rtl.scss' ]
})
export class AlertInputComponent implements OnInit {
  @Input() control;

  isRtl: boolean;
  periodUnitOptions: any[] = [];

  constructor(
    private localizationService: LocalizationService,
    private constantsService: ConstantsService
  ) {}

  ngOnInit(): void {
    this.setAlertsCategories();
    this.setDefaultPeriodUnit();
    this.disableAmountControl();
    this.onPeriodUnitChanged();
    this.isRtl = this.localizationService.isRtl();
  }

  setAlertsCategories(): void {
    this.constantsService.categories.subscribe(categories =>
          this.periodUnitOptions = categories['alertPeriodUnit']);
  }

  onPeriodUnitChanged(): void {
    this.periodUnitControl.valueChanges.subscribe(() => this.onPeriodUnitChange());
  }

  onPeriodUnitChange(): void {
    this.disableAmountControl();

    if (this.amountControl.value !== this.minAmount) {
      this.amountControl.reset();
    }
  }

  disableAmountControl(): void {
    if (this.periodUnitControl.value === alertInputConfig.periodUnitsNoneValue) {
      this.amountControl.disable();
      this.amountControl.clearValidators();
    } else if (this.amountControl.disabled) {
      this.amountControl.enable();
      this.amountControl.setValidators(this.control.required ? [ Validators.required ] : undefined);
    }
    this.amountControl.updateValueAndValidity();
  }

  setDefaultPeriodUnit(): void {
    if (!this.periodUnitControl.value) {
      this.periodUnitControl.setValue(alertInputConfig.periodUnitsDefaultValue);
    }
  }

  get periodUnitControl(): FormControl {
    return this.control.get('periodUnit');
  }

  get amountControl(): FormControl {
    return this.control.get('amount');
  }

  get minAmount(): number {
    return this.periodUnitControl.value ? alertInputConfig.periodUnits[this.periodUnitControl.value]['minAmount'] : undefined;
  }

  get maxAmount(): number {
    return this.periodUnitControl.value ? alertInputConfig.periodUnits[this.periodUnitControl.value]['maxAmount'] : undefined;
  }
}
