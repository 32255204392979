import { Component, OnInit } from '@angular/core';

import { LocalizationService } from '@app/shared/services';
import { AppLanguageCode, Languages } from '@app/shared/models/languages.models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: [ './footer.component.scss', './footer.component.rtl.scss' ]
})
export class FooterComponent implements OnInit {
  languages = Languages;
  selectedLanguageCode: AppLanguageCode;

  constructor(private localizationService: LocalizationService) { }

  ngOnInit() {
    this.selectedLanguageCode = this.localizationService.getLanguage();
  }

  setLanguage(languageCode: AppLanguageCode): void {
    window.location.reload();

    this.localizationService.saveLanguage(languageCode);
  }
}
