import { Component, OnInit } from '@angular/core';

import customersPageConfig from './customers-page.component.config';
import { BaseService } from '@app/shared/services/api/base.service';
import { CustomersService } from '@app/modules/customers/services';

@Component({
  selector: 'app-customers',
  templateUrl: './customers-page.component.html',
  styleUrls: [ './customers-page.component.scss', './customers-page.component.rtl.scss' ]
})
export class CustomersPageComponent implements OnInit {
  rows: any[] = [];
  editData: any = null;
  customersPageConfig = customersPageConfig;

  constructor(
    private customersService: CustomersService,
    private baseService: BaseService
  ) {}

  ngOnInit(): void {
    this.baseService.setPaths('customers');
    this.initCustomersList();
  }

  initCustomersList(): void {
    this.fetchCustomers();
    this.customersService.customersList.subscribe(customers => this.rows = customers);
  }

  fetchCustomers(): void {
    this.baseService.getAll().subscribe(customers => this.customersService.setCustomersList(customers));
  }
}
