import { Injectable } from '@angular/core';
import { GuardsCheckStart, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { distinctUntilChanged } from 'rxjs/operators';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

import { AppConstants } from '@app/shared/constants';
import { CommonService } from '@app/shared/services/common.service';
import { HeaderIcon, WeekSwitch, WeekSwitchStartEnd } from '@app/shared/models';

@Injectable()
export class HeaderDataService {
  private showWeekSwitch: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private showDate: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private date: BehaviorSubject<string> = new BehaviorSubject(moment().startOf('day').format(AppConstants.DATE_FORMAT_ISO));

  showWeekSwitch$: Observable<boolean> = this.showWeekSwitch.asObservable();
  showDate$: Observable<boolean> = this.showDate.asObservable();
  date$: Observable<string> = this.date.asObservable();

  headerForm: FormGroup;
  icons: HeaderIcon[];
  action: Subject<HeaderIcon> = new Subject();
  showGlobalSearch: boolean;
  isDateDisabled: boolean;

  weekSwitch: WeekSwitch = {
    initDate: this.date.value,
    startDateLimit: null,
    endDateLimit: null
  };

  weekSwitchChange: BehaviorSubject<WeekSwitchStartEnd> = new BehaviorSubject({
    startDate: moment().startOf('week').format(AppConstants.DATE_FORMAT_ISO),
    endDate: moment().endOf('week').startOf('day').format(AppConstants.DATE_FORMAT_ISO)
  });

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private commonService: CommonService
  ) {
    this.router.events.subscribe((obEvent: any) => {
      if (obEvent instanceof GuardsCheckStart) {
        const [ previousUrl ] = this.commonService.previousUrl.split(';');
        const [ nextUrl ] = obEvent.url.split(';');

        if (previousUrl !== nextUrl) {
          this.updateShowWeekSwitch(false);
          this.showGlobalSearch = false;

          if (nextUrl !== '/settings') {
            this.commonService.previousUrl = nextUrl;
          }
        }
      }
    });

    this.generateHeaderForm();
  }

  get dateForm(): FormControl {
    return this.headerForm.get('date') as FormControl;
  }

  updateShowWeekSwitch(value: boolean) {
    this.showWeekSwitch.next(value);
  }

  updateShowDate(value: boolean) {
    this.showDate.next(value);
  }

  generateHeaderForm() {
    const today = moment().format();

    this.headerForm = this.fb.group({
      date: [ today, Validators.required ]
    });

    this.dateForm.valueChanges
      .pipe(
        distinctUntilChanged(),
      )
      .subscribe((value: any) => {
        this.updateDate(value);
        this.commonService.setHeaderDate(moment(value));
        this.updateDateWeekSwitch(value);
      });
  }

  dateSet(value: string) {
    this.dateForm.patchValue(value);
  }

  updateDate(value: string) {
    this.date.next(value);
  }

  weekSwitchSet(data: WeekSwitch) {
    this.weekSwitch = {
      ...this.weekSwitch,
      ...data
    };
  }

  weekSwitchChangeSet(data: WeekSwitchStartEnd) {
    this.weekSwitch.initDate = data.startDate;
    this.weekSwitchChange.next(data);
  }

  setIcons(data: HeaderIcon[]) {
    this.icons = data;
  }

  updateDateWeekSwitch(date) {
    const weekSwitch: WeekSwitch  = {
      initDate: date,
      startDateLimit: null,
      endDateLimit: null
    };

    const weekSwitchRange: WeekSwitchStartEnd = {
      startDate: moment(date).startOf('week').format(AppConstants.DATE_FORMAT_ISO),
      endDate: moment(date).endOf('week').startOf('day').format(AppConstants.DATE_FORMAT_ISO)
    };

    this.weekSwitchSet(weekSwitch);
    this.weekSwitchChangeSet(weekSwitchRange);
  }

  isTodayActiveDate(): boolean {
    return moment().startOf('day').isSame(moment(this.date.value).startOf('day'));
  }

  getActiveDate(): string {
    return this.isTodayActiveDate() ? moment().format(AppConstants.DATE_FORMAT_ISO) : this.date.value;
  }

  getDate(): string {
    return this.date.value;
  }

  getDateRange(): { startDate: string; endDate: string; } {
    return {
      startDate: moment(this.date.value).format(AppConstants.DATE_FORMAT_BASE_LINE),
      endDate: moment(this.date.value).format(AppConstants.DATE_FORMAT_BASE_LINE)
    };
  }
}
