import { Injectable } from '@angular/core';
import * as faker from 'faker';
import * as moment from 'moment';
import { AppConstants } from '@app/shared/constants';

@Injectable()
export class MockUpService {
  // TODO: this code is TEMPORARY - need to be removed after fully integrated with the api for fetching real data
  private SUPPLIER_TYPES = [ 'Transports', 'Leasing', 'Council' ];
  private DRIVER_TYPES = [ 'Company Employee', 'Subcontractor' ];
  private ENTITY_TYPES = [ 'Drivers', 'Supplier', 'Vehicle', 'Customer' ];
  private ALERT_TYPES = [ 'Driving License', 'Vehicle License', 'Monthly Inspection', '5000 Treatment' ];
  private STATUS_TYPES = [ { valid: true, value: 'Valid' }, { valid: false, value: 'Expired' }, { valid: false, value: 'Not Eligible' } ];
  private CHANGE_STATUS_TYPES = [ { valid: true, value: 'Sent' }, { valid: true, value: 'Cancelled' }, { valid: true, value: 'Approved' }, { valid: true, value: 'Opened' } ];
  private FILE_STATUSES = [ 'Required', 'Attached' ];
  private MANUFACTURERS = [ 'Mazda', 'Hyundai', 'Kia', 'Subaru', 'Toyota', 'Fiat', 'Mercedes', 'Honda', 'Mitsubishi', 'BMW' ];
  private VEHICLE_TYPES = [ 'Passengers Vehicle', 'Commercial Vehicle' ];
  private ACTIVITY_STATUS = [ 'Active', 'Inactive' ];

  constructor() {
  }

  generateMockRows(numRows, cellsConfig) {
    const rows = [];
    for (let i = 0; i < numRows; i++) {
      const rowObject = {};
      cellsConfig.forEach(config => rowObject[config.prop] = { value: this.getCellMockData(config.mockUpOptions) });
      rowObject['id'] = i;
      rows.push(rowObject);
    }

    return rows;
  }

  private getCellMockData(cellConfig) {
    if (cellConfig) {
      const name = {
        firstName: faker.name.firstName(),
        lastName: faker.name.lastName()
      };

      switch (cellConfig.type) {
        case 'fullName':
          return `${name.firstName} ${name.lastName}`;
        case 'firstName':
          return name.firstName;
        case 'lastName':
          return name.lastName;
        case 'address':
          return faker.address.city();
        case 'phone':
          return faker.phone.phoneNumber('03-#######');
        case 'mobile':
          return faker.phone.phoneNumber('05#-#######');
        case 'number':
          return Math.round(Math.random() * 1000000);
        case 'email':
          return `${name.firstName}_${name.lastName}@gmail.com`;
        case 'id':
          return faker.random.number(Math.pow(10, cellConfig.maxDigits || 10));
        case 'price':
          return faker.random.number(Math.pow(10, cellConfig.maxDigits || 10)).toLocaleString();
        case 'date':
          return moment(this.getRandomDate(new Date(2019, 1, 1), new Date())).format(AppConstants.DATE_FORMAT_BASE_DOT_SHORT);
        case 'time':
          return this.getRandomTime();
        case 'words':
          return faker.random.words(cellConfig.length);
        case 'customLabel':
          return cellConfig.label;
        case 'supplierType':
          return this.getRandomItemInArray(this.SUPPLIER_TYPES);
        case 'employmentType':
          return this.getRandomItemInArray(this.DRIVER_TYPES);
        case 'alertType':
          return this.getRandomItemInArray(this.ALERT_TYPES);
        case 'entity':
          return this.getRandomItemInArray(this.ENTITY_TYPES);
        case 'status':
          return this.getRandomItemInArray(this.STATUS_TYPES);
        case 'expenseStatus':
          return this.getRandomItemInArray(this.CHANGE_STATUS_TYPES);
        case 'file':
          return this.getRandomItemInArray(this.FILE_STATUSES);
        case 'timeOrDistance':
          if (Math.random() < 0.5) {
            return { popoverType: 'document', value: `In ${Math.floor(Math.random() * 14) + 1} Days` };
          }
          return { popoverType: 'km', value: `${(Math.floor(Math.random() * 10000) + 1).toLocaleString()} Km Ago` };
        case 'licenseNumber':
          return `${Math.floor(Math.random() * 100)}-${Math.floor(Math.random() * 10000)}-${Math.floor(Math.random() * 100)}`;
        case 'manufacturer':
          return this.getRandomItemInArray(this.MANUFACTURERS);
        case 'vehicleType':
          return this.getRandomItemInArray(this.VEHICLE_TYPES);
        case 'vehicleAge':
          return `${Math.floor(Math.random() * 100)} Years`;
        case 'activityStatus':
          return this.getRandomItemInArray(this.ACTIVITY_STATUS);
      }
    }
    return null;
  }

  private getRandomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  }

  private getRandomTime() {
    const hours = Math.round(Math.random() * 24);
    const minutes = Math.round(Math.random() * 60);

    return `${minutes}:${hours}`;
  }

  private getRandomItemInArray(array) {
    return array[Math.floor(Math.random() * array.length)];
  }
}
