<div class="file-expiration-input-container">
  <u-input-date [classUInput]="'u-input_text-center'"
                [formControl]="getFileExpirationDateFormControl()"
                [valid]="getFileExpirationDateFormControl().valid"
                [showIcon]="false"
                [container]="'body'"
                [placement]="isRtl ? ['bottom-right', 'bottom-left', 'top-right', 'top-left'] : ['bottom-left', 'bottom-right', 'top-left', 'top-right']"
                [messages]="'uInputDate' | translate">
  </u-input-date>
  <app-file-input [ngClass]="{'existing-file': isFileExists()}" [formObject]="formControlObject" [outsideDelete]="outsideDelete" [innerDelete]="innerDelete" (removeEvent)="removeFile()"></app-file-input>
  <span class="expired-message" *ngIf="isFileExpired()">{{'general.files.expired' | translate}}</span>
</div>
