import { EventEmitter, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ReplaySubject, Subject } from 'rxjs';
import { UPopupService } from '@shift/ulib';

import { LocalizedToastrService, BaseService } from '@app/shared/services';

@Injectable()
export class AddEditModalService {
  public isEditMode: boolean;
  public isSubmitting: boolean;
  public activeTabId: Subject<string> = new ReplaySubject<string>(1);

  constructor(
    private modalService: BsModalService,
    private toastr: LocalizedToastrService,
    private popupService: UPopupService,
    private baseService: BaseService
  ) {}

  openModal(modalComponent, editData?): BsModalRef {
    if (modalComponent) {
      return this.modalService.show(
              modalComponent, {
                class: 'u-modal u-modal_content u-modal_app-add-edit-modal',
                animated: true,
                keyboard: false,
                ignoreBackdropClick: true,
                initialState: {editData}
              });
    }
  }

  submitForm(requestBody: {}, action: EventEmitter<any>, editData: boolean): void {
    this.isSubmitting = true;

    if (!editData && !this.isEditMode) {
      this.baseService
          .create(requestBody)
          .subscribe(
            data => {
              action.emit({type: 'add', data});
              this.handleSubmitSuccess();
            },
            err => this.handleSubmitError(err ? err.errors : [])
          );
    } else {
      this.baseService
          .update(requestBody)
          .subscribe(
            data => {
              action.emit({type: 'edit', data});
              this.handleSubmitSuccess();
            },
            err => this.handleSubmitError(err ? err.errors : [])
          );
    }
  }

  canSubmit(addEditForm: FormGroup): boolean {
    return addEditForm.valid && !this.isSubmitting;
  }

  deleteEntity(message: string, editData, action?: EventEmitter<any>): void {
    this.popupService.showMessage({message, yes: 'general.yes', no: 'general.no'},
        () => {
          this.baseService.delete(editData.id).subscribe(
            () => {
              this.toastr.success('general.successful');
              this.modalService.hide(1);
              if (action) {
                action.emit({ type: 'delete', data: editData });
              }
            },
            err => {
              this.popupService.showErrorMessage({message: err.errors && err.errors[0]},
                    () => this.modalService.hide(1));
            });
        });
  }

  handleSubmitSuccess(): void {
    this.modalService.hide(1);
    this.isSubmitting = false;
    this.toastr.success('general.successful');
  }

  handleSubmitError(errors): void {
    this.isSubmitting = false;
    this.popupService.showErrorMessage({message: errors && errors[0]});
  }
}
