<div class="documents-container">
    <div class="documents-header-container" *ngIf="documentsPageConfig['title']">
        <span class="u-text-line u-text-line_border-off documents-title">
            {{documentsPageConfig['title'] | translate}}
        </span>
        <span class="u-btn-simple add-new-btn"
            (click)="addNewDocument()">{{documentsPageConfig['addNewDocument'] | translate}}</span>
    </div>
    <div class="document-field" *ngFor="let documentControl of formObject?.controls; let i=index;">
        <div class="custom-document" *ngIf="!documentControl.controls['type'] || documentControl.controls['type'] && !documentControl.controls['type'].value">
            <input uInput type="text" [formControl]="documentControl.controls['name']"
                   [valid]="documentControl.controls['name'].valid">
            <app-file-expiration-input [formControlObject]="documentControl"
                                   [outsideDelete]="'forced'" [innerDelete]="true"
                                   (removeEvent)="removeCustomFile(i)"></app-file-expiration-input>
        </div>
        <div class="default-document" *ngIf="documentControl.controls['type'] && documentControl.controls['type'].value">
            <u-group [name]="documentControl.controls['type'].value | translate">
                <app-file-expiration-input [formControlObject]="documentControl" [innerDelete]="true"
                                       (removeEvent)="resetFileControl($event)"></app-file-expiration-input>
            </u-group>
        </div>
    </div>
</div>
