import { Component, OnInit, Input, ViewChild, TemplateRef, OnChanges } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';

import { TablePageService } from '../table-page/table-page.service';
import { ApiService, LocalizationService } from '@app/shared/services';
import { MetadataService } from '@app/shared/services/metadata.service';

@Component({
  selector: 'app-table-element',
  templateUrl: './table-element.component.html',
  styleUrls: [ './table-element.component.scss', './table-element.component.rtl.scss' ]
})
export class TableElementComponent implements OnInit, OnChanges {
  @Input() rows: any[];
  @Input() config: any;

  @ViewChild('normalCell') public normalCell: TemplateRef<any>;
  @ViewChild('editRowCell') public editRowCell: TemplateRef<any>;
  @ViewChild('openRowModalCell') public openRowModalCell: TemplateRef<any>;

  constructor(
    private localizationService: LocalizationService,
    private apiService: ApiService,
    private modalService: BsModalService,
    private metadataService: MetadataService,
    public tablePageService: TablePageService
  ) {}

  ngOnInit(): void {
    this.setCellTemplates();
    this.setRows();
  }

  ngOnChanges(): void {
    this.setCellTemplates();
    this.setRows();
  }

  setCellTemplates(): void {
    this.config.tableConfig.columns.forEach(col =>
          col['cellTemplate'] = col.cellTemplateName ? this[col.cellTemplateName] : this.normalCell);
  }

  setRows(): void {
    if (this.config.tableConfig.metadataSource) {
      this.metadataService.metadataObsObj[this.config.tableConfig.metadataSource].subscribe(data => this.rows = data);
    }
  }

  isRtl(): boolean {
    return this.localizationService.isRtl();
  }

  openRowModal(row: any, openRowModalComponent: Component, basePath: string): void {
    this.apiService.get(basePath, {id: row.id}).subscribe(data => {
      this.modalService.show(
                openRowModalComponent, {
                  class: 'u-modal u-modal_content u-modal_app-add-edit-modal',
                  animated: true,
                  keyboard: false,
                  ignoreBackdropClick: true,
                  initialState: {data}
                });
    });
  }
}
