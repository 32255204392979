export enum Languages {
  Hebrew = 'he',
  English = 'en'
}

export type AppLanguageCode = Languages.Hebrew | Languages.English;

export class AppLanguage {
  name: string;
  code: AppLanguageCode;
}
