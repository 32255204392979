import { Component, OnInit } from '@angular/core';

import { BaseService } from '@app/shared/services';
import vehiclesPageConfig from './vehicles-page.component.config';

@Component({
  selector: 'app-vehicles-page',
  templateUrl: './vehicles-page.component.html',
  styleUrls: [ './vehicles-page.component.scss', './vehicles-page.component.rtl.scss' ]
})
export class VehiclesPageComponent implements OnInit {
  rows:  any[] = [];
  vehiclesPageConfig: any = vehiclesPageConfig;

  constructor(private baseService: BaseService) { }

  ngOnInit(): void {
    this.baseService.setPaths('vehicles');
    this.fetchVehicles();
  }

  fetchVehicles(): void {
    this.baseService.getAll().subscribe(vehicles => this.rows = vehicles);
  }
}
