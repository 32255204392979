<div class="sidebar" [ngClass]="{'sidebar_collapsed': isMenuCollapsed}">
  <div class="header-left-block" [ngClass]="{'open-sidebar': !isMenuCollapsed}">
    <div class="header-left-block-user">
      <button type="button" class="toggle-menu-button" (click)="toggleSidebar()">
      </button>
      <span class="header-left-block-user-name">{{customerName | async}}</span>
    </div>
    <div class="header-left-block-customer" (clickOutside)="showLogout = false">
      <div (click)="showLogout = !showLogout" class="header-left-block-customer__toggle">
        <div class="header-left-block-customer-arrow">
          <img src="/assets/images/icons/side-menu/arrow-down-light.svg" alt="switch-customer-arrow">
        </div>
        <p class="header-left-block-customer-text">{{fullName | async}}</p>
      </div>
      <div class="sidebar-bottom-block-logout-popup" [ngClass]="{'show-sidebar-bottom-popup': showLogout}">
        <ul>
          <li>
            <a (click)="showCustomerRoleSwitch($event)">{{'sidebar.switchRole' | translate }}</a>
          </li>
          <li>
            <a (click)="logout()">{{'sidebar.logout' | translate }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <u-sidebar-menu
    class="sidebar-menu u-sidebar-menu"
    [ngClass]="{'u-sidebar-menu_collapsed': isMenuCollapsed}"
    [collapsed]="isMenuCollapsed"
    [customNavigate]="true"
    [items]="menuItems"
    [isRtl]="isRtl"
    [bottomItems]="config.menuBottomItems"
    [bottomStickyItem]="config.menuBottomStickyItem"
    (navigate)="onNavigate($event)"
    (menuToggle)="onMenuToggle($event)"
  ></u-sidebar-menu>
</div>
