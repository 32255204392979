import { DriverAddEditComponent } from '../driver-add-edit/driver-add-edit.component';

const dictionaryPath = 'drivers.table';

const driversPageConfig = {
  modalComponent: DriverAddEditComponent,
  tableConfig: {
    counterLabel: `${dictionaryPath}.drivers`,
    tableName: 'drivers',
    columns: [
      {
        prop: 'check',
        name: '',
        width: 50,
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizeable: false,
        headerCheckboxable: true,
        checkboxable: true,
        custom: true,
        hideable: false,
        hideDivider: true,
        noValue: true
      },
      {
        prop: 'firstName',
        name: `${dictionaryPath}.firstName`,
        filterType: 'select',
        minWidth: 180,
        maxWidth: 240,
        cellTemplateName: 'editRowCell',
        hideDivider: false,
        custom: true,
        clickble: true,
        highlight: false
      },
      {
        prop: 'lastName',
        name: `${dictionaryPath}.lastName`,
        filterType: 'select',
        minWidth: 140,
        maxWidth: 240
      },
      {
        prop: 'address',
        name: `${dictionaryPath}.address`,
        filterType: 'select',
        selectItems: [],
        minWidth: 160,
        maxWidth: 350
      },
      {
        prop: 'mobile',
        name: `${dictionaryPath}.mobile`,
        filterType: 'text',
        minWidth: 120,
        maxWidth: 250
      },
      {
        prop: 'vehicleNumber',
        name: `${dictionaryPath}.associatedVehicle`,
        filterType: 'select',
        minWidth: 120,
        maxWidth: 250
      },
      {
        prop: 'employmentType',
        name: `${dictionaryPath}.type`,
        filterType: 'select',
        translate: true,
        minWidth: 120,
        maxWidth: 250
      },
      {
        prop: 'notes',
        name: `${dictionaryPath}.notes`,
        filterType: 'text',
        minWidth: 160,
        maxWidth: 250
      },
      {
        prop: 'status',
        translate: true,
        name: `${dictionaryPath}.status`,
        minWidth: 120,
        filterType: 'select',
        cellTemplateName: 'statusCell',
        statusOptions: {
          invalid: 'driverStatus.inactive'
        }
      }
    ]
  }
};

export default driversPageConfig;
