import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import inputsConfig from '@app/modules/inputs/inputs.config';

@Injectable()
export class FormsService {
    // TODO: Get rid of this service

  constructor(private fb: FormBuilder) {}

  generateForm(fieldsConfig: any[] = []): FormGroup {
    let noParentForm;
    const controlsConfig = {};

    fieldsConfig.forEach(field => {
      let formControl;
      const inputConfig = field.inputConfig || inputsConfig[field.inputType];

      if (field.groupField) {
        formControl = field.required ? this.fb.group({}, [ Validators.required ]) : this.fb.group({});
      } else if (field.bodyComponent || field.arrayField) {
        formControl = field.required ? this.fb.array([], [ Validators.required ]) : this.fb.array([]);
      } else {
        const inputFormControls = inputConfig && inputConfig.fields;
        if (inputFormControls && (inputConfig.hasOwnProperty('generateFormControls') ?  inputConfig.generateFormControls !== false : true)) {
          if (field.required) {
            inputFormControls.forEach(control => control['required'] = true);
          }
          const formGroup = this.generateFormGroup(inputFormControls);
          if (inputConfig.noParent) {
            Object.keys(formGroup.controls).forEach(controlKey => controlsConfig[controlKey] = formGroup.controls[controlKey]);
          } else {
            formControl = this.generateFormGroup(inputFormControls);
          }
        } else {
          formControl = new FormControl(field.defaultValue, field.required ? [ Validators.required ] : []);
        }
        if (formControl) {
          formControl.updateValueAndValidity();
        }
      }
      if (inputConfig ? !inputConfig.noParent : true) {
        if (field.name) {
          controlsConfig[field.name] = formControl;
        } else {
          noParentForm = formControl;
        }
      }
    });

    return noParentForm || this.fb.group(controlsConfig);
  }

  private generateFormGroup(formControls: any[]): FormGroup {
    const formGroupObject = {};
    formControls.forEach(control => {
      if (control.value instanceof Array || control.arrayField) {
        formGroupObject[control.name] = this.fb.array([]);
        if (control.value) {
          control.value.forEach(item => {
            const itemFormGroup = this.generateFormGroup(Object.keys(item).map(key => ({
              name: key,
              value: item[key]
            })));
            formGroupObject[control.name].push(itemFormGroup);
          });
        }
      } else {
        if (control.inputType && inputsConfig[control.inputType] && inputsConfig[control.inputType].fields) {
          formGroupObject[control.name] = this.generateForm(inputsConfig[control.inputType].fields);
        } else {
          formGroupObject[control.name] = new FormControl(control.defaultValue || control.value || null, control.required ? [ Validators.required ] : undefined);
        }
      }
    }
        );
    return new FormGroup(formGroupObject);
  }
}
