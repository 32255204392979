import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TabsModule, BsModalRef } from 'ngx-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { UCommonModule } from '@shift/ulib';

import { AddEditModalService } from './services';
import { AddEditModalComponent } from './components';
import { InputsModule } from '@app/modules/inputs/inputs.module';
import { DynamicElementsModule } from '../dynamic-elements/dynamic-elements.module';

@NgModule({
  imports: [
    CommonModule,
    TabsModule,
    TranslateModule,
    InputsModule,
    NgHttpLoaderModule,
    ReactiveFormsModule,
    UCommonModule,
    DynamicElementsModule
  ],
  declarations: [
    AddEditModalComponent
  ],
  providers: [
    AddEditModalService,
    BsModalRef
  ],
  exports: [
    AddEditModalComponent
  ]
})
export class AddEditModalModule {
}
