import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UPopoverDirective, UPopupService } from '@shift/ulib';

import { LocalizationService } from '@app/shared/services';
import { PopoverCellService } from './popover-cell.service';

@Component({
  selector: 'app-popover-cell',
  templateUrl: './popover-cell.component.html',
  styleUrls: [ './popover-cell.component.scss', './popover-cell.component.rtl.scss' ]
})
export class PopoverCellComponent implements OnInit {
  @Input() row: any;
  @Input() col: any;
  @Input() cell: any;
  @Input() config: any;

  data: {};
  pendingPopover = false;
  popoverForm: FormGroup = new FormGroup({});
  submitEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private localizationService: LocalizationService,
    private popupService: UPopupService,
    public popoverCellService: PopoverCellService
  ) {}

  ngOnInit(): void {
    this.data = {row: this.row, cell: this.cell, col: this.col};
  }

  isRtl(): boolean {
    return this.localizationService.isRtl();
  }

  closePopover(popoverElement: UPopoverDirective): void {
    if (popoverElement.isOpen()) {
      if (this.popoverForm.dirty && !this.popoverForm.pristine && this.popoverForm.touched) {
        this.pendingPopover = true;
        this.popoverCellService.isAnyPopoverOpen = true;
        this.popupService.showMessage({
          message: 'general.saveChanges',
          yes: 'general.yes',
          no: 'general.no',
        },
                  () => this.savePopover(popoverElement),
                  () => this.closePopoverElement(popoverElement),
                  () => this.closePopoverElement(popoverElement));
      } else {
        this.closePopoverElement(popoverElement);
      }
    }
  }

  closePopoverElement(popoverElement: UPopoverDirective): void {
    popoverElement.close();
    this.pendingPopover = false;
    this.popoverCellService.isAnyPopoverOpen = false;
  }

  savePopover(popoverElement: UPopoverDirective): void {
    this.submitEvent.emit();
    this.pendingPopover = false;
    this.popoverCellService.isAnyPopoverOpen = false;

    popoverElement.close();
  }
}
