<div class="file-input-container">
  <input type="file" #fileInput (change)="fileChangeEvent($event)" [accept]="fileConfig.allowedFormats" class="file-input-elem">
  <div class="normal-file-input{{innerDelete && fileName ? ' file-input-delete':''}}" *ngIf="fileInputType === 'normal'">
    <div class="file-input-field" [ngClass]="{'image-file-input': fileType === 'image' && !getFileSrc() ,'image-file-preview': fileType === 'image' && getFileSrc()}">
      <label *ngIf="!fileName" class="file-input-label" (click)="fileInput.click()" [uTooltip]="fileType === 'image' ? fileTooltip : ''" [tooltipClass]="'image-file-tooltip'" [placement]="['bottom-center', 'top-center']">{{fileConfig.attachLabel | translate}}</label>
      <label *ngIf="fileName && fileType !== 'image'" (click)="fileName ? downloadFile() : fileInput.click()" class="file-input-label" title="{{fileName}}">{{fileName}}</label>
      <img *ngIf="getFileSrc() && fileName && fileType === 'image'" class="image-file-preview" [src]="getFileSrc()" alt="Preview" (click)="fileInput.click()" [tooltipClass]="'image-file-tooltip'" [uTooltip]="fileTooltip" [placement]="['bottom-center', 'top-center']">
    </div>
    <button *ngIf="innerDelete && fileName" class="u-btn-simple icon-btn remove-file-btn inner-remove" (click)="removeFile()" title="{{'general.delete' | translate}}">
      <span class="icon x-icon"></span>
    </button>
    <button *ngIf="(outsideDelete && fileName) || outsideDelete === 'forced'" class="u-btn-simple u-btn-small icon-btn outside-remove" (click)="removeFile()" title="{{'general.delete' | translate}}">
      <span class="icon delete-icon"></span>
    </button>
  </div>
</div>

<ng-template #fileTooltip>
  <span class="file-tooltip-content-row">{{'general.files.selectFile'|translate}} ({{fileConfig.allowedFormats}})</span>
  <span class="file-tooltip-content-row">{{'general.files.maxSize'|translate}}: {{fileConfig.maxSizeMB}} MB</span>
</ng-template>
